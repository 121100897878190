import AppBar from "@material-ui/core/AppBar"
import Dialog from "@material-ui/core/Dialog"
import IconButton from "@material-ui/core/IconButton"
import Slide from "@material-ui/core/Slide"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import { TransitionProps } from "@material-ui/core/transitions"
import CloseIcon from "@material-ui/icons/Close"
import { Booking, bookingsApi, BookingType, BookingUpdate, Ressource, User } from "api/scheduleAPI"
import { DateTime } from "luxon"
import { useSnackbar } from "notistack"
import React, { useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { BookingRequestsList } from "./BookingRequestsList"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }),
)

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

type BookingRequestsDialogProps = {
  users: User[] | undefined
  resources: Ressource[] | undefined
  bookings: Booking[] | undefined
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}
export default function BookingRequestsDialog(props: BookingRequestsDialogProps) {
  const classes = useStyles()
  const [processing, setProcessing] = useState<number | undefined>(undefined)
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const approveMutation = useMutation(
    (booking: Booking) =>
      bookingsApi.updateBooking(booking.id, {
        id: booking.id,
        title: booking.title,
        booking_type: BookingType.User,
        ressource_id: booking.ressource_id,
        start: booking.start,
        end: booking.end,
        notes: booking.notes,
        rrule: booking.rrule,
        participants_ids: booking.participants_ids,
        tags: booking.tags,
      } as BookingUpdate),
    {
      onSuccess: () => {
        enqueueSnackbar("Booking approved.", {
          variant: "success",
        })
        const startString = DateTime.local().startOf("week").startOf("day").toUTC(0).toISO()
        const endString = DateTime.local().endOf("week").endOf("day").toUTC(0).toISO()
        queryClient.invalidateQueries("bookings_" + startString + endString)
        queryClient.invalidateQueries("booking_requests")
      },
      onError: (error: Error) => {
        const errorMsgs = error.message
        enqueueSnackbar(`Something went wrong: ${errorMsgs}`, {
          variant: "error",
        })
      },
      onSettled: () => {
        if (processing) {
          setProcessing(undefined)
        }
      },
    },
  )
  const deleteMutation = useMutation((bookingId: number) => bookingsApi.deleteBooking(bookingId), {
    onSuccess: () => {
      enqueueSnackbar("Booking refused.", {
        variant: "success",
      })
      queryClient.invalidateQueries("booking_requests")
    },
    onError: (error: Error) => {
      const errorMsgs = error.message
      enqueueSnackbar(`Something went wrong: ${errorMsgs}`, {
        variant: "error",
      })
    },
  })
  const handleClose = () => {
    props.setOpen(false)
  }

  if (props.resources === undefined || props.bookings === undefined || props.users === undefined) {
    return null
  }
  return (
    <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <BookingRequestsList
        users={props.users}
        processing={processing}
        requests={props.bookings}
        resources={props.resources}
        approve={(id: number) => {
          const approvedBooking = props.bookings?.find((b) => b.id === id)
          if (approvedBooking) {
            setProcessing(id)
            approveMutation.mutate(approvedBooking)
          }
        }}
        decline={(id: number) => {
          setProcessing(id)
          deleteMutation.mutate(id)
        }}
      />
    </Dialog>
  )
}
