import { Ressource } from "api/scheduleAPI"
import { useEffect, useRef } from "react"
import { IntervalLength } from "./BookingTypes"

export const maxSlotHeight = 5
const minSlotHeight = 1.5
export const getSubSlotHeightValue = (slotSize: IntervalLength, intervalLength: IntervalLength) => {
  return Math.max(maxSlotHeight / (slotSize / intervalLength), minSlotHeight)
}
export const getSubSlotHeight = (slotSize: IntervalLength, intervalLength: IntervalLength) => {
  return getSubSlotHeightValue(slotSize, intervalLength) + "rem"
}
export const getResourceStart = (resource: Ressource, weekday: number) => {
  return resource.availability[weekday][0] ? resource.availability[weekday][0].start : 0
}
export const getResourceEnd = (resource: Ressource, weekday: number) => {
  return resource.availability[weekday][0] ? resource.availability[weekday][0].end : 1440
}

// Hook
export function useEventListener(eventName: string, handler: Function, element = window) {
  // Create a ref that stores handler
  const savedHandler = useRef<Function>()

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener
      if (!isSupported) return

      // Create event listener that calls handler function stored in ref
      const eventListener: EventListenerOrEventListenerObject = (event) =>
        savedHandler.current && savedHandler.current(event)

      // Add event listener
      element.addEventListener(eventName, eventListener)

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener)
      }
    },
    [eventName, element], // Re-run if eventName or element changes
  )
}
