import { Box, Paper } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { Booking, useBookingsForUser, useRessources, UserRoles, useUsers } from "api/scheduleAPI"
import { SortableTable } from "features/userTags/SortableTable"
import { DateTime } from "luxon"
import React, { useEffect } from "react"
import { useIntl } from "react-intl"
import { useQueryClient } from "react-query"

export const BookingsListView = () => {
  const { data: users } = useUsers()
  const { data: resources } = useRessources()
  const externalPlayer = users?.find((user) => user.role === UserRoles.External)
  const { data: bookings } = useBookingsForUser(externalPlayer?.id)
  const queryClient = useQueryClient()
  const intl = useIntl()

  useEffect(() => {
    if (bookings === undefined && externalPlayer !== undefined) {
      queryClient.invalidateQueries("bookings_by_" + externalPlayer.id)
    }
  }, [bookings, externalPlayer])
  const renderBookingRow = (booking: Booking) => {
    return {
      id: booking.id,
      participants: booking.participants_ids?.map((id) => users?.find((u) => u.id === id)?.completeName).join(" | "),
      resource: resources?.find((r) => r.id === booking.ressource_id)?.name,
      time:
        booking.startDateTime.toLocaleString(DateTime.DATETIME_SHORT) +
        " - " +
        booking.endDateTime.toLocaleString(DateTime.DATETIME_SHORT),
    }
  }

  return (
    <Box p={2} mt={2}>
      <Paper>
        {bookings === undefined || bookings.length === 0 ? (
          <Alert severity="info">
            {intl.formatMessage({
              id: "externalBookings.Empty",
              description: "No Extenral Bookings label",
              defaultMessage: "No external player bookings yet.",
            })}
          </Alert>
        ) : (
          <SortableTable
            pagination={true}
            title={intl.formatMessage({
              id: "externalBookings.Title",
              description: "External bookings TItle label",
              defaultMessage: "Bookings with external players",
            })}
            headCells={[
              { id: "id", label: "ID", numeric: false },
              { id: "participants", label: "Participants", numeric: false },
              { id: "resource", label: "Court", numeric: false },
              { id: "time", label: "Duration", numeric: false },
            ]}
            rows={bookings?.map((booking) => renderBookingRow(booking))}
          />
        )}
      </Paper>
    </Box>
  )
}
