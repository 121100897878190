import { Box, Button, Container, Grid, LinearProgress, Typography } from "@material-ui/core"
import MailIcon from "@material-ui/icons/MailOutline"
import { useMails, useUsers } from "api/scheduleAPI"
import { mailsCreate as mailsCreatePath } from "app/routes"
import AppMenuBar from "components/AppDrawer/AppMenuBar"
import React from "react"
import { useIntl } from "react-intl"
import { Link } from "react-router-dom"
import { MailsList } from "./MailsList"

export const MailsListPage = () => {
  // const [searchTerm, setSearchTerm] = useState("");

  const { data: mails, isLoading: isLoadingMails } = useMails()
  const { data: users, isLoading: isLoadingUsers } = useUsers()
  const intl = useIntl()
  const isLoading = isLoadingMails && isLoadingUsers

  return (
    <Container component="main" maxWidth="lg">
      <AppMenuBar>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5">
              {intl.formatMessage({
                id: "mailList.Page.Title",
                description: "Mail Page title",
                defaultMessage: "Mails",
              })}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              component={Link}
              to={mailsCreatePath}
              variant="contained"
              color="primary"
              style={{
                marginLeft: "6px",
                alignItems: "end",
              }}
              startIcon={<MailIcon />}
            >
              {intl.formatMessage({
                id: "mailList.Page.NewMailButton",
                description: "Button to create new mail",
                defaultMessage: "New Mail",
              })}
            </Button>
          </Grid>
        </Grid>
      </AppMenuBar>
      {isLoading || mails === undefined || users === undefined ? (
        <Box p={10}>
          <LinearProgress />
        </Box>
      ) : (
        <MailsList mails={mails} users={users} />
      )}
    </Container>
  )
}
