import { MenuItem } from "@material-ui/core"
import { IntervalLength } from "features/booking/utils/BookingTypes"
import React from "react"
import ReactHookFormSelect, { FormSelectProps } from "./ReactHookFormSelect"

type Props = Omit<FormSelectProps, "children"> & {
  name: string
  label: string
  control: any
  defaultValue?: any
  startMinute: number
  endMinute: number
  granularity: IntervalLength
  [otherProps: string]: any
}

const zeroPad = (num: number, places: number) => String(num).padStart(places, "0")

const ReactHookTimeSelect: React.FC<Props> = ({ startMinute, endMinute, granularity, ...props }) => {
  let intervals: number[] = []
  for (let minutes = startMinute; minutes <= endMinute; minutes += granularity) {
    intervals.push(minutes)
  }

  return (
    <ReactHookFormSelect {...props}>
      {intervals.map((minutes) => {
        return (
          <MenuItem value={minutes} key={minutes}>
            {zeroPad(~~(minutes / 60), 2)}:{zeroPad(minutes % 60, 2)}
          </MenuItem>
        )
      })}
    </ReactHookFormSelect>
  )
}
export default ReactHookTimeSelect
