import { Box, Button, Checkbox, Container, Divider, FormGroup, Grid, Paper, TextField } from "@material-ui/core"
import Fab from "@material-ui/core/Fab"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"
import InputAdornment from "@material-ui/core/InputAdornment"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import { makeStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import ArrowBackTwoToneIcon from "@material-ui/icons/ArrowBackTwoTone"
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined"
import SaveIcon from "@material-ui/icons/Save"
import { DatePicker } from "@material-ui/pickers"
import { Notifications, UserCreate, UserRoles, UserUpdate, useTags } from "api/scheduleAPI"
import PaperTitle from "components/paperTitle"
import RenderOnRole from "features/auth/RenderOnRole"
import { UserTags } from "features/userTags/UserTags"
import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { bit_test } from "utils/Bits"
const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  grid: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: theme.spacing(6, 6, 6),
  },
  back: {
    marginTop: theme.spacing(2),
  },
}))

type UserFormProps = {
  user: UserUpdate | UserCreate
  title?: string
  subtitle?: string
  backButtonUrl?: string
  editorIsAdmin: boolean
  onFormSubmit: Function
  showPasswordField: boolean
} & typeof defaultProps

const defaultProps = {
  title: "",
  subtitle: "",
  backButtonUrl: "",
  editorIsAdmin: false,
  showDelete: false,
  showPasswordField: false,
}

const SaveButton = () => {
  return (
    <Button type="submit" variant="contained" color="primary" startIcon={<SaveIcon />}>
      Save changes
    </Button>
  )
}

export const UserForm = (props: UserFormProps) => {
  const classes = useStyles()
  const history = useHistory()
  const { data: tags } = useTags()
  const paidTag = tags?.find((tag) => tag.type === 2 && tag.name === "paid")
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [paid, setPaid] = useState(false)
  const [notifications, setNotifications] = useState<number>(props.user.notifications || 0)

  const { register, control, handleSubmit } = useForm<UserCreate | UserUpdate>({
    defaultValues: props.user,
  })
  const { ref: refFirstName, ...restFirstname } = register("firstname")
  const { ref: refLastName, ...restLastName } = register("lastname")
  const { ref: refUserName, ...restUserName } = register("username", { required: true })
  const { ref: refEmail, ...restEmail } = register("email", { required: true })
  const { ref: refAddress, ...restAddress } = register("address")
  const { ref: refPhone, ...restPhone } = register("phone")
  const { ref: refPassword, ...restPassword } = register("password", { required: props.showPasswordField })

  useEffect(() => {
    if (paidTag && tags && props.user.tags) {
      setPaid(props.user.tags.findIndex((t) => t.id === paidTag.id) >= 0)
    }
  }, [paidTag, notifications])

  const onSubmit = (user: UserCreate | UserUpdate) => {
    const removedPaidArray = user.tags?.filter((tag) => tag.id !== paidTag?.id)
    if (paid && paidTag) {
      removedPaidArray?.push({ id: paidTag.id })
    }
    user.notifications = notifications
    user.tags = removedPaidArray
    props.onFormSubmit(user)
  }

  const BackButton = () => {
    if (props.backButtonUrl) {
      return (
        <Tooltip className={classes.back} title="Back" aria-label="back" arrow placement="right">
          <Fab color="primary" size="small" onClick={() => history.push(props.backButtonUrl)}>
            <ArrowBackTwoToneIcon />
          </Fab>
        </Tooltip>
      )
    }
    return null
  }

  const onInValid = (err: any) => {
    console.log("onInValid", err)
  }

  return (
    <Container component="main" maxWidth="md">
      <BackButton />
      <form className={classes.form} onSubmit={handleSubmit(onSubmit, onInValid)}>
        <Paper className={classes.paper}>
          <PaperTitle title={props.title} subtitle={props.subtitle} />
          <Divider />
          <Grid className={classes.grid} container>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField {...restFirstname} label="Firstname" inputRef={refFirstName} id="firstname" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField {...restLastName} label="Lastname" inputRef={refLastName} id="lastname" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  {...restUserName}
                  label="Username"
                  helperText="Used for login"
                  inputRef={refUserName}
                  id="username"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  inputRef={refEmail}
                  id="email"
                  label="Email Address"
                  {...restEmail}
                  type="email"
                  disabled={!props.editorIsAdmin}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField multiline rows={4} {...restAddress} label="Address" inputRef={refAddress} id="address" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField {...restPhone} label="Phone Number" inputRef={refPhone} id="phone" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="birthday"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      disableFuture
                      label="Birthday"
                      open={showDatePicker}
                      inputFormat="dd/MM/yyyy"
                      renderInput={(dateProps) => (
                        <TextField
                          {...dateProps}
                          onClick={(event: React.MouseEvent) => setShowDatePicker(!showDatePicker)}
                        />
                      )}
                      value={value}
                      onChange={(date) => {
                        if (date) {
                          onChange(date.toSQLDate())
                          setShowDatePicker(false)
                        }
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {props.showPasswordField && (
                  <TextField
                    required
                    {...restPassword}
                    label="Password"
                    type="password"
                    inputRef={refPassword}
                    id="password"
                  />
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl component="fieldset" margin="normal">
                  <FormLabel component="legend">Gender</FormLabel>
                  <Controller
                    control={control}
                    name="gender"
                    render={({ field }) => (
                      <RadioGroup row {...field}>
                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                        <FormControlLabel value="female" control={<Radio />} label="Female" />
                        <FormControlLabel value="divers" control={<Radio />} label="Divers" />
                        <FormControlLabel value="not_specified" control={<Radio />} label="Unspecified" />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="flex-end">
            <SaveButton />
          </Box>
        </Paper>

        <RenderOnRole roles={[UserRoles.Admin]}>
          <Paper className={classes.paper}>
            <PaperTitle title="Permissions & Subscription" />
            <Divider />
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">Payment</FormLabel>
              <FormGroup aria-label="position">
                <FormControlLabel
                  label="Paid"
                  control={
                    <Checkbox
                      color="primary"
                      checked={paid}
                      onChange={(event, checked) => {
                        setPaid(checked)
                      }}
                    />
                  }
                />
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">Role</FormLabel>
              <Controller
                control={control}
                name="role"
                render={({ field }) => (
                  <RadioGroup aria-label="role" row {...field}>
                    <FormControlLabel value="user" control={<Radio />} label="User" />
                    <FormControlLabel value="admin" control={<Radio />} label="Admin" />
                  </RadioGroup>
                )}
              />
            </FormControl>
            <UserTags control={control} tagOptions={tags} />
            <Box display="flex" justifyContent="flex-end">
              <SaveButton />
            </Box>
          </Paper>
        </RenderOnRole>

        <Paper className={classes.paper}>
          <PaperTitle title="Notifications by Mail" />
          <Divider />
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">Notifications</FormLabel>
            <FormGroup>
              <FormControlLabel
                label="On booking change"
                control={
                  <Checkbox
                    color="primary"
                    checked={bit_test(notifications, Notifications.BOOKING_CHANGE)}
                    onChange={(event, checked) => {
                      if (checked) {
                        setNotifications(notifications + Notifications.BOOKING_CHANGE)
                      } else {
                        setNotifications(notifications - Notifications.BOOKING_CHANGE)
                      }
                    }}
                  />
                }
              />
              <RenderOnRole roles={[UserRoles.Admin]}>
                <FormControlLabel
                  label="On booking request"
                  control={
                    <Checkbox
                      color="primary"
                      checked={bit_test(notifications, Notifications.BOOKING_REQUEST)}
                      onChange={(event, checked) => {
                        if (checked) {
                          setNotifications(notifications + Notifications.BOOKING_REQUEST)
                        } else {
                          setNotifications(notifications - Notifications.BOOKING_REQUEST)
                        }
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label="On booking with guest"
                  control={
                    <Checkbox
                      color="primary"
                      checked={bit_test(notifications, Notifications.BOOKING_WITH_GUEST)}
                      onChange={(event, checked) => {
                        if (checked) {
                          setNotifications(notifications + Notifications.BOOKING_WITH_GUEST)
                        } else {
                          setNotifications(notifications - Notifications.BOOKING_WITH_GUEST)
                        }
                      }}
                    />
                  }
                />
              </RenderOnRole>
            </FormGroup>
          </FormControl>
          <Box display="flex" justifyContent="flex-end">
            <SaveButton />
          </Box>
        </Paper>
      </form>
    </Container>
  )
}

UserForm.defaultProps = defaultProps
export default UserForm
