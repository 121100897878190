import {
  AppSettings,
  AuthApi,
  BookingRead,
  BookingsApi,
  BookingType,
  MailsApi,
  RessourceRead,
  RessourcesApi,
  SettingsApi,
  UploadApi,
  UserResponse,
  UsersApi,
} from "api/generated/schedule/api"
import { Configuration } from "api/generated/schedule/configuration"
import Config from "app/config"
import axios, { AxiosInstance, AxiosRequestConfig } from "axios"
import { DateTime } from "luxon"
import { useQuery } from "react-query"
import { rrulestr } from "rrule"

export { AuthApi, BookingType, UserRoles } from "api/generated/schedule/api"
export type {
  AppSettings,
  BookingCreate,
  BookingRead,
  BookingUpdate,
  RessourceCreate,
  RessourceRead,
  RessourceUpdate,
  Rule,
  SendMailCreate,
  TagRead,
  UploadCreate,
  UploadRead,
  UserCreate,
  UserLoginResponse,
  UserResponse,
  UserUpdate,
} from "api/generated/schedule/api"

const getToken = () => {
  return localStorage.getItem("token")
}

const config: AxiosRequestConfig = {}
export const client: AxiosInstance = axios.create(config)

client.interceptors.request.use(
  (req) => {
    req.headers["Authorization"] = `Bearer ${getToken()}`
    return req
  },
  (error) => {
    return Promise.reject(error)
  },
)

export const configuration = new Configuration({
  basePath: Config.apiServer,
})

export type Login = {
  username: string
  password: string
}

export type Booking = BookingRead & {
  startDateTime: DateTime
  endDateTime: DateTime
}
export type User = UserResponse & {
  completeName: string
}
export type Ressource = RessourceRead
export type Settings = AppSettings & {
  availabilityStart: DateTime
  availabilityEnd: DateTime
}
export type Availability = {
  day: number
  openingTimes: {
    start: DateTime
    end: DateTime
  }[]
}

export enum Notifications {
  BOOKING_CHANGE = 1,
  BOOKING_REQUEST = 2,
  BOOKING_WITH_GUEST = 4,
}

export const bookingsApi = new BookingsApi(new Configuration({}), Config.apiServer, client)
export const settingsApi = new SettingsApi(new Configuration({}), Config.apiServer, client)
export const ressourcesApi = new RessourcesApi(new Configuration({}), Config.apiServer, client)
export const usersApi = new UsersApi(new Configuration({}), Config.apiServer, client)
export const authApi = new AuthApi(new Configuration({}), Config.apiServer, client)
export const mailsApi = new MailsApi(new Configuration({}), Config.apiServer, client)
export const uploadApi = new UploadApi(new Configuration({}), Config.apiServer, client)

export async function resetPassword(token: string, new_password: string) {
  return await authApi.resetPassword({ token, new_password })
}

export async function recoverPassword(email: string) {
  return await authApi.recoverPassword(email)
}

export function useBookingsForDay(day: DateTime) {
  return useBookings(day.startOf("day"), day.endOf("day"))
}
export function useBookingsForWeek(day: DateTime) {
  const startString = day.startOf("week").startOf("day").toUTC(0).toISO()
  const endString = day.endOf("week").endOf("day").toUTC(0).toISO()
  return useQuery("bookings_" + startString + endString, async () => {
    const data = await bookingsApi.getBookings(startString, endString)
    const bookings: Booking[] = data.data.map((booking: BookingRead) => {
      return {
        ...booking,
        startDateTime: DateTime.fromISO(booking.start, {
          zone: "UTC+0",
        }),
        endDateTime: DateTime.fromISO(booking.end, {
          zone: "UTC+0",
        }),
      }
    }) as Booking[]
    return bookings
  })
}

export function useBookingsForUser(userID: number | undefined) {
  return useQuery("bookings_by_" + userID, async () => {
    if (userID === undefined) {
      return undefined
    }
    const data = await bookingsApi.getBookings(undefined, undefined, undefined, undefined, userID)
    let bookings: Booking[] = data.data.map((booking: BookingRead) => {
      return {
        ...booking,
        startDateTime: DateTime.fromISO(booking.start, {
          zone: "UTC+0",
        }),
        endDateTime: DateTime.fromISO(booking.end, {
          zone: "UTC+0",
        }),
      }
    }) as Booking[]
    return bookings
  })
}

function setUTCPartsToDate(d: Date) {
  return new Date(
    d.getUTCFullYear(),
    d.getUTCMonth(),
    d.getUTCDate(),
    d.getUTCHours(),
    d.getUTCMinutes(),
    d.getUTCSeconds(),
  )
}

function setPartsToUTCDate(d: Date) {
  return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), d.getSeconds()))
}

export function useBookings(startDate: DateTime, endDate: DateTime) {
  const startString = startDate.startOf("week").startOf("day").toUTC(0).toISO()
  const endString = endDate.endOf("week").endOf("day").toUTC(0).toISO()
  return useQuery("bookings_" + startString + endString, async () => {
    const data = await bookingsApi.getBookings(startString, endString)
    const recurringBookings: Booking[] = []
    let bookings: Booking[] = data.data.map((booking: BookingRead) => {
      let newBooking = {
        ...booking,
        startDateTime: DateTime.fromISO(booking.start, {
          zone: "UTC+0",
        }),
        endDateTime: DateTime.fromISO(booking.end, {
          zone: "UTC+0",
        }),
      }
      if (newBooking.rrule) {
        let rrule = rrulestr(newBooking.rrule)

        let diff = newBooking.endDateTime.hour - newBooking.startDateTime.hour

        let occurences = rrule
          .between(startDate.startOf("week").startOf("day").toJSDate(), endDate.endOf("week").endOf("day").toJSDate())
          .map(setUTCPartsToDate)

        occurences.forEach((occ) => {
          const newStart = newBooking.startDateTime.set({
            day: occ.getDate(),
            month: occ.getMonth() + 1,
            year: occ.getFullYear(),
            hour: occ.getHours(),
          })
          var startString = newStart.toUTC(0).toISO()

          const newEnd = newBooking.endDateTime.set({
            day: occ.getDate(),
            month: occ.getMonth() + 1,
            year: occ.getFullYear(),
            hour: occ.getHours() + diff,
          })

          var endString = newEnd.toUTC(0).toISO()

          recurringBookings.push({
            ...newBooking,
            start: startString,
            end: endString,
            startDateTime: newStart,
            endDateTime: newEnd,
          })
        })
        return undefined
      } else {
        return newBooking
      }
    }) as Booking[]
    bookings = bookings.filter((b) => b !== undefined)
    bookings.push(...recurringBookings)
    bookings = bookings.filter(
      (b) =>
        b.startDateTime >= startDate.startOf("week").startOf("day").toUTC(0) &&
        b.endDateTime <= endDate.endOf("week").endOf("day").toUTC(0),
    )
    return bookings
  })
}

export function useBookingRequestsForUser(user: User | undefined) {
  const userID = user ? user.id : -1
  return useQuery("booking_requests_".concat(userID.toString()), async () => {
    const data = await bookingsApi.getBookings(undefined, undefined, undefined, [BookingType.Request])
    return data.data
      .filter((b) => b.participants_ids?.includes(userID))
      .map((booking: BookingRead) => {
        return {
          ...booking,
          startDateTime: DateTime.fromISO(booking.start, {
            zone: "UTC+0",
          }),
          endDateTime: DateTime.fromISO(booking.end, {
            zone: "UTC+0",
          }),
        }
      }) as Booking[]
  })
}
export function useBookingRequests() {
  return useQuery("booking_requests", async () => {
    const data = await bookingsApi.getBookings(undefined, undefined, undefined, [BookingType.Request])
    return data.data.map((booking: BookingRead) => {
      return {
        ...booking,
        startDateTime: DateTime.fromISO(booking.start, {
          zone: "UTC+0",
        }),
        endDateTime: DateTime.fromISO(booking.end, {
          zone: "UTC+0",
        }),
      }
    }) as Booking[]
  })
}

export function useRessources() {
  return useQuery("ressources", async () => {
    const data = await ressourcesApi.getRessources()
    return data.data
  })
}

export function useSettings() {
  return useQuery(
    "settings",
    async () => {
      const data = await settingsApi.getSettings()
      const dt = DateTime.local()
      const settings: Settings = {
        ...data.data,
        availabilityStart: dt.set({
          hour: data.data.hours_of_availability.displayHours.start / 60,
          minute: 0,
          second: 0,
        }),
        availabilityEnd: dt.set({
          hour: data.data.hours_of_availability.displayHours.end / 60,
          minute: 0,
          second: 0,
        }),
      }
      return settings
    },
    { staleTime: Infinity },
  )
}

export function useMe() {
  const RETRIES = 1
  return useQuery(
    "me",
    async () => {
      const data = await usersApi.getMe()
      return {
        ...data.data,
        completeName: data.data.firstname + " " + data.data.lastname,
      } as User
    },
    {
      retry: (failureCount, error) => {
        if (error instanceof Response && error.status === 401) {
          return false
        }
        return failureCount < RETRIES
      },
    },
  )
}

export function useUsers() {
  return useQuery("users", async () => {
    const data = await usersApi.getUsers()
    const users = data.data.map((user: UserResponse) => {
      return { ...user, completeName: user.firstname + " " + user.lastname }
    }) as User[]
    return users
  })
}

export function useUser(userId: number) {
  return useQuery(["user", userId], async () => {
    const data = await usersApi.getUser(userId)
    return {
      ...data.data,
      completeName: data.data.firstname + " " + data.data.lastname,
    }
  })
}

export function useMails() {
  return useQuery("mails", async () => {
    const data = await mailsApi.getMails()
    return data.data
  })
}

export function useTags() {
  return useQuery("tags", async () => {
    const data = await usersApi.getTags()
    return data.data
  })
}

export const downloadUploadedFile = async (uuid: string, download_name: string) => {
  console.log("download File")
  const response = await uploadApi.getUploadFile(uuid)
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement("a")
  link.setAttribute("download", download_name)
  link.href = url
  link.target = "_blank"
  link.click()
}
