import { Card, Grid, Tooltip, Typography } from "@material-ui/core"
import { Booking, BookingType, TagRead, User } from "api/scheduleAPI"
import { DateTime } from "luxon"
import React from "react"
import { pickTextColorBasedOnBgColor, useBookingColor } from "../utils/BookingColorManager"

type BookingCardViewProps = {
  mouseDown: Function
  mouseEnter: (event: React.MouseEvent) => void
  granularity: number
  height: number
  anchorEl: HTMLElement | null
  booking: Booking
  classes: any
  user: User | undefined
  users: User[] | undefined
  tag: TagRead | undefined
}
export const BookingCardView = (props: BookingCardViewProps) => {
  const parentTable = document.getElementById("schedulerTable")
  const parentRect = parentTable?.getBoundingClientRect()
  const targetRect = props.anchorEl && props.anchorEl.getBoundingClientRect()
  const users = props.booking.participants_ids?.map((userID) => {
    return props.users?.find((user) => user.id === userID)
  })
  const cardMargin = props.booking.booking_type === BookingType.Blocked ? 0 : 4
  const bookingDuration = props.booking.endDateTime.diff(props.booking.startDateTime).as("minutes")
  let cardHeightValue = bookingDuration ? ((props.height + 0.065) * bookingDuration) / props.granularity : 0
  let cardHeight = cardHeightValue + "rem"
  let cardWidth = props.anchorEl && props.anchorEl.offsetWidth
  const bookingColor = useBookingColor(
    props.booking.booking_type,
    props.user?.id,
    props.booking.participants_ids,
    props.tag,
    1,
  )
  const bookingTextColor = pickTextColorBasedOnBgColor(bookingColor, "#ffffff", "#1E1E1E")
  if (props.anchorEl === null || cardWidth === null) {
    return null
  }
  const userList = users?.map((user) => {
    return user?.completeName
  })
  const tooltipContent = (
    <React.Fragment>
      {props.booking.title !== "" && <Typography variant="subtitle2">{props.booking.title}</Typography>}
      <Typography variant="body1">{userList?.join(" & ")}</Typography>
      <Typography variant="body2">
        {props.booking.startDateTime.toLocaleString(DateTime.TIME_24_SIMPLE) +
          " - " +
          props.booking.endDateTime.toLocaleString(DateTime.TIME_24_SIMPLE)}
      </Typography>
    </React.Fragment>
  )
  return (
    <div
      id={"container" + props.booking.id}
      key={"slot_" + props.booking.id}
      style={{
        position: "relative",
        width: "0px",
        height: "0px",
        top: "0px",
        background: "yellow",
      }}
      onMouseDown={(event: React.MouseEvent) => props.mouseDown(event, props.booking)}
      onMouseEnter={props.mouseEnter}
    >
      <div
        style={{
          position: "absolute",
          background: "transparent",
          height: cardHeight,
          width: cardWidth,
          left: targetRect && parentRect ? targetRect.left - parentRect.left : 0,
          top: targetRect && parentRect && parentTable ? targetRect.top - parentRect.top + parentTable.scrollTop : 0,
        }}
      >
        <Tooltip title={tooltipContent} arrow>
          <Card
            className={
              props.booking.booking_type === BookingType.Blocked
                ? props.classes.blockedBookingCardView
                : props.classes.bookingCardView
            }
            style={{
              height: `calc(100% - ${2 * cardMargin}px)`,
              width: `calc(100% - ${2 * cardMargin}px)`,
              margin: cardMargin,
              background: bookingColor,
              zIndex: 0,
            }}
          >
            <Grid container spacing={2} className={props.classes.timePopper}>
              <Grid item xs={12}>
                {props.booking.booking_type === BookingType.User ? (
                  <Typography
                    noWrap
                    style={{ color: bookingTextColor }}
                    className={props.classes.playerPopperTypography}
                  >
                    {userList?.join(" & ")}
                  </Typography>
                ) : (
                  <Typography style={{ color: bookingTextColor }} className={props.classes.playerPopperTypography}>
                    {props.booking.title}
                  </Typography>
                )}
                {/* <Typography style={{ color: bookingTextColor }} className={props.classes.timePopperTypography}>
                  {props.booking.startDateTime.toLocaleString(DateTime.TIME_24_SIMPLE) +
                    " - " +
                    props.booking.endDateTime.toLocaleString(DateTime.TIME_24_SIMPLE)}
                </Typography> */}
              </Grid>
            </Grid>
          </Card>
        </Tooltip>
      </div>
    </div>
  )
}
