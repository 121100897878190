const populate = (a: string, seed: number) => {
  const hexValues = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "b", "c", "d", "e"]
  for (let i = 0; i < 6; i++) {
    const result = randomSeed(0, 1, seed)
    seed = result.seed
    let x = Math.round(result.random * 14)
    let y = hexValues[x]
    a += y
  }
  return a
}
const randomSeed = (min: number, max: number, seed: number) => {
  max = max || 1
  min = min || 0

  seed = (seed * 9301 + 49297) % 233280
  let rnd = seed / 233280

  return {
    random: min + rnd * (max - min),
    seed: seed,
  }
}
export const GradientGenerator = (userID: number) => {
  let newColor1 = populate("#", userID)
  let newColor2 = populate("#", userID * 2)

  return "linear-gradient(45deg, " + newColor1 + ", " + newColor2 + ")"
}
export const ColorGenerator = (userID: number, transparency: string) => {
  let newColor1 = populate("#", userID)
  return hexToRGB(newColor1, transparency)
}
export const hexToRGB = (hex: string, alpha: string) => {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")"
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")"
  }
}
