import { FormControl, InputLabel, Select } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import { Controller } from "react-hook-form"

export type FormSelectProps = {
  name: string
  label: string
  control: any
  defaultValue?: any
  children: React.ReactNode
  [otherProps: string]: any
}

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: theme.spacing(50),
  },
}))

const ReactHookFormSelect: React.FC<FormSelectProps> = ({ name, label, control, defaultValue, children, ...props }) => {
  const classes = useStyles()
  const labelId = `${name}-label`

  return (
    <FormControl {...props}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        render={({ field: { onChange, value } })  => (
          <Select onChange={onChange} value={value} labelId={labelId} label={label} MenuProps={{ classes: { paper: classes.menuPaper } }}>
            {children}
          </Select>
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </FormControl>
  )
}
export default ReactHookFormSelect
