import { TagNested } from "api/generated/schedule"
import { Booking, BookingType, useBookingsForDay, useMe, useRessources, UserRoles, useSettings } from "api/scheduleAPI"
import { getResourceEnd, getResourceStart } from "features/booking/utils/BookingSlotHandler"
import { DateTime } from "luxon"
import * as React from "react"

type CreateBooking = {
  resourceID: number
  time: DateTime
  bookingType: BookingType
}
export type UpdateBooking = {
  id?: number
  title?: string
  booking_type?: BookingType
  resource_id?: number
  startDateTime?: DateTime
  endDateTime?: DateTime
  notes?: string
  rrule?: string
  participants_ids?: Array<number>
  tags?: Array<TagNested>
}

export type BookingContext = ReturnType<typeof useBookingProvider>
const Context = React.createContext<BookingContext | undefined>(undefined)
Context.displayName = "BookingContext"
export function useBookingContext(): BookingContext {
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error(`Upsidupsi keen Provider`)
  }
  return context
}

function useBookingProvider() {
  const [booking, setBooking] = React.useState<Booking | undefined>()
  const { data: settings } = useSettings()
  const { data: resources } = useRessources()
  const { data: user } = useMe()
  const selectedDate = booking ? booking.startDateTime : DateTime.local()
  const { data: bookings } = useBookingsForDay(selectedDate)
  const creatingBooking = booking !== undefined

  const validateBooking = React.useCallback(
    (newBooking: Booking): Booking | undefined => {
      if (settings === undefined || resources === undefined) {
        return newBooking
      }
      const minDuration =
        user?.role === UserRoles.Admin ? settings.booking.min_duration_minutes : settings.booking.min_duration_minutes // if admin --> settings.time_granularity but this has frontend bug
      const maxDuration = user?.role === UserRoles.Admin ? -1 : settings.booking.max_duration_minutes
      if (booking) {
        const duration = newBooking.endDateTime.diff(newBooking.startDateTime).as("minutes")
        if (minDuration > 0 && duration < minDuration) {
          newBooking = booking
        }
        if (maxDuration > 0 && duration > maxDuration) {
          //if start is equal, end changed, move start down
          if (+booking.startDateTime === +newBooking.startDateTime) {
            newBooking.startDateTime = newBooking.endDateTime.minus({
              minutes: maxDuration,
            })
          }
          //if end is equal, start changed, move end up
          if (+booking.endDateTime === +newBooking.endDateTime) {
            newBooking.endDateTime = newBooking.startDateTime.plus({
              minutes: maxDuration,
            })
          }
        }
      }
      const collidingBookings = bookings?.filter(
        (b) =>
          b.id !== newBooking.id &&
          b.ressource_id === newBooking.ressource_id &&
          b.startDateTime.toDays() === newBooking.startDateTime.toDays(),
      )
      if (collidingBookings && collidingBookings.length > 0) {
        const upperLimit = collidingBookings.reduce((prev, curr) =>
          Math.abs(curr.endDateTime.diff(newBooking.startDateTime).as("minutes")) <
          Math.abs(prev.endDateTime.diff(newBooking.startDateTime).as("minutes"))
            ? curr
            : prev,
        )
        const lowerLimit = collidingBookings.reduce((prev, curr) =>
          Math.abs(curr.startDateTime.diff(newBooking.endDateTime).as("minutes")) <
          Math.abs(prev.startDateTime.diff(newBooking.endDateTime).as("minutes"))
            ? curr
            : prev,
        )
        let collision = false
        if (
          newBooking.startDateTime.toMinutes() > upperLimit.startDateTime.toMinutes() &&
          newBooking.startDateTime.toMinutes() < upperLimit.endDateTime.toMinutes()
        ) {
          collision = true
          newBooking.startDateTime = upperLimit.endDateTime
        }
        if (
          newBooking.startDateTime.toMinutes() < lowerLimit.startDateTime.toMinutes() &&
          newBooking.endDateTime.toMinutes() > lowerLimit.startDateTime.toMinutes()
        ) {
          collision = true
          newBooking.endDateTime = lowerLimit.startDateTime
        }
        if (collision && booking === undefined) {
          return undefined
        }
      }
      let resource = resources.find((res) => res.id === newBooking.ressource_id)
      if (newBooking.startDateTime.toMinutes() < settings.hours_of_availability.displayHours.start) {
        newBooking.startDateTime = newBooking.startDateTime.setMinutes(
          settings.hours_of_availability.displayHours.start,
        )
        newBooking.endDateTime = newBooking.startDateTime.plus({
          minutes: minDuration,
        })
      }
      if (
        resource &&
        newBooking.startDateTime.toMinutes() < getResourceStart(resource, newBooking.startDateTime.weekday)
      ) {
        newBooking.startDateTime = newBooking.startDateTime.setMinutes(
          getResourceStart(resource, newBooking.startDateTime.weekday),
        )
        newBooking.endDateTime = newBooking.startDateTime.plus({
          minutes: minDuration,
        })
      }
      if (newBooking.endDateTime.toMinutes() > settings.hours_of_availability.displayHours.end) {
        newBooking.endDateTime = newBooking.endDateTime.setMinutes(settings.hours_of_availability.displayHours.end)
        newBooking.startDateTime = newBooking.endDateTime.minus({
          minutes: minDuration,
        })
      }
      if (resource && newBooking.endDateTime.toMinutes() > getResourceEnd(resource, newBooking.endDateTime.weekday)) {
        newBooking.endDateTime = newBooking.endDateTime.setMinutes(
          getResourceEnd(resource, newBooking.endDateTime.weekday),
        )
        newBooking.startDateTime = newBooking.endDateTime.minus({
          minutes: minDuration,
        })
      }
      if (newBooking.booking_type === undefined) {
        newBooking.booking_type = BookingType.User
      }
      return newBooking
    },
    [booking, settings, resources, bookings],
  )
  const createBooking = React.useCallback(
    (newBooking: CreateBooking) => {
      if (!creatingBooking) {
        if (newBooking && user) {
          const newBookingContent = validateBooking({
            ressource_id: newBooking.resourceID,
            start: newBooking.time.toISODate(),
            startDateTime: newBooking.time,
            endDateTime: newBooking.time.plus({
              minutes:
                settings?.booking.min_duration_minutes === -1
                  ? settings?.time_granularity_minutes
                  : settings?.booking.min_duration_minutes,
            }),
            booking_type: newBooking.bookingType,
            owner_id: user.id,
          } as Booking)
          setBooking(newBookingContent)
        }
      }
    },
    [creatingBooking, settings, user, validateBooking],
  )
  const loadBooking = React.useCallback(
    (loadedBooking: Booking) => {
      if (booking === undefined) {
        setBooking(loadedBooking)
      }
    },
    [booking],
  )
  const updateBooking = React.useCallback(
    (bookingUpdate: UpdateBooking) => {
      let updatedBooking: Booking | undefined = undefined
      if (booking) {
        updatedBooking = { ...booking, ...bookingUpdate }
      } else {
        if (user) {
          updatedBooking = {
            ressource_id: -1,
            start: DateTime.local().toISODate(),
            startDateTime: DateTime.local(),
            endDateTime: DateTime.local().plus({
              minutes:
                settings?.booking.min_duration_minutes === -1
                  ? settings?.time_granularity_minutes
                  : settings?.booking.min_duration_minutes,
            }),
            booking_type: BookingType.User,
            owner_id: user.id,
            ...bookingUpdate,
          } as Booking
        }
      }
      if (updatedBooking) {
        updatedBooking = validateBooking(updatedBooking)
        if (updatedBooking !== undefined) {
          setBooking({ ...updatedBooking })
        }
      }
      return updatedBooking
    },
    [booking, user, validateBooking, settings],
  )
  const cancelBooking = React.useCallback(() => {
    setBooking(undefined)
  }, [])
  return React.useMemo(
    () => ({
      booking,
      creatingBooking,
      loadBooking,
      createBooking,
      updateBooking,
      cancelBooking,
      validateBooking,
    }),
    [
      booking,
      settings,
      resources,
      user,
      cancelBooking,
      createBooking,
      creatingBooking,
      loadBooking,
      updateBooking,
      validateBooking,
    ],
  )
}
export const BookingProvider: React.FC = ({ children }: any) => {
  const value = useBookingProvider()
  return <Context.Provider value={value}>{children}</Context.Provider>
}
