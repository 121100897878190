import { BookingType, TagRead } from "api/scheduleAPI"
import { primaryColor } from "app/withRoot"
import { hexToRGB } from "./GradientGenerator"

export const useBookingColor = (
  type: BookingType | undefined,
  userID: number | undefined,
  participants: number[] | undefined,
  tag: TagRead | undefined,
  transparency: number,
) => {
  switch (type) {
    case BookingType.Blocked:
      return hexToRGB("#737373", transparency.toString())
    case BookingType.Owner:
      if (tag === undefined) {
        return hexToRGB("#CC5449", transparency.toString())
      } else {
        return hexToRGB(tag.color ? tag.color : "#CC5449", transparency.toString())
      }
    case BookingType.User:
      if (userID && participants?.includes(userID)) {
        return primaryColor
      } else {
        return "#617D8B"
      }
    case BookingType.Request:
      return "#6284AB"
    //return ColorGenerator(userID ? userID : 0,transparency.toString());
    default:
      return hexToRGB("#737373", transparency.toString())
  }
}

export const pickTextColorBasedOnBgColor = (bgColor: string | undefined, lightColor: string, darkColor: string) => {
  if (bgColor === undefined) {
    return darkColor
  }
  var color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor
  var r = parseInt(color.substring(0, 2), 16) // hexToR
  var g = parseInt(color.substring(2, 4), 16) // hexToG
  var b = parseInt(color.substring(4, 6), 16) // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor
}
