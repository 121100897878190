import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core"
import { DatePicker } from "@material-ui/pickers"
import { DateTime } from "luxon"
import React from "react"
import { IntlShape, useIntl } from "react-intl"
import { RRule, RRuleSet, rrulestr } from "rrule"
export type Recurrency = {
  recurrency: string
  interval: number
  skipWeekends: boolean
  repetition: string
  endDate: DateTime
}
export const recurrencyFromString = (rruleStr: string) => {
  // const rruleSet = rrulestr(rruleStr) as RRuleSet;
  // const rrule = rruleSet.rrules()[0];
  let rrule = rrulestr(rruleStr)
  if (rrule.hasOwnProperty("_rrule")) {
    // Ruleset
    const rruleSet = rrule as RRuleSet
    rrule = rruleSet.rrules()[0] as RRule
    const recurrency = {
      recurrency: "",
      interval: rrule.options.interval,
      endDate: rrule.options.until ? DateTime.fromJSDate(rrule.options.until) : DateTime.local(),
      repetition: "date",
      skipWeekends: false,
    }
    switch (rrule.options.freq) {
      case RRuleSet.MONTHLY:
        recurrency.recurrency = "Monthly"
        break
      case RRule.DAILY:
        recurrency.recurrency = "Daily"
        break
      case RRule.WEEKLY:
        recurrency.recurrency = "Weekly"
        break
      default:
        recurrency.recurrency = "None"
        break
    }
    if (recurrency.recurrency === "Monthly" && rrule.options.byweekday !== null) {
      recurrency.repetition = "weekday"
    } else if (recurrency.recurrency === "Daily" && rrule.options.byweekday !== null) {
      recurrency.skipWeekends = true
    }
    return recurrency
  } else {
    // RRule
    const recurrency = {
      recurrency: "",
      interval: rrule.options.interval,
      endDate: rrule.options.until ? DateTime.fromJSDate(rrule.options.until) : DateTime.local(),
      repetition: "date",
      skipWeekends: false,
    }
    switch (rrule.options.freq) {
      case RRuleSet.MONTHLY:
        recurrency.recurrency = "Monthly"
        break
      case RRule.DAILY:
        recurrency.recurrency = "Daily"
        break
      case RRule.WEEKLY:
        recurrency.recurrency = "Weekly"
        break
      default:
        recurrency.recurrency = "None"
        break
    }
    if (recurrency.recurrency === "Monthly" && rrule.options.byweekday !== null) {
      recurrency.repetition = "weekday"
    } else if (recurrency.recurrency === "Daily" && rrule.options.byweekday !== null) {
      recurrency.skipWeekends = true
    }
    return recurrency
  }
}
export const getRRule = (recurrency: Recurrency, startDate: DateTime) => {
  const rule = new RRule({
    freq: RRule.FREQUENCIES.findIndex((value) => value.toString() === recurrency.recurrency.toUpperCase()),
    dtstart: startDate.toUTC(0).toJSDate(),
    interval: recurrency.interval,
    until: recurrency.endDate
      ? recurrency.endDate.set({ hour: startDate.hour, minute: startDate.minute }).toUTC(0).toJSDate()
      : startDate.plus({ days: 1 }).toUTC(0).toJSDate(),
    byweekday:
      recurrency.recurrency.toLowerCase() === "monthly" && recurrency.repetition === "weekday"
        ? startDate.weekday - 1
        : recurrency.skipWeekends
        ? [0, 1, 2, 3, 4]
        : null,
    bysetpos: recurrency.repetition === "date" ? null : getWeek(startDate),
  })
  return rule
}
type RecurrencyPickerProps = {
  label: string
  disabled: boolean
  minDate: DateTime
  recurrencyOptions: string[]
  recurrency: Recurrency
  setRecurrency: (type: Recurrency) => void
}
const recurrencyUnitToString = (recurrency: string, intl: IntlShape) => {
  switch (recurrency) {
    case "Daily":
      return intl.formatMessage({
        id: "recurrencyPicker.RecurrencyUnit.Days",
        description: "Recurrency Unit : Days",
        defaultMessage: "day(s)",
      })
    case "Weekly":
      return intl.formatMessage({
        id: "recurrencyPicker.RecurrencyUnit.Weeks",
        description: "Recurrency Unit : Week",
        defaultMessage: "week(s)",
      })
    case "Monthly":
      return intl.formatMessage({
        id: "recurrencyPicker.RecurrencyUnit.Months",
        description: "Recurrency Unit : Month",
        defaultMessage: "month(s)",
      })
    case "Yearly":
      return intl.formatMessage({
        id: "recurrencyPicker.RecurrencyUnit.Years",
        description: "Recurrency Unit : Year",
        defaultMessage: "year(s)",
      })
  }
  return ""
}
const recurrencyOptionsToString = (recurrency: string, intl: IntlShape) => {
  switch (recurrency) {
    case "Daily":
      return intl.formatMessage({
        id: "recurrencyPicker.RecurrencyOption.Days",
        description: "Recurrency Option : Days",
        defaultMessage: "Daily",
      })
    case "Weekly":
      return intl.formatMessage({
        id: "recurrencyPicker.RecurrencyOption.Weeks",
        description: "Recurrency Option : Week",
        defaultMessage: "Weekly",
      })
    case "Monthly":
      return intl.formatMessage({
        id: "recurrencyPicker.RecurrencyOption.Months",
        description: "Recurrency Option : Month",
        defaultMessage: "Monthly",
      })
    case "Yearly":
      return intl.formatMessage({
        id: "recurrencyPicker.RecurrencyOption.Years",
        description: "Recurrency Option : Year",
        defaultMessage: "Yearly",
      })
  }
  return ""
}
const weekUnit = (week: number) => {
  switch (week) {
    case 1:
      return "first"
    case 2:
      return "second"
    case 3:
      return "third"
    case 4:
      return "fourth"
  }
  return ""
}
const dayUnit = (i: number) => {
  var j = i % 10,
    k = i % 100
  if (j === 1 && k !== 11) {
    return i + "st"
  }
  if (j === 2 && k !== 12) {
    return i + "nd"
  }
  if (j === 3 && k !== 13) {
    return i + "rd"
  }
  return i + "th"
}
const getWeek = (date: DateTime) => {
  let monthStart = date.startOf("month")
  let offset = ((monthStart.day + 1) % 7) - 1 // -1 is for a week starting on Monday
  return Math.ceil((date.day + offset) / 7)
}
export default function RecurrencyPicker(props: RecurrencyPickerProps) {
  const intl = useIntl()
  let recurrencyOptions = props.recurrencyOptions
  if (!recurrencyOptions.includes("None")) {
    recurrencyOptions = ["None", ...recurrencyOptions]
    if (!(props.recurrency.recurrency in recurrencyOptions)) {
      props.setRecurrency({ ...props.recurrency, recurrency: "None" })
    }
  } else {
    recurrencyOptions = ["None", ...recurrencyOptions.filter((entry) => entry !== "None")] as string[]
  }
  const recurring = props.recurrency.recurrency !== "None"
  const day = props.minDate.minus({ days: 1 })
  return (
    <Accordion expanded={recurring}>
      <AccordionSummary aria-label="Expand" aria-controls="additional-actions1-content" id="additional-actions1-header">
        <FormControlLabel
          onClick={(event) => event.stopPropagation()}
          onFocus={(event) => event.stopPropagation()}
          control={
            <Checkbox
              checked={recurring}
              disabled={props.disabled}
              onChange={(event, checked) =>
                props.setRecurrency({
                  ...props.recurrency,
                  recurrency: checked ? recurrencyOptions[1] : recurrencyOptions[0],
                })
              }
            />
          }
          label={props.label}
        />
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={0}>
          <Grid container style={{ margin: "5%" }}>
            <Grid item xs={2}>
              <Typography>
                {intl.formatMessage({
                  id: "recurrencyPicker.Period",
                  description: "Recurring Period",
                  defaultMessage: "Period:",
                })}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Select
                disabled={props.disabled}
                value={props.recurrency.recurrency}
                onChange={(event, child) =>
                  props.setRecurrency({
                    ...props.recurrency,
                    recurrency: event.target.value as string,
                  })
                }
              >
                {recurrencyOptions
                  .filter((entry) => entry !== "None")
                  .map((option) => {
                    return <MenuItem value={option}>{recurrencyOptionsToString(option, intl)}</MenuItem>
                  })}
              </Select>
            </Grid>
            <Grid item xs={2}>
              <Typography>
                {intl.formatMessage({
                  id: "recurrencyPicker.EndDate",
                  description: "Recurring EndDate",
                  defaultMessage: "End Date:",
                })}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <DatePicker
                disablePast
                disabled={props.disabled}
                minDate={props.minDate}
                inputFormat="dd/MM/yyyy"
                renderInput={(renderProps) => <TextField {...renderProps} />}
                value={props.recurrency.endDate ? props.recurrency.endDate : props.minDate}
                onChange={(date) => {
                  if (date) {
                    props.setRecurrency({
                      ...props.recurrency,
                      endDate: date,
                    })
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <TextField
                type="number"
                disabled={props.disabled}
                label={intl.formatMessage({
                  id: "recurrencyPicker.RepeatEvery",
                  description: "Repeat every",
                  defaultMessage: "Repeat every",
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {recurrencyUnitToString(props.recurrency.recurrency, intl)}
                    </InputAdornment>
                  ),
                }}
                value={props.recurrency.interval}
                variant="filled"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  props.setRecurrency({
                    ...props.recurrency,
                    interval: parseInt(event.target.value),
                  })
                }}
              />
            </Grid>
            <Grid item xs={6}>
              {props.recurrency.recurrency === "Daily" && (
                <FormControlLabel
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  control={
                    <Checkbox
                      disabled={props.disabled}
                      checked={props.recurrency.skipWeekends}
                      onChange={(event, checked) =>
                        props.setRecurrency({
                          ...props.recurrency,
                          skipWeekends: checked,
                        })
                      }
                    />
                  }
                  label={intl.formatMessage({
                    id: "recurrencyPicker.Weekends",
                    description: "Weeknds checkbox",
                    defaultMessage: "Weekdays only",
                  })}
                />
              )}
              {props.recurrency.recurrency === "Monthly" && (
                <FormControl component="fieldset" disabled={props.disabled}>
                  <FormLabel component="legend">
                    {intl.formatMessage({
                      id: "recurrencyPicker.Repeat",
                      description: "Recurring Repeat",
                      defaultMessage: "Repeat",
                    })}
                  </FormLabel>
                  <RadioGroup
                    value={props.recurrency.repetition}
                    onChange={(event, value) =>
                      props.setRecurrency({
                        ...props.recurrency,
                        repetition: value,
                      })
                    }
                  >
                    <FormControlLabel
                      value="date"
                      control={<Radio />}
                      label={
                        intl.formatMessage({
                          id: "recurrencyPicker.The",
                          description: "The...",
                          defaultMessage: "The ",
                        }) +
                        dayUnit(day.day) +
                        intl.formatMessage({
                          id: "recurrencyPicker.OfEveryMonth",
                          description: "Prt. of recurring date",
                          defaultMessage: " of every month",
                        })
                      }
                    />
                    <FormControlLabel
                      value="weekday"
                      control={<Radio />}
                      label={
                        intl.formatMessage({
                          id: "recurrencyPicker.Every",
                          description: "Prt. of recurring date",
                          defaultMessage: "Every ",
                        }) +
                        weekUnit(getWeek(day)) +
                        " " +
                        day.weekdayLong +
                        intl.formatMessage({
                          id: "recurrencyPicker.OfEveryMonth",
                          description: "Prt. of recurring date",
                          defaultMessage: " of every month",
                        })
                      }
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
