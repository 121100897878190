import { Box, Typography } from "@material-ui/core"
import grey from "@material-ui/core/colors/grey"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"

type PaperTitleProps = {
  title: string
  subtitle?: string
}

const useStyles = makeStyles({
  title: {
    fontSize: "20px",
    fontWeight: "bolder",
  },
  subtitle: {
    fontSize: "16px",
    color: grey[600],
  },
})

const PaperTitle = ({ title, subtitle }: PaperTitleProps) => {
  const classes = useStyles()
  return (
    <Box pb={2}>
      <Typography className={classes.title}>{title}</Typography>
      {subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
    </Box>
  )
}

export default PaperTitle
