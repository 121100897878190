export const numberToBitPositions = (number: number | undefined) => {
  if (number === undefined) {
    return []
  }
  let positions: number[] = []
  let bits = (number >>> 0).toString(2).split("").map(Number).reverse()
  for (var i = 0; i < bits.length; i++) {
    if (bits[i] === 1) {
      positions.push(i + 1)
    }
  }
  return positions
}

export const bitPositionsToNumber = (numbers: (string | number)[]): number => {
  let sum = 0
  for (const num of numbers) {
    sum += Math.pow(2, Number(num) - 1)
  }
  return sum
}

export const bit_test = (num: number | undefined, bit: number) => {
  const arr1 = numberToBitPositions(bit)
  const arr2 = numberToBitPositions(num)
  return arr1.some((r) => arr2.includes(r))
}
