import { makeStyles } from "@material-ui/core/styles"
import EuroIcon from "@material-ui/icons/Euro"
import LabelImportantIcon from "@material-ui/icons/LabelImportant"
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount"
import systemTags from "api/systemTags"
import { CustomTag, CustomTagProps } from "features/user/CustomTag"
import React from "react"
type SystemTagProps = {} & CustomTagProps

const useStyles = makeStyles((theme) => ({
  tag: {
    textTransform: "capitalize",
    fontWeight: "bold",
  },
}))

export const SystemTag = (props: SystemTagProps) => {
  const classes = useStyles()
  let icon = <LabelImportantIcon />
  switch (props.label) {
    case systemTags.Admin:
      icon = <SupervisorAccountIcon />
      break
    case systemTags.Paid:
      icon = <EuroIcon />
      break
    default:
      icon = <LabelImportantIcon />
      break
  }
  return <CustomTag className={classes.tag} {...props} icon={icon} />
}
