import { Box, Divider, LinearProgress, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import { SendMailRead } from "api/generated/schedule"
import { downloadUploadedFile, TagRead, User, useTags } from "api/scheduleAPI"
import { CollapsableTagDisplay } from "components/Form/CollapsableTagDisplay"
import { UserTag } from "features/user"
import React from "react"
import { useIntl } from "react-intl"

const useStyles = makeStyles((theme) => ({
  bottomBox: {
    bottom: 0,
    position: "absolute",
    width: "100%",
  },
}))

type MailsListItemProps = {
  mail: SendMailRead
  users: User[]
}

function user_by_id(users: User[], id: number) {
  return users.find((user) => user.id === id)
}

export const MailsListItem = ({ mail, users }: MailsListItemProps) => {
  const classes = useStyles()
  const intl = useIntl()
  const { data: tags, isLoading: isLoadingTags } = useTags()
  const sender = user_by_id(users, mail.sender)

  if (isLoadingTags || tags === undefined) {
    return (
      <Box p={10}>
        <LinearProgress />
      </Box>
    )
  }

  const send_to_tags = Array.from(mail.to_tags)
    .map((id) => {
      return tags.find((tag) => tag.id === id)
    })
    .filter((tag) => tag !== undefined) as TagRead[]

  const send_to_users = Array.from(mail.to_users)
    .map((id) => {
      return users.find((user) => user.id === id)
    })
    .filter((user) => user !== undefined) as User[]

  return (
    <Box minHeight={500} position="relative">
      <Box p={2}>
        <Typography gutterBottom component="p" variant="body1" color="textPrimary">
          {mail.subject}
        </Typography>
        <Typography gutterBottom component="p" variant="body1" color="textSecondary">
          {intl.formatMessage({
            id: "mailList.Item.Sender",
            description: "Sender label",
            defaultMessage: "Sender: ",
          })}
          <UserTag label={sender?.completeName} />
        </Typography>
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <Typography gutterBottom component="p" variant="body1" color="textSecondary">
            {intl.formatMessage({
              id: "mailList.Item.Receiver",
              description: "Receiver label",
              defaultMessage: "Receiver: ",
            })}
          </Typography>
          <CollapsableTagDisplay tags={send_to_tags} users={send_to_users} displayLimit={10} />
          {/* {send_to_tags.map((tag) =>
            tag?.type === 1 ? <CustomTag label={tag?.name} /> : <SystemTag label={tag?.name} />,
          )}
          {send_to_users.map((user) => (
            <UserTag label={user?.completeName} />
          ))} */}
        </Box>
      </Box>
      <Divider />
      <Box p={2} pt={2} pb={12} position="relative">
        <div dangerouslySetInnerHTML={{ __html: mail.message }} />
      </Box>
      {mail.attachments.length > 0 && (
        <Box className={classes.bottomBox}>
          <Divider />
          <List>
            {mail.attachments.map((attachment) => (
              <ListItem button onClick={() => downloadUploadedFile(attachment.id, attachment.upload_name)}>
                <ListItemIcon>
                  <FileCopyIcon />
                </ListItemIcon>
                <ListItemText primary={attachment.upload_name} />
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  )
}
