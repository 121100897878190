import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import React from "react"
import { useIntl } from "react-intl"

export type ModifyRecurringDialogProps = {
  open: boolean
  modifySeriesCallback: Function
  modifySingleCallback: Function
  cancelCallback: Function
  title: string
  message: string
}

export default function ModifyRecurringDialog(props: ModifyRecurringDialogProps) {
  const intl = useIntl()
  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.modifySeriesCallback()} color="primary">
            {intl.formatMessage({
              id: "modifyRecurringDialog.WholeSeries",
              description: "Recurring event whole series",
              defaultMessage: "Whole series",
            })}
          </Button>
          <Button onClick={() => props.modifySingleCallback()} color="secondary">
            {intl.formatMessage({
              id: "modifyRecurringDialog.SingleEvent",
              description: "Recurring event single event",
              defaultMessage: "Single event",
            })}
          </Button>
          <Button onClick={() => props.cancelCallback()} color="default">
            {intl.formatMessage({
              id: "cancelButton",
              defaultMessage: "Cancel",
              description: "Cancel button",
            })}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
