import { CircularProgress } from "@material-ui/core"
import Config from "app/config"
import {
  bookings,
  login,
  mails,
  mailsCreate,
  mailsCreateWithTarget,
  newpassword,
  passwordreset,
  passwordresetWithUsername,
  profile,
  settings,
  users,
  usersCreate,
  usersEdit,
} from "app/routes"
import { PrivateRoute } from "components/Route/PrivateRoute"
import { MainLayout } from "containers/MainLayout"
import { BookingProvider } from "context/bookingContext"
import { LoginPage } from "features/auth/LoginPage"
import { NewPasswordPage } from "features/auth/NewPasswordPage"
import { ResetPassword } from "features/auth/ResetPassword"
import { BookingsPage } from "features/booking/views/BookingsPage"
import { MailsListPage } from "features/mail/MailsListPage"
import { SendMailPage } from "features/mail/SendMailPage"
import { SettingsListPage } from "features/settingsList/SettingsListPage"
import { ProfileEditPage } from "features/userEdit/ProfileEditPage"
import { UserCreatePage } from "features/userEdit/UserCreatePage"
import { UserEditPage } from "features/userEdit/UserEditPage"
import React, { Suspense, useEffect } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { withRoot } from "./withRoot"

const UsersListPage = React.lazy(() => import("features/usersList/UsersListPage"))

const App: React.FC = () => {
  useEffect(() => {
    document.title = Config.title
  }, [])

  return (
    <Suspense fallback={<CircularProgress />}>
      <Switch>
        <PrivateRoute path="/" exact>
          <Redirect to={bookings} />
        </PrivateRoute>
        <Route path={login}>
          <LoginPage />
        </Route>
        <Route path={passwordresetWithUsername} exact>
          <Route render={(props) => <ResetPassword renderProps={props} />} />
        </Route>
        <Route path={passwordreset} exact>
          <ResetPassword />
        </Route>
        <Route path={newpassword}>
          <NewPasswordPage />
        </Route>
        <PrivateRoute path={bookings}>
          <MainLayout>
            <BookingProvider>
              <BookingsPage />
            </BookingProvider>
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute path={users} exact>
          <MainLayout>
            <UsersListPage />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute path={usersCreate} exact>
          <MainLayout>
            <UserCreatePage />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute path={usersEdit}>
          <MainLayout>
            <UserEditPage />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute path={settings} exact>
          <MainLayout>
            <SettingsListPage />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute path={mailsCreate} exact>
          <MainLayout>
            <SendMailPage />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute path={mailsCreateWithTarget} exact>
          <MainLayout>
            <Route render={(props) => <SendMailPage renderProps={props} />} />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute path={mails} exact>
          <MainLayout>
            <MailsListPage />
          </MainLayout>
        </PrivateRoute>
        <PrivateRoute path={profile} exact>
          <MainLayout>
            <ProfileEditPage />
          </MainLayout>
        </PrivateRoute>
      </Switch>
    </Suspense>
  )
}

export default withRoot(App)
