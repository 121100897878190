import { Box } from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import ClearIcon from "@material-ui/icons/Clear"
import { Booking, Ressource, User } from "api/scheduleAPI"
import { LoaderButton } from "components/Form/LoaderButton"
import { SortableTable } from "features/userTags/SortableTable"
import { DateTime } from "luxon"
import React from "react"
import { useIntl } from "react-intl"

type BookingRequestsListProps = {
  processing: number | undefined
  users: User[]
  resources: Ressource[]
  requests: Booking[]
  approve: Function
  decline: Function
}

export const BookingRequestsList = (props: BookingRequestsListProps) => {
  const intl = useIntl()
  const renderBookingRow = (booking: Booking) => {
    return {
      id: booking.id,
      participants: booking.participants_ids
        ?.map((id) => props.users.find((u) => u.id === id)?.completeName)
        .join(" | "),
      resource: props.resources.find((r) => r.id === booking.ressource_id)?.name,
      time:
        booking.startDateTime.toLocaleString(DateTime.DATETIME_SHORT) +
        " - " +
        booking.endDateTime.toLocaleString(DateTime.DATETIME_SHORT),
      actions: (
        <React.Fragment>
          <LoaderButton
            variant="contained"
            color="inherit"
            startIcon={<CheckIcon />}
            onClick={() => props.approve(booking.id)}
            style={{
              backgroundColor: props.processing === booking.id ? "#e2e2e2" : "#50AC51",
              color: "white",
              marginRight: "6px",
            }}
            loading={props.processing === booking.id}
            success={false}
          >
            {intl.formatMessage({
              id: "common.Approve",
              description: "Approve label",
              defaultMessage: "Approve",
            })}
          </LoaderButton>
          <LoaderButton
            variant="contained"
            color="inherit"
            startIcon={<ClearIcon />}
            onClick={() => props.decline(booking.id)}
            style={{
              backgroundColor: props.processing === booking.id ? "#e2e2e2" : "#FD1129",
              color: "white",
              marginRight: "6px",
            }}
            loading={props.processing === booking.id}
            success={false}
          >
            {intl.formatMessage({
              id: "common.Decline",
              description: "Decline label",
              defaultMessage: "Decline",
            })}
          </LoaderButton>
        </React.Fragment>
      ),
    }
  }
  return (
    <Box p={2} mt={2}>
      <SortableTable
        pagination={true}
        title={intl.formatMessage({
          id: "requests.Title",
          description: "Requests TItle label",
          defaultMessage: "Requests",
        })}
        headCells={[
          { id: "id", label: "ID", numeric: false },
          { id: "participants", label: "Participants", numeric: false },
          { id: "resource", label: "Court", numeric: false },
          { id: "time", label: "Duration", numeric: false },
          { id: "actions", label: "Action", numeric: false, notSortable: true },
        ]}
        rows={props.requests.map((request) => renderBookingRow(request))}
      />
    </Box>
  )
}
