import React, { useCallback, useContext, useMemo, useState } from "react"

export interface IMobileMenuContext {
  getMobileMenuOpen: Function
  setMobileMenuOpen: Function
}

const MobileMenuContext = React.createContext<IMobileMenuContext | undefined>(undefined)
MobileMenuContext.displayName = "AuthMobileMenuContextContext"

export function MobileMenuProvider(props: any) {
  const [mobileOpen, setMobileOpen] = useState(false)
  const getMobileMenuOpen = useCallback(() => {
    return mobileOpen
  }, [mobileOpen])
  const setMobileMenuOpen = useCallback(
    (open: boolean) => {
      if (mobileOpen !== open) {
        setMobileOpen(open)
      }
    },
    [mobileOpen],
  )
  const value = useMemo(() => ({ getMobileMenuOpen, setMobileMenuOpen }), [getMobileMenuOpen, setMobileMenuOpen])

  return <MobileMenuContext.Provider value={value} {...props} />
}

export function useMobileMenu() {
  const context = useContext(MobileMenuContext)
  if (!context) {
    throw new Error("To use `useMobileMenu`, component must be within a MobileMenu provider")
  }
  return context
}
