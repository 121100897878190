import { Box, Typography } from "@material-ui/core"
import LinearProgress from "@material-ui/core/LinearProgress"
import { UserRoles, usersApi, UserUpdate } from "api/scheduleAPI"
import AppMenuBar from "components/AppDrawer/AppMenuBar"
import { useAuth } from "features/auth/AuthContext"
import { UserForm } from "features/userEdit/UserForm"
import { useSnackbar } from "notistack"
import React from "react"
import { useMutation, useQueryClient } from "react-query"

export const ProfileEditPage = () => {
  const { user: user } = useAuth()

  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const mutation = useMutation((values: UserUpdate) => usersApi.updateUser(user.id, values), {
    onSuccess: () => {
      enqueueSnackbar("Successfully saved.", { variant: "success" })
    },
    onError: () => {
      enqueueSnackbar("Error saving user.", { variant: "error" })
    },
    onSettled: () => {
      queryClient.invalidateQueries("me")
      queryClient.invalidateQueries("users")
      queryClient.invalidateQueries(["user", user.id])
    },
  })

  if (!user) {
    return (
      <Box p={10}>
        <LinearProgress />
      </Box>
    )
  }

  return (
    <>
      <AppMenuBar>
        <Typography variant="h5">Profile</Typography>
      </AppMenuBar>
      <UserForm
        user={user}
        title="My Profile"
        subtitle="Manage your user-account settings"
        onFormSubmit={mutation.mutate}
        editorIsAdmin={user.role === UserRoles.Admin}
      />
    </>
  )
}
