import {
  Avatar,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { SendMailRead } from "api/generated/schedule"
import { User } from "api/scheduleAPI"
import PaperTitle from "components/paperTitle"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { MailsListItem } from "./MailListItem"

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: theme.spacing(2, 2, 2),
  },
  mailList: {
    width: "100%",
    position: "relative",
    overflow: "auto",
    maxHeight: 800,
  },
}))

type MailListProps = {
  mails: SendMailRead[]
  users: User[]
}

function truncate(str: string, n: number) {
  return str.length > n ? str.substr(0, n - 1) + " ..." : str
}

function user_by_id(users: User[], id: number) {
  const user = users.find((user) => user.id === id)
  return user
}

export const MailsList = ({ mails, users }: MailListProps) => {
  const [selectedMail, setSelectedMail] = useState<SendMailRead | undefined>()

  useEffect(() => {
    if (mails) {
      setSelectedMail(mails[0])
    }
  }, [])

  const handleListItemClick = (id: number) => {
    const mail = mails.find((mail) => mail.id === id)
    setSelectedMail(mail)
  }
  const intl = useIntl()
  const classes = useStyles()
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <PaperTitle
            title={intl.formatMessage({
              id: "mailList.Title",
              description: "List Title label",
              defaultMessage: "Sent: ",
            })}
          />
          <Divider />
          <List className={classes.mailList}>
            {mails.map((mail) => (
              <ListItem
                alignItems="flex-start"
                button
                onClick={() => handleListItemClick(mail.id)}
                selected={mail.id === selectedMail?.id}
              >
                <ListItemAvatar>
                  <Avatar alt="Avatar">AB</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={mail.subject}
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="textSecondary">
                        <div dangerouslySetInnerHTML={{ __html: truncate(mail.message, 70) }} />
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>
      <Grid item xs={8}>
        <Paper className={classes.paper}>
          {selectedMail ? (
            <MailsListItem users={users} mail={selectedMail} />
          ) : (
            <Typography component="span" variant="body1" color="textPrimary">
              {intl.formatMessage({
                id: "mailList.Item.Display.Empty",
                description: "Placeholder text if no mail selected",
                defaultMessage: "Select a mail from the list to view the details",
              })}
            </Typography>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}
