import { Box, Typography } from "@material-ui/core"
import LinearProgress from "@material-ui/core/LinearProgress"
import { Gender } from "api/generated/schedule"
import { useMe, UserCreate, UserRoles, usersApi } from "api/scheduleAPI"
import { users as usersPath } from "app/routes"
import AppMenuBar from "components/AppDrawer/AppMenuBar"
import { UserForm } from "features/userEdit/UserForm"
import { DateTime } from "luxon"
import { useSnackbar } from "notistack"
import React from "react"
import { useMutation, useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"

const initialValues: UserCreate = {
  firstname: "",
  lastname: "",
  username: "",
  email: "",
  address: "",
  phone: "",
  role: UserRoles.User,
  gender: Gender.NotSpecified,
  password: "",
  birthday: DateTime.local().toSQLDate(),
  tags: [],
}

export const UserCreatePage = () => {
  const { data: currentUser } = useMe()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const history = useHistory()
  const mutation = useMutation((values: UserCreate) => usersApi.createUser(values), {
    onSuccess: () => {
      enqueueSnackbar("User successfully created.", { variant: "success" })
      history.goBack()
    },
    onError: () => {
      enqueueSnackbar("Error creating new user.", { variant: "error" })
    },
    onSettled: () => {
      queryClient.invalidateQueries("me")
      queryClient.invalidateQueries("users")
      queryClient.invalidateQueries(["user", currentUser?.id])
    },
  })

  if (!currentUser) {
    return (
      <Box p={10}>
        <LinearProgress />
      </Box>
    )
  }

  return (
    <>
      <AppMenuBar>
        <Typography variant="h5">Create User</Typography>
      </AppMenuBar>
      <UserForm
        user={initialValues}
        title="Add a user"
        subtitle="User profile"
        backButtonUrl={usersPath}
        editorIsAdmin={true}
        onFormSubmit={mutation.mutate}
        showPasswordField
      />
    </>
  )
}
