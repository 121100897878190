//import './wdyr'; // <--- first import
import { Settings } from "luxon"
import React from "react"
import ReactDOM from "react-dom"
import "typeface-roboto"
import "./index.css"

const render = () => {
  const App = require("./app/App").default
  Settings.defaultZoneName = "utc"
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root"),
  )
}

render()

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./app/App", render)
}
