import { TablePagination } from "@material-ui/core"
import Checkbox from "@material-ui/core/Checkbox"
import IconButton from "@material-ui/core/IconButton"
import { createStyles, lighten, makeStyles, Theme } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import Toolbar from "@material-ui/core/Toolbar"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import clsx from "clsx"
import React from "react"

function descendingComparator<T>(a: T, b: T, orderBy: keyof T, orderByKey?: string) {
  if (orderByKey === undefined) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
  } else {
    const B: any = b[orderBy]
    const A: any = a[orderBy]
    const valB: any = orderByKey.split(".").reduce((p: any, c: any) => p?.[c], B)
    const valA: any = orderByKey.split(".").reduce((p: any, c: any) => p?.[c], A)
    if (valB < valA) {
      return -1
    }
    if (valB > valA) {
      return 1
    }
  }
  return 0
}

type Order = "asc" | "desc"

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
  orderByKey?: string,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy, orderByKey)
    : (a, b) => -descendingComparator(a, b, orderBy, orderByKey)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el: any, index) => {
    return [el, index] as [T, number]
  })
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export interface HeadCell {
  id: string
  label: string
  numeric: boolean
  sortingKey?: string
  notSortable?: boolean
}

interface SortableTableHeadProps {
  classes: ReturnType<typeof useStyles>
  selectable: boolean
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string
  rowCount: number
  headCells: HeadCell[]
}

function SortableTableHead(props: SortableTableHeadProps) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {props.selectable && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
        )}
        {props.headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.notSortable ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  }),
)

interface SortableTableToolbarProps {
  numSelected: number
  title: string
  deleteHandler: Function
  editHandler?: Function
}

const SortableTableToolbar = (props: SortableTableToolbarProps) => {
  const classes = useToolbarStyles()
  const { numSelected } = props

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          {props.title}
        </Typography>
      )}
      {numSelected > 0 && props.editHandler && (
        <Tooltip title="Edit">
          <IconButton
            aria-label="edit"
            onClick={() => {
              if (props.editHandler) {
                props.editHandler()
              }
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton aria-label="delete" onClick={() => props.deleteHandler()}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    container: {
      maxHeight: "60vh",
    },
  }),
)

export type SortableTableProps = {
  pagination: boolean
  title: string
  headCells: HeadCell[]
  rows: any[]
  deleteHandler?: Function
  editHandler?: Function
  clickHandler?: Function
  selected?: string[]
  setSelected?: Function
}

export const SortableTable = (props: SortableTableProps) => {
  const classes = useStyles()
  const [order, setOrder] = React.useState<Order>("asc")
  const [orderBy, setOrderBy] = React.useState("id")
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)

  if (!props.pagination) {
    if (rowsPerPage !== props.rows.length) {
      setRowsPerPage(props.rows.length)
    }
  }
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.selected && props.setSelected) {
      if (event.target.checked) {
        const newSelectedIds = props.rows.map((n) => n.id.toString())
        props.setSelected(newSelectedIds)
        return
      }
      props.setSelected([])
    }
  }

  const handleCheckboxChange = (id: string) => {
    if (props.selected && props.setSelected) {
      const selectedIndex = props.selected.indexOf(id)
      let newSelected: string[] = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(props.selected, id)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(props.selected.slice(1))
      } else if (selectedIndex === props.selected.length - 1) {
        newSelected = newSelected.concat(props.selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          props.selected.slice(0, selectedIndex),
          props.selected.slice(selectedIndex + 1),
        )
      }

      props.setSelected(newSelected)
    }
  }

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    if (props.clickHandler !== undefined) {
      props.clickHandler(event, id)
      return
    }
    handleCheckboxChange(id)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (id: string) => (props.selected ? props.selected.indexOf(id) !== -1 : false)

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, props.rows.length - page * rowsPerPage)

  return (
    <div className={classes.root}>
      <SortableTableToolbar
        title={props.title}
        numSelected={props.selected ? props.selected.length : 0}
        deleteHandler={() => {
          if (props.deleteHandler) {
            props.deleteHandler(props.selected)
          }
        }}
        editHandler={props.editHandler}
      />
      <TableContainer className={classes.container}>
        <Table
          stickyHeader
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <SortableTableHead
            classes={classes}
            selectable={props.selected !== undefined}
            numSelected={props.selected ? props.selected.length : 0}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={props.rows.length}
            headCells={props.headCells}
          />
          <TableBody>
            {stableSort(
              props.rows,
              getComparator(order, orderBy, props.headCells.find((c) => c.id === orderBy)?.sortingKey),
            )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id.toString())
                const labelId = `enhanced-table-checkbox-${index}`

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id.toString())}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    {props.setSelected && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          onChange={(event) => handleCheckboxChange(row.id.toString())}
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                    )}
                    <TableCell component="th" id={labelId} scope="row">
                      {row.id}
                    </TableCell>
                    {props.headCells
                      .filter((head) => head.id !== "id")
                      .map((head) => {
                        return <TableCell align="left">{row[head.id]}</TableCell>
                      })}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {props.pagination && (
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 25, 50, 100]}
          count={props.rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            fullWidth: false,
          }}
        />
      )}
    </div>
  )
}
