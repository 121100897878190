/* tslint:disable */
/* eslint-disable */
/**
 * Schedule API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AppSettings
 */
export interface AppSettings {
    /**
     * 
     * @type {string}
     * @memberof AppSettings
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AppSettings
     */
    timezone: string;
    /**
     * 
     * @type {number}
     * @memberof AppSettings
     */
    time_granularity_minutes: number;
    /**
     * 
     * @type {HoursOfAvailability}
     * @memberof AppSettings
     */
    hours_of_availability: HoursOfAvailability;
    /**
     * 
     * @type {BookingSettings}
     * @memberof AppSettings
     */
    booking: BookingSettings;
}
/**
 * 
 * @export
 * @interface BodyChangeUserPasswordUsersIdPasswordPatch
 */
export interface BodyChangeUserPasswordUsersIdPasswordPatch {
    /**
     * 
     * @type {string}
     * @memberof BodyChangeUserPasswordUsersIdPasswordPatch
     */
    old_password?: string;
    /**
     * 
     * @type {string}
     * @memberof BodyChangeUserPasswordUsersIdPasswordPatch
     */
    new_password: string;
}
/**
 * 
 * @export
 * @interface BodyResetPasswordAuthResetPasswordPost
 */
export interface BodyResetPasswordAuthResetPasswordPost {
    /**
     * 
     * @type {string}
     * @memberof BodyResetPasswordAuthResetPasswordPost
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof BodyResetPasswordAuthResetPasswordPost
     */
    new_password: string;
}
/**
 * 
 * @export
 * @interface BookingCollisions
 */
export interface BookingCollisions {
    /**
     * 
     * @type {string}
     * @memberof BookingCollisions
     */
    reason: string;
    /**
     * 
     * @type {string}
     * @memberof BookingCollisions
     */
    error_code: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof BookingCollisions
     */
    collision_with: Array<number>;
}
/**
 * 
 * @export
 * @interface BookingCreate
 */
export interface BookingCreate {
    /**
     * 
     * @type {number}
     * @memberof BookingCreate
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BookingCreate
     */
    title?: string;
    /**
     * 
     * @type {BookingType}
     * @memberof BookingCreate
     */
    booking_type: BookingType;
    /**
     * 
     * @type {number}
     * @memberof BookingCreate
     */
    ressource_id: number;
    /**
     * 
     * @type {string}
     * @memberof BookingCreate
     */
    start: string;
    /**
     * 
     * @type {string}
     * @memberof BookingCreate
     */
    end: string;
    /**
     * 
     * @type {string}
     * @memberof BookingCreate
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingCreate
     */
    rrule?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof BookingCreate
     */
    participants_ids?: Array<number>;
    /**
     * A booking can have a tag to e.g. change his color.
     * @type {Array<TagNested>}
     * @memberof BookingCreate
     */
    tags?: Array<TagNested>;
}
/**
 * 
 * @export
 * @interface BookingQuotaExceeded
 */
export interface BookingQuotaExceeded {
    /**
     * 
     * @type {string}
     * @memberof BookingQuotaExceeded
     */
    reason: string;
    /**
     * 
     * @type {string}
     * @memberof BookingQuotaExceeded
     */
    error_code: string;
    /**
     * 
     * @type {number}
     * @memberof BookingQuotaExceeded
     */
    user_id: number;
    /**
     * 
     * @type {number}
     * @memberof BookingQuotaExceeded
     */
    num_upcoming_bookings: number;
    /**
     * 
     * @type {number}
     * @memberof BookingQuotaExceeded
     */
    max_num_of_bookings: number;
}
/**
 * 
 * @export
 * @interface BookingRead
 */
export interface BookingRead {
    /**
     * 
     * @type {number}
     * @memberof BookingRead
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BookingRead
     */
    title?: string;
    /**
     * 
     * @type {BookingType}
     * @memberof BookingRead
     */
    booking_type: BookingType;
    /**
     * 
     * @type {number}
     * @memberof BookingRead
     */
    ressource_id: number;
    /**
     * 
     * @type {string}
     * @memberof BookingRead
     */
    start: string;
    /**
     * 
     * @type {string}
     * @memberof BookingRead
     */
    end: string;
    /**
     * 
     * @type {string}
     * @memberof BookingRead
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingRead
     */
    rrule?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof BookingRead
     */
    participants_ids?: Array<number>;
    /**
     * A booking can have a tag to e.g. change his color.
     * @type {Array<TagNested>}
     * @memberof BookingRead
     */
    tags?: Array<TagNested>;
    /**
     * 
     * @type {number}
     * @memberof BookingRead
     */
    owner_id: number;
    /**
     * 
     * @type {string}
     * @memberof BookingRead
     */
    end_of_last_occurrence: string;
    /**
     * 
     * @type {string}
     * @memberof BookingRead
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingRead
     */
    updated_at?: string;
}
/**
 * 
 * @export
 * @interface BookingSettings
 */
export interface BookingSettings {
    /**
     * 
     * @type {number}
     * @memberof BookingSettings
     */
    max_duration_minutes: number;
    /**
     * 
     * @type {number}
     * @memberof BookingSettings
     */
    min_duration_minutes: number;
    /**
     * 
     * @type {number}
     * @memberof BookingSettings
     */
    concurrent: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum BookingType {
    User = 'user',
    Owner = 'owner',
    Blocked = 'blocked',
    Request = 'request'
}

/**
 * 
 * @export
 * @interface BookingUpdate
 */
export interface BookingUpdate {
    /**
     * 
     * @type {number}
     * @memberof BookingUpdate
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BookingUpdate
     */
    title?: string;
    /**
     * 
     * @type {BookingType}
     * @memberof BookingUpdate
     */
    booking_type?: BookingType;
    /**
     * 
     * @type {number}
     * @memberof BookingUpdate
     */
    ressource_id?: number;
    /**
     * 
     * @type {string}
     * @memberof BookingUpdate
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingUpdate
     */
    end?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingUpdate
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof BookingUpdate
     */
    rrule?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof BookingUpdate
     */
    participants_ids?: Array<number>;
    /**
     * A booking can have a tag to e.g. change his color.
     * @type {Array<TagNested>}
     * @memberof BookingUpdate
     */
    tags?: Array<TagNested>;
}
/**
 * 
 * @export
 * @interface Duration
 */
export interface Duration {
    /**
     * minutes
     * @type {number}
     * @memberof Duration
     */
    start: number;
    /**
     * minutes
     * @type {number}
     * @memberof Duration
     */
    end: number;
}
/**
 * 
 * @export
 * @interface DurationConstraints
 */
export interface DurationConstraints {
    /**
     * 
     * @type {string}
     * @memberof DurationConstraints
     */
    reason: string;
    /**
     * 
     * @type {string}
     * @memberof DurationConstraints
     */
    error_code: string;
    /**
     * 
     * @type {number}
     * @memberof DurationConstraints
     */
    duration_minutes: number;
    /**
     * 
     * @type {number}
     * @memberof DurationConstraints
     */
    min: number;
    /**
     * 
     * @type {number}
     * @memberof DurationConstraints
     */
    max: number;
}
/**
 * 
 * @export
 * @interface ExceptionModel
 */
export interface ExceptionModel {
    /**
     * 
     * @type {number}
     * @memberof ExceptionModel
     */
    status_code: number;
    /**
     * 
     * @type {string}
     * @memberof ExceptionModel
     */
    detail?: string;
    /**
     * 
     * @type {string}
     * @memberof ExceptionModel
     */
    error_code?: string;
}
/**
 * 
 * @export
 * @interface Explanation
 */
export interface Explanation {
    /**
     * 
     * @type {string}
     * @memberof Explanation
     */
    reason: string;
    /**
     * 
     * @type {string}
     * @memberof Explanation
     */
    error_code: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum Gender {
    NotSpecified = 'not_specified',
    Male = 'male',
    Female = 'female',
    Divers = 'divers'
}

/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    detail?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface HoursOfAvailability
 */
export interface HoursOfAvailability {
    /**
     * 
     * @type {Duration}
     * @memberof HoursOfAvailability
     */
    displayHours: Duration;
    /**
     * 
     * @type {Array<Rule>}
     * @memberof HoursOfAvailability
     */
    rules?: Array<Rule>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum Insert {
    Before = 'before',
    After = 'after'
}

/**
 * 
 * @export
 * @interface InvalidBookingType
 */
export interface InvalidBookingType {
    /**
     * 
     * @type {string}
     * @memberof InvalidBookingType
     */
    reason: string;
    /**
     * 
     * @type {string}
     * @memberof InvalidBookingType
     */
    error_code: string;
    /**
     * 
     * @type {number}
     * @memberof InvalidBookingType
     */
    user_id: number;
    /**
     * 
     * @type {string}
     * @memberof InvalidBookingType
     */
    booking_type: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum LanguageCode {
    De = 'de',
    Fr = 'fr',
    En = 'en'
}

/**
 * 
 * @export
 * @interface MissingTagExplanation
 */
export interface MissingTagExplanation {
    /**
     * 
     * @type {string}
     * @memberof MissingTagExplanation
     */
    reason: string;
    /**
     * 
     * @type {string}
     * @memberof MissingTagExplanation
     */
    error_code: string;
    /**
     * 
     * @type {number}
     * @memberof MissingTagExplanation
     */
    user_id: number;
    /**
     * 
     * @type {number}
     * @memberof MissingTagExplanation
     */
    resource_id: number;
}
/**
 * 
 * @export
 * @interface NotInHoursOfAvailability
 */
export interface NotInHoursOfAvailability {
    /**
     * 
     * @type {string}
     * @memberof NotInHoursOfAvailability
     */
    reason: string;
    /**
     * 
     * @type {string}
     * @memberof NotInHoursOfAvailability
     */
    error_code: string;
}
/**
 * 
 * @export
 * @interface ParticipantsWithoutPayment
 */
export interface ParticipantsWithoutPayment {
    /**
     * 
     * @type {string}
     * @memberof ParticipantsWithoutPayment
     */
    reason: string;
    /**
     * 
     * @type {string}
     * @memberof ParticipantsWithoutPayment
     */
    error_code: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ParticipantsWithoutPayment
     */
    user_ids: Array<number>;
}
/**
 * 
 * @export
 * @interface RessourceCreate
 */
export interface RessourceCreate {
    /**
     * 
     * @type {string}
     * @memberof RessourceCreate
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RessourceCreate
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof RessourceCreate
     */
    published: boolean;
    /**
     * Tags needed to book this resource.
     * @type {Array<TagNested>}
     * @memberof RessourceCreate
     */
    tags?: Array<TagNested>;
}
/**
 * 
 * @export
 * @interface RessourceRead
 */
export interface RessourceRead {
    /**
     * 
     * @type {string}
     * @memberof RessourceRead
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RessourceRead
     */
    description: string;
    /**
     * 
     * @type {boolean}
     * @memberof RessourceRead
     */
    published: boolean;
    /**
     * Tags needed to book this resource.
     * @type {Array<TagNested>}
     * @memberof RessourceRead
     */
    tags?: Array<TagNested>;
    /**
     * 
     * @type {number}
     * @memberof RessourceRead
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof RessourceRead
     */
    rank: string;
    /**
     * ISO day of the week with 1 being Monday and 7 being Sunday.
     * @type {{ [key: string]: Array<Duration>; }}
     * @memberof RessourceRead
     */
    availability: { [key: string]: Array<Duration>; };
}
/**
 * 
 * @export
 * @interface RessourceUpdate
 */
export interface RessourceUpdate {
    /**
     * 
     * @type {string}
     * @memberof RessourceUpdate
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RessourceUpdate
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RessourceUpdate
     */
    published?: boolean;
    /**
     * 
     * @type {Array<TagNested>}
     * @memberof RessourceUpdate
     */
    tags?: Array<TagNested>;
    /**
     * 
     * @type {string}
     * @memberof RessourceUpdate
     */
    rank?: string;
}
/**
 * 
 * @export
 * @interface Rule
 */
export interface Rule {
    /**
     * 
     * @type {Array<number>}
     * @memberof Rule
     */
    ressourceIds?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof Rule
     */
    dayBitmask: number;
    /**
     * minutes
     * @type {number}
     * @memberof Rule
     */
    start: number;
    /**
     * minutes
     * @type {number}
     * @memberof Rule
     */
    end: number;
}
/**
 * 
 * @export
 * @interface SendMailCreate
 */
export interface SendMailCreate {
    /**
     * 
     * @type {Array<number>}
     * @memberof SendMailCreate
     */
    to_users: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof SendMailCreate
     */
    to_tags: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof SendMailCreate
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof SendMailCreate
     */
    subject: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendMailCreate
     */
    attachments: Array<string>;
}
/**
 * 
 * @export
 * @interface SendMailRead
 */
export interface SendMailRead {
    /**
     * 
     * @type {number}
     * @memberof SendMailRead
     */
    id: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SendMailRead
     */
    to_users: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof SendMailRead
     */
    to_tags: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof SendMailRead
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof SendMailRead
     */
    subject: string;
    /**
     * 
     * @type {number}
     * @memberof SendMailRead
     */
    sender: number;
    /**
     * 
     * @type {boolean}
     * @memberof SendMailRead
     */
    failed: boolean;
    /**
     * 
     * @type {Array<UploadRead>}
     * @memberof SendMailRead
     */
    attachments: Array<UploadRead>;
    /**
     * 
     * @type {string}
     * @memberof SendMailRead
     */
    created_at: string;
}
/**
 * 
 * @export
 * @interface TagCreate
 */
export interface TagCreate {
    /**
     * 
     * @type {string}
     * @memberof TagCreate
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TagCreate
     */
    color?: string;
}
/**
 * 
 * @export
 * @interface TagNested
 */
export interface TagNested {
    /**
     * 
     * @type {number}
     * @memberof TagNested
     */
    id: number;
}
/**
 * 
 * @export
 * @interface TagRead
 */
export interface TagRead {
    /**
     * 
     * @type {number}
     * @memberof TagRead
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TagRead
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof TagRead
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TagRead
     */
    type: number;
}
/**
 * 
 * @export
 * @interface TagUpdate
 */
export interface TagUpdate {
    /**
     * 
     * @type {string}
     * @memberof TagUpdate
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TagUpdate
     */
    color?: string;
}
/**
 * 
 * @export
 * @interface UploadCreate
 */
export interface UploadCreate {
    /**
     * 
     * @type {string}
     * @memberof UploadCreate
     */
    id: string;
}
/**
 * 
 * @export
 * @interface UploadRead
 */
export interface UploadRead {
    /**
     * 
     * @type {string}
     * @memberof UploadRead
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UploadRead
     */
    file_name: string;
    /**
     * 
     * @type {string}
     * @memberof UploadRead
     */
    upload_name: string;
}
/**
 * 
 * @export
 * @interface UserCreate
 */
export interface UserCreate {
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    firstname: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    email: string;
    /**
     * 
     * @type {UserRoles}
     * @memberof UserCreate
     */
    role: UserRoles;
    /**
     * 
     * @type {Array<TagNested>}
     * @memberof UserCreate
     */
    tags?: Array<TagNested>;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    birthday?: string;
    /**
     * 
     * @type {Gender}
     * @memberof UserCreate
     */
    gender: Gender;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    phone?: string;
    /**
     * 
     * @type {LanguageCode}
     * @memberof UserCreate
     */
    language?: LanguageCode;
    /**
     * 
     * @type {number}
     * @memberof UserCreate
     */
    notifications?: number;
    /**
     * 
     * @type {string}
     * @memberof UserCreate
     */
    password: string;
}
/**
 * 
 * @export
 * @interface UserLoginResponse
 */
export interface UserLoginResponse {
    /**
     * 
     * @type {string}
     * @memberof UserLoginResponse
     */
    access_token?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginResponse
     */
    token_type?: string;
}
/**
 * 
 * @export
 * @interface UserRegister
 */
export interface UserRegister {
    /**
     * 
     * @type {string}
     * @memberof UserRegister
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegister
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegister
     */
    firstname: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegister
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegister
     */
    email: string;
}
/**
 * 
 * @export
 * @interface UserRegisterResponse
 */
export interface UserRegisterResponse {
    /**
     * 
     * @type {string}
     * @memberof UserRegisterResponse
     */
    email: string;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    firstname: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    email: string;
    /**
     * 
     * @type {UserRoles}
     * @memberof UserResponse
     */
    role: UserRoles;
    /**
     * 
     * @type {Array<TagNested>}
     * @memberof UserResponse
     */
    tags: Array<TagNested>;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    birthday?: string;
    /**
     * 
     * @type {Gender}
     * @memberof UserResponse
     */
    gender: Gender;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    phone?: string;
    /**
     * 
     * @type {LanguageCode}
     * @memberof UserResponse
     */
    language?: LanguageCode;
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    notifications?: number;
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    id: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */
export enum UserRoles {
    User = 'user',
    Admin = 'admin',
    External = 'external'
}

/**
 * 
 * @export
 * @interface UserUpdate
 */
export interface UserUpdate {
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    lastname?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    username?: string;
    /**
     * 
     * @type {UserRoles}
     * @memberof UserUpdate
     */
    role?: UserRoles;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    email?: string;
    /**
     * 
     * @type {Array<TagNested>}
     * @memberof UserUpdate
     */
    tags?: Array<TagNested>;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    birthday?: string;
    /**
     * 
     * @type {Gender}
     * @memberof UserUpdate
     */
    gender?: Gender;
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    phone?: string;
    /**
     * 
     * @type {LanguageCode}
     * @memberof UserUpdate
     */
    language?: LanguageCode;
    /**
     * 
     * @type {number}
     * @memberof UserUpdate
     */
    notifications?: number;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationError
     */
    loc: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    msg: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    type: string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Login User
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUser: async (username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('loginUser', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('loginUser', 'password', password)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (scope !== undefined) { 
                localVarFormParams.set('scope', scope as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Recover Password
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverPassword: async (username: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('recoverPassword', 'username', username)
            const localVarPath = `/auth/password-recovery/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register User
         * @param {UserRegister} userRegister 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser: async (userRegister: UserRegister, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRegister' is not null or undefined
            assertParamExists('registerUser', 'userRegister', userRegister)
            const localVarPath = `/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRegister, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset Password
         * @param {BodyResetPasswordAuthResetPasswordPost} bodyResetPasswordAuthResetPasswordPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (bodyResetPasswordAuthResetPasswordPost: BodyResetPasswordAuthResetPasswordPost, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyResetPasswordAuthResetPasswordPost' is not null or undefined
            assertParamExists('resetPassword', 'bodyResetPasswordAuthResetPasswordPost', bodyResetPasswordAuthResetPasswordPost)
            const localVarPath = `/auth/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyResetPasswordAuthResetPasswordPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Login User
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginUser(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginUser(username, password, grantType, scope, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Recover Password
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async recoverPassword(username: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.recoverPassword(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register User
         * @param {UserRegister} userRegister 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerUser(userRegister: UserRegister, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRegisterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerUser(userRegister, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset Password
         * @param {BodyResetPasswordAuthResetPasswordPost} bodyResetPasswordAuthResetPasswordPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(bodyResetPasswordAuthResetPasswordPost: BodyResetPasswordAuthResetPasswordPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(bodyResetPasswordAuthResetPasswordPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Login User
         * @param {string} username 
         * @param {string} password 
         * @param {string} [grantType] 
         * @param {string} [scope] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUser(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: any): AxiosPromise<UserLoginResponse> {
            return localVarFp.loginUser(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Recover Password
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recoverPassword(username: string, options?: any): AxiosPromise<void> {
            return localVarFp.recoverPassword(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register User
         * @param {UserRegister} userRegister 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser(userRegister: UserRegister, options?: any): AxiosPromise<UserRegisterResponse> {
            return localVarFp.registerUser(userRegister, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset Password
         * @param {BodyResetPasswordAuthResetPasswordPost} bodyResetPasswordAuthResetPasswordPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(bodyResetPasswordAuthResetPasswordPost: BodyResetPasswordAuthResetPasswordPost, options?: any): AxiosPromise<void> {
            return localVarFp.resetPassword(bodyResetPasswordAuthResetPasswordPost, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Login User
     * @param {string} username 
     * @param {string} password 
     * @param {string} [grantType] 
     * @param {string} [scope] 
     * @param {string} [clientId] 
     * @param {string} [clientSecret] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginUser(username: string, password: string, grantType?: string, scope?: string, clientId?: string, clientSecret?: string, options?: any) {
        return AuthApiFp(this.configuration).loginUser(username, password, grantType, scope, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Recover Password
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public recoverPassword(username: string, options?: any) {
        return AuthApiFp(this.configuration).recoverPassword(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register User
     * @param {UserRegister} userRegister 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public registerUser(userRegister: UserRegister, options?: any) {
        return AuthApiFp(this.configuration).registerUser(userRegister, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset Password
     * @param {BodyResetPasswordAuthResetPasswordPost} bodyResetPasswordAuthResetPasswordPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public resetPassword(bodyResetPasswordAuthResetPasswordPost: BodyResetPasswordAuthResetPasswordPost, options?: any) {
        return AuthApiFp(this.configuration).resetPassword(bodyResetPasswordAuthResetPasswordPost, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BookingsApi - axios parameter creator
 * @export
 */
export const BookingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Booking
         * @param {BookingCreate} bookingCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBooking: async (bookingCreate: BookingCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingCreate' is not null or undefined
            assertParamExists('createBooking', 'bookingCreate', bookingCreate)
            const localVarPath = `/bookings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Booking
         * @param {number} id 
         * @param {string} [startDatetime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBooking: async (id: number, startDatetime?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBooking', 'id', id)
            const localVarPath = `/bookings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (startDatetime !== undefined) {
                localVarQueryParameter['start_datetime'] = (startDatetime as any instanceof Date) ?
                    (startDatetime as any).toISOString() :
                    startDatetime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Booking By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBookingById', 'id', id)
            const localVarPath = `/bookings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Bookings
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [ressourceId] 
         * @param {Array<BookingType>} [bookingTypes] filter by booking type
         * @param {number} [participant] filter where user id is participant ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookings: async (start?: string, end?: string, ressourceId?: number, bookingTypes?: Array<BookingType>, participant?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/bookings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (ressourceId !== undefined) {
                localVarQueryParameter['ressource_id'] = ressourceId;
            }

            if (bookingTypes) {
                localVarQueryParameter['booking_types'] = bookingTypes;
            }

            if (participant !== undefined) {
                localVarQueryParameter['participant'] = participant;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Booking
         * @param {number} id 
         * @param {BookingUpdate} bookingUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBooking: async (id: number, bookingUpdate: BookingUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBooking', 'id', id)
            // verify required parameter 'bookingUpdate' is not null or undefined
            assertParamExists('updateBooking', 'bookingUpdate', bookingUpdate)
            const localVarPath = `/bookings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookingUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingsApi - functional programming interface
 * @export
 */
export const BookingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Booking
         * @param {BookingCreate} bookingCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBooking(bookingCreate: BookingCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBooking(bookingCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Booking
         * @param {number} id 
         * @param {string} [startDatetime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBooking(id: number, startDatetime?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBooking(id, startDatetime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Booking By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookingById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookingById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Bookings
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [ressourceId] 
         * @param {Array<BookingType>} [bookingTypes] filter by booking type
         * @param {number} [participant] filter where user id is participant ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBookings(start?: string, end?: string, ressourceId?: number, bookingTypes?: Array<BookingType>, participant?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BookingRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBookings(start, end, ressourceId, bookingTypes, participant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Booking
         * @param {number} id 
         * @param {BookingUpdate} bookingUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBooking(id: number, bookingUpdate: BookingUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBooking(id, bookingUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BookingsApi - factory interface
 * @export
 */
export const BookingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Booking
         * @param {BookingCreate} bookingCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBooking(bookingCreate: BookingCreate, options?: any): AxiosPromise<BookingRead> {
            return localVarFp.createBooking(bookingCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Booking
         * @param {number} id 
         * @param {string} [startDatetime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBooking(id: number, startDatetime?: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBooking(id, startDatetime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Booking By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookingById(id: number, options?: any): AxiosPromise<BookingRead> {
            return localVarFp.getBookingById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Bookings
         * @param {string} [start] 
         * @param {string} [end] 
         * @param {number} [ressourceId] 
         * @param {Array<BookingType>} [bookingTypes] filter by booking type
         * @param {number} [participant] filter where user id is participant ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBookings(start?: string, end?: string, ressourceId?: number, bookingTypes?: Array<BookingType>, participant?: number, options?: any): AxiosPromise<Array<BookingRead>> {
            return localVarFp.getBookings(start, end, ressourceId, bookingTypes, participant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Booking
         * @param {number} id 
         * @param {BookingUpdate} bookingUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBooking(id: number, bookingUpdate: BookingUpdate, options?: any): AxiosPromise<BookingRead> {
            return localVarFp.updateBooking(id, bookingUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingsApi - object-oriented interface
 * @export
 * @class BookingsApi
 * @extends {BaseAPI}
 */
export class BookingsApi extends BaseAPI {
    /**
     * 
     * @summary Create Booking
     * @param {BookingCreate} bookingCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public createBooking(bookingCreate: BookingCreate, options?: any) {
        return BookingsApiFp(this.configuration).createBooking(bookingCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Booking
     * @param {number} id 
     * @param {string} [startDatetime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public deleteBooking(id: number, startDatetime?: string, options?: any) {
        return BookingsApiFp(this.configuration).deleteBooking(id, startDatetime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Booking By Id
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public getBookingById(id: number, options?: any) {
        return BookingsApiFp(this.configuration).getBookingById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Bookings
     * @param {string} [start] 
     * @param {string} [end] 
     * @param {number} [ressourceId] 
     * @param {Array<BookingType>} [bookingTypes] filter by booking type
     * @param {number} [participant] filter where user id is participant ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public getBookings(start?: string, end?: string, ressourceId?: number, bookingTypes?: Array<BookingType>, participant?: number, options?: any) {
        return BookingsApiFp(this.configuration).getBookings(start, end, ressourceId, bookingTypes, participant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Booking
     * @param {number} id 
     * @param {BookingUpdate} bookingUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsApi
     */
    public updateBooking(id: number, bookingUpdate: BookingUpdate, options?: any) {
        return BookingsApiFp(this.configuration).updateBooking(id, bookingUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MailsApi - axios parameter creator
 * @export
 */
export const MailsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Mails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMails: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send Mail
         * @param {SendMailCreate} sendMailCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMail: async (sendMailCreate: SendMailCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendMailCreate' is not null or undefined
            assertParamExists('sendMail', 'sendMailCreate', sendMailCreate)
            const localVarPath = `/mails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendMailCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailsApi - functional programming interface
 * @export
 */
export const MailsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MailsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Mails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMails(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SendMailRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send Mail
         * @param {SendMailCreate} sendMailCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendMail(sendMailCreate: SendMailCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendMail(sendMailCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MailsApi - factory interface
 * @export
 */
export const MailsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MailsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Mails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMails(options?: any): AxiosPromise<Array<SendMailRead>> {
            return localVarFp.getMails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send Mail
         * @param {SendMailCreate} sendMailCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMail(sendMailCreate: SendMailCreate, options?: any): AxiosPromise<void> {
            return localVarFp.sendMail(sendMailCreate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MailsApi - object-oriented interface
 * @export
 * @class MailsApi
 * @extends {BaseAPI}
 */
export class MailsApi extends BaseAPI {
    /**
     * 
     * @summary Get Mails
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailsApi
     */
    public getMails(options?: any) {
        return MailsApiFp(this.configuration).getMails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send Mail
     * @param {SendMailCreate} sendMailCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailsApi
     */
    public sendMail(sendMailCreate: SendMailCreate, options?: any) {
        return MailsApiFp(this.configuration).sendMail(sendMailCreate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RessourcesApi - axios parameter creator
 * @export
 */
export const RessourcesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Ressource
         * @param {RessourceCreate} ressourceCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRessource: async (ressourceCreate: RessourceCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ressourceCreate' is not null or undefined
            assertParamExists('createRessource', 'ressourceCreate', ressourceCreate)
            const localVarPath = `/ressources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ressourceCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Ressource
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRessource: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRessource', 'id', id)
            const localVarPath = `/ressources/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Ressources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRessources: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/ressources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reorder Resource
         * @param {number} id 
         * @param {number} fixedResourceId 
         * @param {Insert} insert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderResource: async (id: number, fixedResourceId: number, insert: Insert, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reorderResource', 'id', id)
            // verify required parameter 'fixedResourceId' is not null or undefined
            assertParamExists('reorderResource', 'fixedResourceId', fixedResourceId)
            // verify required parameter 'insert' is not null or undefined
            assertParamExists('reorderResource', 'insert', insert)
            const localVarPath = `/ressources/reorder/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)

            if (fixedResourceId !== undefined) {
                localVarQueryParameter['fixed_resource_id'] = fixedResourceId;
            }

            if (insert !== undefined) {
                localVarQueryParameter['insert'] = insert;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Ressource
         * @param {number} id 
         * @param {RessourceUpdate} ressourceUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRessource: async (id: number, ressourceUpdate: RessourceUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRessource', 'id', id)
            // verify required parameter 'ressourceUpdate' is not null or undefined
            assertParamExists('updateRessource', 'ressourceUpdate', ressourceUpdate)
            const localVarPath = `/ressources/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ressourceUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RessourcesApi - functional programming interface
 * @export
 */
export const RessourcesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RessourcesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Ressource
         * @param {RessourceCreate} ressourceCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRessource(ressourceCreate: RessourceCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRessource(ressourceCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Ressource
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRessource(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRessource(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Ressources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRessources(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RessourceRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRessources(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reorder Resource
         * @param {number} id 
         * @param {number} fixedResourceId 
         * @param {Insert} insert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reorderResource(id: number, fixedResourceId: number, insert: Insert, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reorderResource(id, fixedResourceId, insert, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Ressource
         * @param {number} id 
         * @param {RessourceUpdate} ressourceUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRessource(id: number, ressourceUpdate: RessourceUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRessource(id, ressourceUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RessourcesApi - factory interface
 * @export
 */
export const RessourcesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RessourcesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Ressource
         * @param {RessourceCreate} ressourceCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRessource(ressourceCreate: RessourceCreate, options?: any): AxiosPromise<void> {
            return localVarFp.createRessource(ressourceCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Ressource
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRessource(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRessource(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Ressources
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRessources(options?: any): AxiosPromise<Array<RessourceRead>> {
            return localVarFp.getRessources(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reorder Resource
         * @param {number} id 
         * @param {number} fixedResourceId 
         * @param {Insert} insert 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reorderResource(id: number, fixedResourceId: number, insert: Insert, options?: any): AxiosPromise<any> {
            return localVarFp.reorderResource(id, fixedResourceId, insert, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Ressource
         * @param {number} id 
         * @param {RessourceUpdate} ressourceUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRessource(id: number, ressourceUpdate: RessourceUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.updateRessource(id, ressourceUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RessourcesApi - object-oriented interface
 * @export
 * @class RessourcesApi
 * @extends {BaseAPI}
 */
export class RessourcesApi extends BaseAPI {
    /**
     * 
     * @summary Create Ressource
     * @param {RessourceCreate} ressourceCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RessourcesApi
     */
    public createRessource(ressourceCreate: RessourceCreate, options?: any) {
        return RessourcesApiFp(this.configuration).createRessource(ressourceCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Ressource
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RessourcesApi
     */
    public deleteRessource(id: number, options?: any) {
        return RessourcesApiFp(this.configuration).deleteRessource(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Ressources
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RessourcesApi
     */
    public getRessources(options?: any) {
        return RessourcesApiFp(this.configuration).getRessources(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reorder Resource
     * @param {number} id 
     * @param {number} fixedResourceId 
     * @param {Insert} insert 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RessourcesApi
     */
    public reorderResource(id: number, fixedResourceId: number, insert: Insert, options?: any) {
        return RessourcesApiFp(this.configuration).reorderResource(id, fixedResourceId, insert, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Ressource
     * @param {number} id 
     * @param {RessourceUpdate} ressourceUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RessourcesApi
     */
    public updateRessource(id: number, ressourceUpdate: RessourceUpdate, options?: any) {
        return RessourcesApiFp(this.configuration).updateRessource(id, ressourceUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Settings
         * @param {AppSettings} appSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings: async (appSettings: AppSettings, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'appSettings' is not null or undefined
            assertParamExists('updateSettings', 'appSettings', appSettings)
            const localVarPath = `/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettings(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Settings
         * @param {AppSettings} appSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSettings(appSettings: AppSettings, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSettings(appSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(options?: any): AxiosPromise<AppSettings> {
            return localVarFp.getSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Settings
         * @param {AppSettings} appSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings(appSettings: AppSettings, options?: any): AxiosPromise<AppSettings> {
            return localVarFp.updateSettings(appSettings, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * 
     * @summary Get Settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getSettings(options?: any) {
        return SettingsApiFp(this.configuration).getSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Settings
     * @param {AppSettings} appSettings 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public updateSettings(appSettings: AppSettings, options?: any) {
        return SettingsApiFp(this.configuration).updateSettings(appSettings, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UploadApi - axios parameter creator
 * @export
 */
export const UploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Upload File
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadFile: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUploadFile', 'id', id)
            const localVarPath = `/upload/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload File
         * @param {any} uploadFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (uploadFile: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadFile' is not null or undefined
            assertParamExists('uploadFile', 'uploadFile', uploadFile)
            const localVarPath = `/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


            if (uploadFile !== undefined) { 
                localVarFormParams.append('upload_file', uploadFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UploadApi - functional programming interface
 * @export
 */
export const UploadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UploadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Upload File
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadFile(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUploadFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload File
         * @param {any} uploadFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(uploadFile: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(uploadFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UploadApi - factory interface
 * @export
 */
export const UploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UploadApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Upload File
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadFile(id: string, options?: any): AxiosPromise<any> {
            return localVarFp.getUploadFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload File
         * @param {any} uploadFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(uploadFile: any, options?: any): AxiosPromise<UploadCreate> {
            return localVarFp.uploadFile(uploadFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UploadApi - object-oriented interface
 * @export
 * @class UploadApi
 * @extends {BaseAPI}
 */
export class UploadApi extends BaseAPI {
    /**
     * 
     * @summary Get Upload File
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public getUploadFile(id: string, options?: any) {
        return UploadApiFp(this.configuration).getUploadFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload File
     * @param {any} uploadFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadFile(uploadFile: any, options?: any) {
        return UploadApiFp(this.configuration).uploadFile(uploadFile, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Admin can change all passwords. The user can only change his own password.
         * @summary Change User Password
         * @param {number} id 
         * @param {BodyChangeUserPasswordUsersIdPasswordPatch} bodyChangeUserPasswordUsersIdPasswordPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPassword: async (id: number, bodyChangeUserPasswordUsersIdPasswordPatch: BodyChangeUserPasswordUsersIdPasswordPatch, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('changeUserPassword', 'id', id)
            // verify required parameter 'bodyChangeUserPasswordUsersIdPasswordPatch' is not null or undefined
            assertParamExists('changeUserPassword', 'bodyChangeUserPasswordUsersIdPasswordPatch', bodyChangeUserPasswordUsersIdPasswordPatch)
            const localVarPath = `/users/{id}/password`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyChangeUserPasswordUsersIdPasswordPatch, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create User
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (userCreate: UserCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreate' is not null or undefined
            assertParamExists('createUser', 'userCreate', userCreate)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create User Tag
         * @param {TagCreate} tagCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserTag: async (tagCreate: TagCreate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagCreate' is not null or undefined
            assertParamExists('createUserTag', 'tagCreate', tagCreate)
            const localVarPath = `/users/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete User
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUser', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete User Tag
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserTag: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserTag', 'id', id)
            const localVarPath = `/users/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUser', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User
         * @param {number} id 
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: number, userUpdate: UserUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUser', 'id', id)
            // verify required parameter 'userUpdate' is not null or undefined
            assertParamExists('updateUser', 'userUpdate', userUpdate)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User Tag
         * @param {number} id 
         * @param {TagUpdate} tagUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserTag: async (id: number, tagUpdate: TagUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserTag', 'id', id)
            // verify required parameter 'tagUpdate' is not null or undefined
            assertParamExists('updateUserTag', 'tagUpdate', tagUpdate)
            const localVarPath = `/users/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Admin can change all passwords. The user can only change his own password.
         * @summary Change User Password
         * @param {number} id 
         * @param {BodyChangeUserPasswordUsersIdPasswordPatch} bodyChangeUserPasswordUsersIdPasswordPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeUserPassword(id: number, bodyChangeUserPasswordUsersIdPasswordPatch: BodyChangeUserPasswordUsersIdPasswordPatch, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeUserPassword(id, bodyChangeUserPasswordUsersIdPasswordPatch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create User
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(userCreate: UserCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create User Tag
         * @param {TagCreate} tagCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserTag(tagCreate: TagCreate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserTag(tagCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete User
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete User Tag
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserTag(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserTag(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMe(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTags(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TagRead>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get User
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update User
         * @param {number} id 
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: number, userUpdate: UserUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, userUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update User Tag
         * @param {number} id 
         * @param {TagUpdate} tagUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserTag(id: number, tagUpdate: TagUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserTag(id, tagUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Admin can change all passwords. The user can only change his own password.
         * @summary Change User Password
         * @param {number} id 
         * @param {BodyChangeUserPasswordUsersIdPasswordPatch} bodyChangeUserPasswordUsersIdPasswordPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserPassword(id: number, bodyChangeUserPasswordUsersIdPasswordPatch: BodyChangeUserPasswordUsersIdPasswordPatch, options?: any): AxiosPromise<void> {
            return localVarFp.changeUserPassword(id, bodyChangeUserPasswordUsersIdPasswordPatch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create User
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(userCreate: UserCreate, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.createUser(userCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create User Tag
         * @param {TagCreate} tagCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserTag(tagCreate: TagCreate, options?: any): AxiosPromise<TagRead> {
            return localVarFp.createUserTag(tagCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete User
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete User Tag
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserTag(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserTag(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options?: any): AxiosPromise<UserResponse> {
            return localVarFp.getMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags(options?: any): AxiosPromise<Array<TagRead>> {
            return localVarFp.getTags(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(id: number, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.getUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): AxiosPromise<Array<UserResponse>> {
            return localVarFp.getUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User
         * @param {number} id 
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(id: number, userUpdate: UserUpdate, options?: any): AxiosPromise<any> {
            return localVarFp.updateUser(id, userUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User Tag
         * @param {number} id 
         * @param {TagUpdate} tagUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserTag(id: number, tagUpdate: TagUpdate, options?: any): AxiosPromise<TagRead> {
            return localVarFp.updateUserTag(id, tagUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Admin can change all passwords. The user can only change his own password.
     * @summary Change User Password
     * @param {number} id 
     * @param {BodyChangeUserPasswordUsersIdPasswordPatch} bodyChangeUserPasswordUsersIdPasswordPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public changeUserPassword(id: number, bodyChangeUserPasswordUsersIdPasswordPatch: BodyChangeUserPasswordUsersIdPasswordPatch, options?: any) {
        return UsersApiFp(this.configuration).changeUserPassword(id, bodyChangeUserPasswordUsersIdPasswordPatch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create User
     * @param {UserCreate} userCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUser(userCreate: UserCreate, options?: any) {
        return UsersApiFp(this.configuration).createUser(userCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create User Tag
     * @param {TagCreate} tagCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUserTag(tagCreate: TagCreate, options?: any) {
        return UsersApiFp(this.configuration).createUserTag(tagCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete User
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUser(id: number, options?: any) {
        return UsersApiFp(this.configuration).deleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete User Tag
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUserTag(id: number, options?: any) {
        return UsersApiFp(this.configuration).deleteUserTag(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getMe(options?: any) {
        return UsersApiFp(this.configuration).getMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getTags(options?: any) {
        return UsersApiFp(this.configuration).getTags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUser(id: number, options?: any) {
        return UsersApiFp(this.configuration).getUser(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUsers(options?: any) {
        return UsersApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User
     * @param {number} id 
     * @param {UserUpdate} userUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUser(id: number, userUpdate: UserUpdate, options?: any) {
        return UsersApiFp(this.configuration).updateUser(id, userUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User Tag
     * @param {number} id 
     * @param {TagUpdate} tagUpdate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUserTag(id: number, tagUpdate: TagUpdate, options?: any) {
        return UsersApiFp(this.configuration).updateUserTag(id, tagUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}


