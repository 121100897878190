import { Box, Container, LinearProgress, Typography } from "@material-ui/core"
import { AppSettings, settingsApi, useRessources, useSettings } from "api/scheduleAPI"
import AppMenuBar from "components/AppDrawer/AppMenuBar"
import SettingsForm from "features/settingsList/SettingsForm"
import { useSnackbar } from "notistack"
import React from "react"
import { useMutation, useQueryClient } from "react-query"

export const SettingsListPage = () => {
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const { data: settings, isLoading: isLoadingSettings } = useSettings()
  const { data: resources, isLoading: isLoadingRessources } = useRessources()

  const isLoading = isLoadingSettings && isLoadingRessources

  const mutation = useMutation((values: AppSettings) => settingsApi.updateSettings(values), {
    onSuccess: () => {
      enqueueSnackbar("Successfully saved.", { variant: "success" })
    },
    onError: () => {
      enqueueSnackbar("Error.", { variant: "error" })
    },
    onSettled: () => {
      queryClient.invalidateQueries("settings")
    },
  })

  const onSubmit = (settings: AppSettings) => {
    mutation.mutate(settings)
  }

  if (isLoading || settings === undefined || resources === undefined) {
    return (
      <Box p={10}>
        <LinearProgress />
      </Box>
    )
  }

  return (
    <Container component="main" disableGutters maxWidth="md">
      <AppMenuBar>
        <Typography variant="h5">Settings</Typography>
      </AppMenuBar>
      <SettingsForm settings={settings} resources={resources} onSubmit={onSubmit} />
    </Container>
  )
}
