import { Chip, ChipProps } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import LabelIcon from "@material-ui/icons/Label"
import { pickTextColorBasedOnBgColor } from "features/booking/utils/BookingColorManager"
import React from "react"

export type CustomTagProps = {
  color?: string
} & Omit<ChipProps, "color">

const useStyles = makeStyles((theme) => ({
  tag: {
    textTransform: "capitalize",
    marginRight: "0.1rem",
    marginLeft: "0.1rem",
  },
}))
export const CustomTag = ({ color, icon = <LabelIcon />, ...props }: CustomTagProps) => {
  const classes = useStyles()
  return (
    <Chip
      className={classes.tag}
      disabled={props.disabled}
      size="medium"
      style={{ color: pickTextColorBasedOnBgColor(color, "#fff", "#1e1e1e"), backgroundColor: color }}
      {...props}
      icon={icon}
    />
  )
}
