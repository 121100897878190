import { makeStyles } from "@material-ui/core"
import { maxSlotHeight } from "features/booking/utils/BookingSlotHandler"

export const drawerWidth = 200

export const useBookingStyles = makeStyles((theme) => ({
  timeStep: {
    marginTop: "-0.65rem",
    marginRight: "0.5rem",
    background: "linear-gradient(90deg, rgba(250,250,250,1) 0%, rgba(250,250,250,1) 86%, rgba(250,250,250,0) 100%)",
  },
  ressource: {
    borderBottom: "1px solid #dee2e6",
  },
  closedSlotItem: {
    textAlign: "center",
    height: maxSlotHeight + "rem",
    border: "1px solid #dee2e6",
    borderBottom: 0,
    borderRight: 0,
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    backgroundColor: "#1E1E1E",
  },
}))
