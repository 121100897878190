import { Box, Button, Chip, FormControl, Grid, TextField, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { AddBox } from "@material-ui/icons"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever"
import { Autocomplete } from "@material-ui/lab"
import { Ressource, Rule } from "api/scheduleAPI"
import ReactHookTimeSelect from "components/Form/ReactHookTimeSelect"
import { DateTime } from "luxon"
import React from "react"
import { Controller, useFieldArray } from "react-hook-form"
import { bitPositionsToNumber, numberToBitPositions } from "utils/Bits"

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: theme.spacing(50),
  },
  rule: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    border: "1px dashed",
    borderColor: theme.palette.grey[400],
    marginBottom: theme.spacing(2),
  },
  ruleInput: {
    backgroundColor: "white",
  },
}))

export type AvailabilityRulesProps = {
  name: string
  control: any
  [otherProps: string]: any
  resources: Ressource[]
}

const AvailabilityRules: React.FC<AvailabilityRulesProps> = ({ name, control, resources, ...props }) => {
  const classes = useStyles()
  const labelId = `${name}-label`
  const label = "ressource"
  const {
    fields: rules,
    append,
    remove,
  } = useFieldArray({
    control,
    name: name,
  })

  return (
    <FormControl {...props}>
      {rules.map((rule, index) => (
        <div className={classes.rule} key={rule.id}>
          <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1} key={rule.id}>
            <Grid item>
              <FormControl>
                <Controller
                  name={`${name}.${index}.ressourceIds`}
                  defaultValue={`${rule}.ressourceIds`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disableClearable={true}
                      options={resources.map((r) => r.id)}
                      multiple
                      value={value ? value : []}
                      onChange={(event, data) => {
                        onChange(data)
                      }}
                      getOptionSelected={(option, value) => option === value}
                      getOptionLabel={(option: number) => resources.find((r) => r.id === option)?.name || "Unknown"}
                      renderTags={(tagValue: any, getTagProps: any) =>
                        tagValue.map((option: number, index: any) => (
                          <Chip
                            size="small"
                            label={resources.find((r) => r.id === option)?.name}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          margin="dense"
                          style={{ minWidth: "15rem" }}
                          className={classes.ruleInput}
                          placeholder="bookable courts, rooms..."
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item>
              <Typography>are available between</Typography>
            </Grid>
            <Grid item>
              <ReactHookTimeSelect
                control={control}
                label="Start time"
                name={`${name}.${index}.start`}
                margin="dense"
                required
                startMinute={0}
                endMinute={1440}
                granularity={15}
                style={{ minWidth: "6rem" }}
                className={classes.ruleInput}
              />
            </Grid>

            <Grid item>
              <Typography> to </Typography>
            </Grid>
            <Grid item>
              <ReactHookTimeSelect
                control={control}
                label="End time"
                name={`${name}.${index}.end`}
                margin="dense"
                required
                startMinute={0}
                endMinute={1440}
                granularity={15}
                style={{ minWidth: "6rem" }}
                className={classes.ruleInput}
              />
            </Grid>
            <Grid item>
              <Typography> on </Typography>
            </Grid>
            <Grid item>
              <FormControl>
                <Controller
                  name={`${name}.${index}.dayBitmask`}
                  control={control}
                  defaultValue={`${rule}.dayBitmask`}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      disableClearable={true}
                      options={numberToBitPositions(127)}
                      multiple
                      value={numberToBitPositions(value)}
                      onChange={(event, data) => {
                        onChange(bitPositionsToNumber(data))
                      }}
                      getOptionSelected={(option, value) => option === value}
                      getOptionLabel={(option: number) => DateTime.local().set({ weekday: option }).weekdayLong}
                      renderTags={(tagValue: any, getTagProps: any) =>
                        tagValue.map((option: number, index: any) => (
                          <Chip
                            size="small"
                            label={DateTime.local().set({ weekday: option }).weekdayShort}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          margin="dense"
                          className={classes.ruleInput}
                          style={{ minWidth: "15rem" }}
                          placeholder="Weekdays"
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Box textAlign="right">
            <Button variant="outlined" color="primary" onClick={(e) => remove(index)} startIcon={<DeleteForeverIcon />}>
              Delete
            </Button>
          </Box>
        </div>
      ))}
      <Box>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddBox />}
          onClick={(e) => append({ ressourceIds: [], dayBitmask: 0, start: 0, end: 1440 })}
        >
          New Rule
        </Button>
      </Box>
    </FormControl>
  )
}
export default AvailabilityRules
