import { Box, Button, FormControlLabel, Switch, TextField, Typography } from "@material-ui/core"
import { DeleteForever, Save } from "@material-ui/icons"
import { RessourceRead, ressourcesApi, RessourceUpdate, useTags } from "api/scheduleAPI"
import { UserTags } from "features/userTags/UserTags"
import { useSnackbar } from "notistack"
import React from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"

const initialValues: RessourceUpdate = {
  name: "",
  description: "",
  published: true,
  tags: [],
}

export type ResourceFromProps = {
  resource: RessourceRead
}

const ResourceFrom: React.FC<ResourceFromProps> = ({ resource }) => {
  const { register, getValues, control } = useForm<RessourceRead>({
    defaultValues: resource,
  })
  const { ref: refName, ...restName } = register('name',{ required: true })
  const { ref: refDescription, ...restDescription } = register('description',{ required: false })
  const { ref: refPublished, ...restPublished } = register('published',{ required: true })
  
  const { data: tags } = useTags()
  const onSubmit = () => {
    muUpdate.mutate(getValues())
  }

  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const muDelete = useMutation((id: number) => ressourcesApi.deleteRessource(id), {
    onSuccess: () => {
      enqueueSnackbar("Space successfully deleted.", { variant: "success" })
    },
    onError: () => {
      enqueueSnackbar("Error deleting space.", { variant: "error" })
    },
    onSettled: () => {
      queryClient.invalidateQueries("ressources")
    },
  })

  const muUpdate = useMutation((values: RessourceUpdate) => ressourcesApi.updateRessource(resource.id, values), {
    onSuccess: () => {
      enqueueSnackbar("Space successfully updated.", { variant: "success" })
    },
    onError: () => {
      enqueueSnackbar("Error updating space.", { variant: "error" })
    },
    onSettled: () => {
      queryClient.invalidateQueries("ressources")
    },
  })

  return (
    <>
      <TextField label="Space Name" {...restName}
      inputRef={refName} required />
      <TextField
        label="Description"
        {...restDescription}
        type="text"
        rows={2}
        multiline
        placeholder="Details that might be useful."
        inputRef={refDescription}
      />
      <Box>
        <UserTags control={control} tagOptions={tags} />
        <Typography variant="body2" color="textSecondary">
          Only users with these tags can book the space.
        </Typography>
      </Box>
      <FormControlLabel
        control={<Switch color="primary" defaultChecked={resource.published} />}
        inputRef={refPublished}
        label="Published"
        {...restPublished}
      />

      <Box display="flex" justifyContent="flex-end">
        <Box>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<DeleteForever />}
            onClick={() => muDelete.mutate(resource.id)}
          >
            Delete
          </Button>
        </Box>
        <Box pl={2}>
          <Button variant="contained" color="primary" startIcon={<Save />} onClick={() => onSubmit()}>
            Save
          </Button>
        </Box>
      </Box>
    </>
  )
}
export default ResourceFrom
