import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core"
import InfoIcon from "@material-ui/icons/Info"
import {
  Booking,
  TagRead,
  useBookingRequestsForUser,
  useBookingsForDay,
  useMe,
  User,
  useRessources,
  UserRoles,
  useTags,
  useUsers,
} from "api/scheduleAPI"
import { primaryColor } from "app/withRoot"
import { useBookingContext } from "context/bookingContext"
import { DateTime } from "luxon"
import React, { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { BookingCardView } from "../views/BookingCardView"
import { CreateBookingCardView } from "../views/CreateBookingCardView"
import { PreviousNextButtonPair } from "../views/PreviousNextButtonPair"
import { BookingTableRows } from "../views/slotviews/BookingTableRows"

export type BookingSlotTableProps = {
  showDialogForExisting: Function
  columns: BookingColumn[]
  rows: BookingRow[]
  granularity: number
  date: DateTime
  changeResourceHandler: Function
  slotCount: number
}

export type BookingColumn = {
  id: number
  label: string
  tooltip?: string
  buttons?: boolean
}

export interface BookingRow {
  time: number
  resourceIDs: number[]
  closed: boolean[]
}

type StyleProps = {
  height: number
}
const useStyles = makeStyles((theme) => ({
  timePopper: {
    backgroundColor: "transparent",
    color: "#fff",
    width: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    // height: (props: number) => props + 'rem',
  },
  timePopperTypography: {
    textAlign: "start",
    marginTop: "0px",
    marginLeft: "4px",
    fontSize: "0.6rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "0.5rem",
    },
  },
  playerPopperTypography: {
    textAlign: "start",
    marginTop: "2px",
    marginLeft: "4px",
    marginBottom: "0px",
    fontSize: "1rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  titlePopperTypography: {
    marginTop: "0px",
    textAlign: "end",
    fontSize: "0.8rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  expandBox: {
    position: "relative",
  },
  expandLess: {
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    top: "-0.15rem",
  },
  expandMore: {
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    bottom: "-0.15rem",
  },
  schedulerItemSelected: {
    color: primaryColor,
    backgroundColor: "#E0E0E0",
    height: "100%",
    position: "absolute",
  },
  bookingCardView: {
    height: "100%",
    position: "absolute",
    background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
    border: 0,
    borderRadius: 3,
    boxShadow: "0px 0px 6px 2px rgba(0, 0, 0, .3)",
    "&:hover": {
      boxShadow: "0px 0px 8px 4px rgba(0, 0, 0, .3)",
      //border: "1px solid white"
    },
    color: "white",
  },
  blockedBookingCardView: {
    height: "100%",
    position: "absolute",
    background: "#1E1E1E",
    border: 0,
    borderRadius: 0,
    "&:hover": {
      boxShadow: "0px 0px 8px 4px rgba(0, 0, 0, .3)",
      //border: "1px solid white"
    },
    color: "white",
  },
  prevNextButton: {
    backgroundColor: "transparent",
    margin: "0px",
    padding: "2px",
  },
  schedulerItem: {
    textAlign: "center",
    height: (props: StyleProps) => props.height + "rem",
    borderLeft: "1px solid #dee2e6",
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    backgroundColor: "#fff",
  },
  schedulerItemHover: {
    "&:hover": {
      backgroundColor: primaryColor,
      color: "#fff",
    },
  },
  closedSlotItem: {
    textAlign: "center",
    height: (props: StyleProps) => props.height,
    border: "1px solid #dee2e6",
    borderBottom: 0,
    borderRight: 0,
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    backgroundColor: "#1E1E1E",
  },
  schedulerItemBlocked: {
    color: "#d3d3d3",
    backgroundColor: "#d3d3d3",
    cursor: "not-allowed",
  },
  timeStep: {
    marginTop: "-1rem",
    marginRight: "0.6rem",
    background: "linear-gradient(90deg, rgba(250,250,250,1) 0%, rgba(250,250,250,1) 86%, rgba(250,250,250,0) 100%)",
  },
  schedulerItemNoPermission: {
    cursor: "help",
    backgroundColor: "transparent",
    color: "transparent",
    background: "repeating-linear-gradient(-40deg,#fff,#fff 10px,#EAEAEA 10px,#EAEAEA 20px)",
  },
  container: {
    maxHeight: "100%",
    paddingBottom: "100px",
  },
}))
const createTableHeader = (columns: BookingColumn[], changeResourceHandler: Function, classes: any, height: number) => {
  const align = "center"
  if (columns.some((column) => column.buttons)) {
    return columns.map((column) => {
      const courtColumnCount = columns.length - 1
      return column.buttons ? (
        <TableCell style={{ padding: 0 }} key={column.id} align={align}>
          <PreviousNextButtonPair
            onClickPreviousHandler={() => changeResourceHandler(false)}
            onClickNextHandler={() => changeResourceHandler(true)}
            additionalClass={classes.prevNextButton}
          />
        </TableCell>
      ) : (
        <TableCell style={{ height: height, width: `calc(84% / ${courtColumnCount})` }} key={column.id} align={align}>
          {column.tooltip && column.tooltip !== "" ? (
            <Tooltip title={column.tooltip} arrow>
              <Box flexDirection="row">
                {column.label}
                <InfoIcon fontSize="inherit" />
              </Box>
            </Tooltip>
          ) : (
            column.label
          )}
        </TableCell>
      )
    })
  } else {
    return columns.map((column) => {
      return (
        <TableCell style={{ height: height}} key={column.id} align={align}>
          {column.tooltip && column.tooltip !== "" ? (
            <Tooltip title={column.tooltip} arrow>
              <Box flexDirection="row">
                {column.label}
                <InfoIcon fontSize="inherit" />
              </Box>
            </Tooltip>
          ) : (
            column.label
          )}
        </TableCell>
      )
    })
  }
}
const calculateSlotHeight = (slotCount: number, windowHeight: number) => {
  let slotHeight = (windowHeight - 160) / slotCount / 16
  slotHeight = Math.max(1.3, slotHeight)
  slotHeight = Math.min(slotHeight, 5)
  return slotHeight
}
const createBookingCardView = (
  mouseDown: Function,
  mouseEnter: (event: React.MouseEvent) => void,
  booking: Booking,
  height: number,
  granularity: number,
  classes: any,
  user: User | undefined,
  users: User[] | undefined,
  columns: BookingColumn[],
  tags: TagRead[] | undefined,
) => {
  if (columns.some((column) => column.id === booking.ressource_id)) {
    let anchorEl = document.getElementById("slot_" + booking.ressource_id + "_" + booking.startDateTime.toMinutes())
    return (
      <BookingCardView
        mouseDown={mouseDown}
        mouseEnter={mouseEnter}
        granularity={granularity}
        height={height}
        anchorEl={anchorEl}
        booking={booking}
        classes={classes}
        users={users}
        user={user}
        tag={tags?.find((tag) =>
          booking.tags ? (booking.tags.length > 0 ? booking.tags[0].id === tag.id : undefined) : undefined,
        )}
      />
    )
  }
  return null
}
const debounce = (fn: Function, ms: number) => {
  let timer: any
  var self = this
  return () => {
    clearTimeout(timer)
    timer = setTimeout(function () {
      timer = null
      fn.apply(self, arguments)
    }, ms)
  }
}
export const BookingSlotTable = (props: BookingSlotTableProps) => {
  const { booking } = useBookingContext()
  const { data: users } = useUsers()
  const { data: user } = useMe()
  const { data: tags } = useTags()
  const { data: resources } = useRessources()
  const { data: bookingsForWeek } = useBookingsForDay(props.date)
  const { data: bookingRequests } = useBookingRequestsForUser(user)
  const [tableRendered, setTableRendered] = useState(false)
  const bookings = bookingsForWeek?.filter((b) => b.startDateTime.day === props.date.day)
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
    slotHeight: calculateSlotHeight(props.slotCount + 1, window.innerHeight),
  })
  const classes = useStyles({ height: dimensions.slotHeight })
  const intl = useIntl()
  const mouseDownOnExistingHandler = useCallback((event: React.MouseEvent, clickedBooking: Booking) => {
    // Update coordinates
    event.preventDefault()
    props.showDialogForExisting(clickedBooking)
  }, [])
  const mouseEnterExistingHandler = useCallback(
    (event: React.MouseEvent) => {
      // Update coordinates
      event.preventDefault()
      event.target.dispatchEvent(
        new MouseEvent("mouseup", {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1,
        }),
      )
    },
    [window.innerWidth],
  )
  const displayedResourcesChanged = (next: boolean) => {
    setTableRendered(false)
    props.changeResourceHandler(next)
  }
  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
        slotHeight: calculateSlotHeight(props.slotCount + 1, window.innerHeight),
      })
      setTableRendered(false)
    }, 60)
    window.addEventListener("resize", debouncedHandleResize)
    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  })
  useEffect(() => {
    if (!tableRendered) {
      setTableRendered(true)
    }
  }, [tableRendered])
  const bookableTags = user?.role === UserRoles.Admin ? undefined : user?.tags
  const bookableResources = resources?.map((resource) => {
    if (resource.tags === undefined || resource.tags.length === 0 || bookableTags === undefined) {
      return resource.id
    } else {
      let bookable = true
      resource.tags.forEach((tag) => {
        if (!bookableTags.some((bt) => bt.id === tag.id)) {
          bookable = false
        }
      })
      if (bookable) {
        return resource.id
      } else {
        return -1
      }
    }
  })
  return (
    <React.Fragment>
      <TableContainer id="schedulerTable" className={classes.container}>
        <CreateBookingCardView height={dimensions.slotHeight} granularity={props.granularity} date={props.date} />
        {tableRendered &&
          bookings?.map((existingBooking) => {
            return createBookingCardView(
              mouseDownOnExistingHandler,
              mouseEnterExistingHandler,
              existingBooking,
              dimensions.slotHeight,
              props.granularity,
              classes,
              user,
              users,
              props.columns,
              tags,
            )
          })}
        {tableRendered &&
          bookingRequests?.map((existingBooking) => {
            existingBooking.title = intl.formatMessage({
              id: "common.Request",
              description: "Request label",
              defaultMessage: "Request",
            })
            return createBookingCardView(
              mouseDownOnExistingHandler,
              mouseEnterExistingHandler,
              existingBooking,
              dimensions.slotHeight,
              props.granularity,
              classes,
              user,
              users,
              props.columns,
              tags,
            )
          })}
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead>
            <TableRow>
              {createTableHeader(props.columns, displayedResourcesChanged, classes, dimensions.slotHeight)}
            </TableRow>
          </TableHead>
          <TableBody>
            {BookingTableRows({
              ...props,
              classes: classes,
              height: dimensions.slotHeight,
              hoverable: !booking,
              bookableResources: bookableResources,
              tooltipNoPermission: intl.formatMessage({
                id: "tooltips.NoPermission",
                description: "No booking permission tooltip",
                defaultMessage: "No subscription for this court, only request possible",
              }),
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}
