import { CssBaseline, IconButton, MuiThemeProvider } from "@material-ui/core"
import { createMuiTheme } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import { LocalizationProvider } from "@material-ui/pickers"
import LuxonUtils from "@material-ui/pickers/adapter/luxon"
import { MobileMenuProvider } from "context/MobileMenuContext"
import { AuthProvider } from "features/auth/AuthContext"
import { SnackbarKey, SnackbarProvider } from "notistack"
import React from "react"
import { IntlProvider } from "react-intl"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { HashRouter } from "react-router-dom"
import { default as messages_de } from "../compiled-lang/de.json"
import { default as messages_en } from "../compiled-lang/en.json"

export const primaryColor = "#2563AB"
export const secondaryColor = "#AB4026" //"#2cccbc"
const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  props: {
    // Name of the component ⚛️
    MuiTextField: {
      variant: "outlined",
      fullWidth: true,
      margin: "normal",
    },
    MuiFormControl: {
      variant: "outlined",
      fullWidth: true,
    },
    MuiSelect: {
      variant: "outlined",
      fullWidth: true,
    },
  },
})

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})
let current_locale = "en"
const getTranslations = () => {
  current_locale = navigator.language
  switch (current_locale) {
    case "de":
      return messages_de
    case "fr":
    default:
      return messages_en
  }
}

export function withRoot(Component: any) {
  const messages = getTranslations()
  // add action to all snackbars
  const notistackRef = React.createRef<SnackbarProvider>()
  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef.current?.closeSnackbar(key)
  }

  function WithRoot(props: object) {
    return (
      <QueryClientProvider client={client}>
        <ReactQueryDevtools initialIsOpen />
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <HashRouter>
            <IntlProvider locale={current_locale} messages={messages}>
              <LocalizationProvider dateAdapter={LuxonUtils}>
                <AuthProvider>
                  <SnackbarProvider
                    maxSnack={4}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    ref={notistackRef}
                    action={(key) => (
                      <IconButton size="small" color="inherit" onClick={onClickDismiss(key)}>
                        <CloseIcon />
                      </IconButton>
                    )}
                  >
                    <MobileMenuProvider>
                      <Component {...props} />
                    </MobileMenuProvider>
                  </SnackbarProvider>
                </AuthProvider>
              </LocalizationProvider>
            </IntlProvider>
          </HashRouter>
        </MuiThemeProvider>
      </QueryClientProvider>
    )
  }

  return WithRoot
}
