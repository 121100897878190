import { Button, ButtonGroup } from "@material-ui/core"
import { BookingType } from "api/scheduleAPI"
import React from "react"
import PersonIcon from "@material-ui/icons/Person"
import BlockIcon from "@material-ui/icons/Block"
import HomeWorkIcon from "@material-ui/icons/HomeWork"

export type RadioButtonGroupProps = {
  bookingType: BookingType
  setBookingType: (type: BookingType) => void
}

export default function RadioButtonGroup(props: RadioButtonGroupProps) {
  return (
    <ButtonGroup color="primary" aria-label="outlined primary button group" fullWidth>
      <Button
        onClick={() => props.setBookingType(BookingType.User)}
        variant={props.bookingType === BookingType.User ? "contained" : "outlined"}
        startIcon={<PersonIcon />}
      >
        User
      </Button>
      <Button
        onClick={() => props.setBookingType(BookingType.Owner)}
        variant={props.bookingType === BookingType.Owner ? "contained" : "outlined"}
        startIcon={<HomeWorkIcon />}
      >
        Internal
      </Button>
      <Button
        onClick={() => props.setBookingType(BookingType.Blocked)}
        variant={props.bookingType === BookingType.Blocked ? "contained" : "outlined"}
        startIcon={<BlockIcon />}
      >
        Blocked
      </Button>
    </ButtonGroup>
  )
}
