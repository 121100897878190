import {
  AppBar,
  Box,
  Container,
  createStyles,
  DialogContent,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Slide,
  TextField,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import DialogContentText from "@material-ui/core/DialogContentText"
import { TransitionProps } from "@material-ui/core/transitions"
import CloseIcon from "@material-ui/icons/Close"
import DeleteIcon from "@material-ui/icons/Delete"
import SaveIcon from "@material-ui/icons/Save"
import { Alert, Autocomplete, createFilterOptions } from "@material-ui/lab"
import { DatePicker } from "@material-ui/pickers"
import { TagNested } from "api/generated/schedule"
import {
  BookingCreate,
  BookingRead,
  bookingsApi,
  BookingType,
  BookingUpdate,
  User,
  UserRoles,
  useTags,
} from "api/scheduleAPI"
import { primaryColor } from "app/withRoot"
import { LoaderButton } from "components/Form/LoaderButton"
import ReactHookFormSelect from "components/Form/ReactHookFormSelect"
import ReactHookTimeSelect from "components/Form/ReactHookTimeSelect"
import { UpdateBooking, useBookingContext } from "context/bookingContext"
import RenderOnRole from "features/auth/RenderOnRole"
import { getResourceEnd, getResourceStart } from "features/booking/utils/BookingSlotHandler"
import { IntervalLength } from "features/booking/utils/BookingTypes"
import { UserTag } from "features/user"
import { UserTags } from "features/userTags/UserTags"
import { DateTime } from "luxon"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"
import { useMutation, useQueryClient } from "react-query"
import { BookingDialogProps } from "../BookingsPage"
import ModifyRecurringDialog from "./ModifyRecurringDialog"
import RadioButtonGroup from "./RadioButtonGroup"
import RecurrencyPicker, { getRRule, Recurrency, recurrencyFromString } from "./RecurrencyPicker"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    deleteButton: {
      backgroundColor: "#f44336",
      color: "white",
      margin: 10,
      "&:hover": {
        backgroundColor: "white",
        color: "#f44336",
      },
    },
    submitButton: {
      backgroundColor: "white",
      color: primaryColor,
      "&:hover": {
        backgroundColor: "#4caf50",
        color: "white",
      },
      margin: 10,
    },
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }),
)
const filterOptions = createFilterOptions<User>({
  matchFrom: "start",
  stringify: (option) => option.completeName,
})

type BookingInput = {
  title: string
  notes: string
  booking_type: BookingType
  bookingDay: DateTime
  bookingStartTime: number
  bookingEndTime: number
  ressource_id: number
  players: User[]
  recurrency: Recurrency
  tags: Array<TagNested>
}

type BookingProblem = {
  reason: string
  error_code: string
  user_ids: number[]
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})
export const BookingsDialog = (props: BookingDialogProps) => {
  const intl = useIntl()
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [showDeleteRecurringDialog, setShowDeleteRecurringDialog] = useState(false)
  const [showUpdateRecurringDialog, setShowUpdateRecurringDialog] = useState(false)
  const [processingSubmission, setProcessingSubmission] = useState("")
  const bookingContext = useBookingContext()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { data: tags } = useTags()
  const {
    register,
    control,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<BookingInput>()
  const { ref: refTitle, ...restTitle } = register("title")
  const { ref: refNotes, ...restNotes } = register("notes")

  const sortedUsers = [...props.users]
  sortedUsers.sort((a, b) => -b.lastname.localeCompare(a.lastname))

  const [problems, setProblems] = useState<BookingProblem[]>([])

  const close = () => {
    setProblems([])
    reset()
    props.close()
  }

  const updateMutation = useMutation(
    (booking: BookingUpdate) => bookingsApi.updateBooking(booking.id ? booking.id : -1, booking),
    {
      onSuccess: () => {
        enqueueSnackbar("Booking updated.", {
          variant: "success",
        })
        const day = bookingContext.booking?.startDateTime
        if (day) {
          const startString = day.startOf("week").startOf("day").toUTC(0).toISO()
          const endString = day.endOf("week").endOf("day").toUTC(0).toISO()
          queryClient.invalidateQueries("bookings_" + startString + endString)
        }
        bookingContext.cancelBooking()
        close()
      },
      onError: (error: any) => {
        const errorMsgs = error.message
        enqueueSnackbar(`Something went wrong: ${errorMsgs}`, {
          variant: "error",
        })
        const problems = error.response?.data
        if (problems["error_code"] == "booking_problem") {
          const myproblems: BookingProblem[] = problems["detail"]
          setProblems(myproblems)
        }
      },
      onSettled: () => {
        setProcessingSubmission("")
      },
    },
  )

  const deleteMutation = useMutation(
    (booking: BookingRead) => bookingsApi.deleteBooking(booking.id, booking.start === "" ? undefined : booking.start),
    {
      onSuccess: () => {
        enqueueSnackbar("Booking deleted.", {
          variant: "success",
        })
        const day = bookingContext.booking?.startDateTime
        if (day) {
          const startString = day.startOf("week").startOf("day").toUTC(0).toISO()
          const endString = day.endOf("week").endOf("day").toUTC(0).toISO()
          queryClient.invalidateQueries("bookings_" + startString + endString)
        }
        bookingContext.cancelBooking()
        close()
      },
      onError: (error: Error) => {
        const errorMsgs = error.message
        enqueueSnackbar(`Something went wrong: ${errorMsgs}`, {
          variant: "error",
        })
      },
      onSettled: () => {
        setProcessingSubmission("")
      },
    },
  )

  const createMutation = useMutation((values: BookingCreate) => bookingsApi.createBooking(values), {
    onSuccess: () => {
      enqueueSnackbar(
        "Booking ".concat(bookingContext.booking?.booking_type === BookingType.Request ? " requested." : " created."),
        {
          variant: "success",
        },
      )
      const day = bookingContext.booking?.startDateTime
      if (day) {
        const startString = day.startOf("week").startOf("day").toUTC(0).toISO()
        const endString = day.endOf("week").endOf("day").toUTC(0).toISO()
        queryClient.invalidateQueries("bookings_" + startString + endString)
        queryClient.invalidateQueries("booking_requests_".concat(props.user.id.toString()))
      }
      bookingContext.cancelBooking()
      close()
    },
    onError: (error: any) => {
      const errorMsgs = error
      enqueueSnackbar(`Something went wrong: ${errorMsgs}`, {
        variant: "error",
      })
      const problems = error.response?.data
      if (problems["error_code"] == "booking_problem") {
        const myproblems: BookingProblem[] = problems["detail"]
        setProblems(myproblems)
      }
    },
    onSettled: () => {
      setProcessingSubmission("")
    },
  })
  const onDelete = () => {
    if (bookingContext.booking?.id) {
      if (bookingContext.booking.rrule) {
        setShowDeleteRecurringDialog(true)
      } else {
        setProcessingSubmission("delete")
        deleteMutation.mutate(bookingContext.booking)
      }
    }
  }
  const onDeleteRecurring = (series: boolean) => {
    if (bookingContext.booking?.id) {
      if (series) {
        deleteMutation.mutate({
          ...bookingContext.booking,
          start: "",
        })
      } else {
        deleteMutation.mutate(bookingContext.booking)
      }
    }
    setShowDeleteRecurringDialog(false)
  }
  const onUpdateRecurring = (series: boolean) => {
    if (bookingContext.booking?.id) {
      if (series) {
        handleSubmit(onSubmit)()
      } else {
        deleteMutation.mutate(bookingContext.booking)
        setValue("recurrency", {
          recurrency: "None",
          endDate: bookingContext.booking.startDateTime.plus({ days: 1 }),
          repetition: "date",
          interval: 7,
          skipWeekends: false,
        })
        handleSubmit(onSubmit)()
      }
    }
    setShowUpdateRecurringDialog(false)
  }
  const { bookingStartTime, bookingEndTime, booking_type, recurrency } = watch()
  useEffect(() => {
    if (bookingEndTime - bookingStartTime < props.settings.booking.min_duration_minutes + 1) {
      setValue(
        "bookingEndTime",
        bookingStartTime +
          (props.settings.booking.min_duration_minutes > 0
            ? props.settings.booking.min_duration_minutes
            : props.settings.time_granularity_minutes),
      )
    }
    if (
      props.user.role !== UserRoles.Admin &&
      props.settings.booking.max_duration_minutes > 0 &&
      bookingEndTime - bookingStartTime > props.settings.booking.max_duration_minutes
    ) {
      setValue(
        "bookingEndTime",
        bookingStartTime +
          (props.settings.booking.max_duration_minutes > 0
            ? props.settings.booking.max_duration_minutes
            : props.settings.time_granularity_minutes),
      )
    }
  }, [bookingStartTime, bookingEndTime])
  if (!props.open) {
    return null
  }
  if (bookingContext.booking === undefined) {
    return null
  }
  const selectedResource = props.courts.find((court) => court.id === bookingContext.booking?.ressource_id)
  if (selectedResource === undefined) {
    return null
  }

  let availabilityStart = Math.max(
    getResourceStart(selectedResource, bookingContext.booking.startDateTime.weekday),
    props.settings.hours_of_availability.displayHours.start,
  )
  if (bookingContext.booking.startDateTime < DateTime.now()) {
    availabilityStart = DateTime.now().setToGranularityStart(props.settings.time_granularity_minutes).toMinutes()
  }
  const availabilityEnd = Math.min(
    getResourceEnd(selectedResource, bookingContext.booking.startDateTime.weekday),
    props.settings.hours_of_availability.displayHours.end,
  )
  const bookableTags = props.user.role === UserRoles.Admin ? undefined : props.user.tags
  const bookableResources = props.courts?.map((resource) => {
    if (resource.tags === undefined || resource.tags.length === 0 || bookableTags === undefined) {
      return resource.id
    } else {
      let bookable = true
      resource.tags.forEach((tag) => {
        if (!bookableTags.some((bt) => bt.id === tag.id)) {
          bookable = false
        }
      })
      if (bookable) {
        return resource.id
      } else {
        return -1
      }
    }
  })
  const fixedParticipants = [props.user.role === UserRoles.Admin ? null : props.user]
  const adminEditOnly = bookingContext.booking.endDateTime < DateTime.local() && props.user.role === UserRoles.Admin
  const canUserEdit =
    ((bookingContext.booking.owner_id === props.user.id ||
      bookingContext.booking.participants_ids?.some((id) => id === props.user.id)) &&
      bookingContext.booking.endDateTime > DateTime.local()) ||
    props.user.role === UserRoles.Admin
  const onSubmit = (data: BookingInput) => {
    setProcessingSubmission("submit")
    const startTime = data.bookingDay.setMinutes(data.bookingStartTime)
    const endTime = data.bookingDay.setMinutes(data.bookingEndTime)
    const update = bookingContext.updateBooking({
      ...data,
      startDateTime: startTime,
      endDateTime: endTime,
      booking_type:
        props.user.role === UserRoles.Admin || bookableResources.some((r) => r === selectedResource.id)
          ? data.booking_type
          : BookingType.Request,
      participants_ids: data.players.map((user) => user.id),
    } as UpdateBooking)
    if (update) {
      if (data.recurrency && data.recurrency.recurrency !== "None") {
        update.rrule = getRRule(data.recurrency, startTime).toString()
      }
      let updateElement = {
        id:
          data.recurrency && data.recurrency.recurrency === "None" && update.rrule && update.rrule !== ""
            ? undefined
            : update.id,
        title: update.title,
        booking_type: update.booking_type,
        ressource_id: update.ressource_id,
        start: update.startDateTime.toUTC(0).toISO(),
        end: update.endDateTime.toUTC(0).toISO(),
        notes: update.notes,
        tags: update.tags
          ? update.tags.map((tag) => {
              return { id: tag.id }
            })
          : undefined,
        rrule:
          data.recurrency && data.recurrency.recurrency === "None" && update.rrule && update.rrule !== ""
            ? undefined
            : update.rrule,
        participants_ids: update.participants_ids,
      }
      if (updateElement.id !== undefined) {
        updateMutation.mutate({ ...updateElement, id: update.id })
      } else {
        createMutation.mutate(updateElement)
      }
    }
  }
  return (
    <div>
      <Dialog
        disableEnforceFocus
        fullScreen
        open={props.open}
        onClose={props.onClose}
        TransitionComponent={Transition}
        disableEscapeKeyDown
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                bookingContext.cancelBooking()
                close()
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {bookingContext.booking.id ? (
              adminEditOnly ? (
                <React.Fragment>
                  <Typography variant="h6" className={classes.title}>
                    {bookingContext.booking.title && bookingContext.booking.title.trim() !== "" ? (
                      <FormattedMessage
                        id="bookingDialog.EditTitle"
                        description="The edit booking dialog title"
                        defaultMessage="Edit {title}"
                        values={{
                          title: bookingContext.booking.title,
                        }}
                      />
                    ) : (
                      <FormattedMessage
                        id="bookingDialog.EditTitle.NoTitle"
                        description="The edit booking dialogif no title title"
                        defaultMessage="Edit booking"
                        values={{
                          title: bookingContext.booking.title,
                        }}
                      />
                    )}
                  </Typography>
                  <LoaderButton
                    className={classes.deleteButton}
                    onClick={onDelete}
                    variant="contained"
                    loading={processingSubmission === "delete"}
                    success={false}
                    startIcon={<DeleteIcon />}
                  >
                    <FormattedMessage
                      id="bookingDialog.DeleteBooking"
                      description="Delete booking button"
                      defaultMessage="Delete booking"
                    />
                  </LoaderButton>
                  <LoaderButton
                    loading={processingSubmission === "submit"}
                    success={false}
                    className={classes.submitButton}
                    onClick={() => {
                      if (recurrency && recurrency.recurrency !== "None" && bookingContext.booking?.id) {
                        setShowUpdateRecurringDialog(true)
                      } else {
                        handleSubmit(onSubmit)()
                      }
                    }}
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    {bookingContext.booking.id ? (
                      <FormattedMessage
                        id="bookingDialog.UpdateBooking"
                        description="Update booking button"
                        defaultMessage="Update Booking"
                      />
                    ) : (
                      <FormattedMessage
                        id="bookingDialog.SubmitBooking"
                        description="Submit booking button"
                        defaultMessage="Submit Booking"
                      />
                    )}
                  </LoaderButton>
                </React.Fragment>
              ) : canUserEdit ? (
                <React.Fragment>
                  <Typography variant="h6" className={classes.title}>
                    {bookingContext.booking.title && bookingContext.booking.title.trim() !== "" ? (
                      <FormattedMessage
                        id="bookingDialog.EditTitle"
                        description="The edit booking dialog title"
                        defaultMessage="Edit {title}"
                        values={{
                          title: bookingContext.booking.title,
                        }}
                      />
                    ) : (
                      <FormattedMessage
                        id="bookingDialog.EditTitle.NoTitle"
                        description="The edit booking dialogif no title title"
                        defaultMessage="Edit booking"
                        values={{
                          title: bookingContext.booking.title,
                        }}
                      />
                    )}
                  </Typography>
                  <LoaderButton
                    className={classes.deleteButton}
                    onClick={onDelete}
                    loading={processingSubmission === "delete"}
                    success={false}
                    variant="contained"
                    startIcon={<DeleteIcon />}
                  >
                    <FormattedMessage
                      id="bookingDialog.DeleteBooking"
                      description="Delete booking button"
                      defaultMessage="Delete booking"
                    />
                  </LoaderButton>
                  <LoaderButton
                    loading={processingSubmission === "submit"}
                    success={false}
                    className={classes.submitButton}
                    onClick={() => {
                      if (recurrency && recurrency.recurrency !== "None" && bookingContext.booking?.id) {
                        setShowUpdateRecurringDialog(true)
                      } else {
                        handleSubmit(onSubmit)()
                      }
                    }}
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    {bookingContext.booking.id ? (
                      <FormattedMessage
                        id="bookingDialog.UpdateBooking"
                        description="Update booking button"
                        defaultMessage="Update Booking"
                      />
                    ) : (
                      <FormattedMessage
                        id="bookingDialog.SubmitBooking"
                        description="Submit booking button"
                        defaultMessage="Submit Booking"
                      />
                    )}
                  </LoaderButton>
                </React.Fragment>
              ) : (
                <Typography variant="h6" className={classes.title}>
                  {bookingContext.booking.title}
                </Typography>
              )
            ) : (
              <React.Fragment>
                <Typography variant="h6" className={classes.title}>
                  {bookableResources.some((r) => r === selectedResource.id) ? (
                    <FormattedMessage
                      id="bookingDialog.NewTitle"
                      description="The new booking dialog title"
                      defaultMessage="New Booking"
                    />
                  ) : (
                    <FormattedMessage
                      id="bookingDialog.RequestNewTitle"
                      description="The request new booking dialog title"
                      defaultMessage="Request Booking"
                    />
                  )}
                </Typography>
                <LoaderButton
                  loading={processingSubmission === "submit"}
                  success={false}
                  className={classes.submitButton}
                  onClick={() => {
                    if (recurrency && recurrency.recurrency !== "None" && bookingContext.booking?.id) {
                      setShowUpdateRecurringDialog(true)
                    } else {
                      handleSubmit(onSubmit)()
                    }
                  }}
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  {bookingContext.booking.id ? (
                    <FormattedMessage
                      id="bookingDialog.UpdateBooking"
                      description="Update booking button"
                      defaultMessage="Update Booking"
                    />
                  ) : (
                    <FormattedMessage
                      id="bookingDialog.SubmitBooking"
                      description="Submit booking button"
                      defaultMessage="Submit Booking"
                    />
                  )}
                </LoaderButton>
              </React.Fragment>
            )}
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Container component="main" disableGutters maxWidth="md">
            <RenderOnRole roles={[UserRoles.Admin]}>
              <TextField
                label={intl.formatMessage({
                  id: "bookingDialog.Form.Title",
                  description: "Titel label",
                  defaultMessage: "Title",
                })}
                {...restTitle}
                inputRef={refTitle}
                defaultValue={bookingContext.booking.title}
                disabled={!canUserEdit && !adminEditOnly}
                error={errors?.title !== undefined}
              />
            </RenderOnRole>
            <DialogContentText>
              <FormattedMessage
                id="bookingDialog.Form.Title.Details"
                description="Form Details Title"
                defaultMessage="Details"
              />
            </DialogContentText>
            <Grid container direction="row" alignItems="center" spacing={2}>
              {problems.length > 0 ? (
                <Grid item xs={12}>
                  <Box>
                    <Alert severity="warning">
                      <Typography variant="subtitle2" display="block">
                        Problems:
                      </Typography>
                      <ul>
                        {problems.map((problem) => {
                          return (
                            <li>
                              <Typography variant="overline" display="block">
                                {problem.reason}{" "}
                                {problem?.user_ids?.map((id) => {
                                  const user = sortedUsers.find((user) => user.id === id)
                                  return <span>| {user?.completeName}</span>
                                })}
                              </Typography>
                            </li>
                          )
                        })}
                      </ul>
                    </Alert>
                  </Box>
                </Grid>
              ) : null}
              <Grid item xs={12} sm={3}>
                <Controller
                  name="bookingDay"
                  control={control}
                  defaultValue={bookingContext.booking.startDateTime}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      disablePast
                      disabled={!canUserEdit && !adminEditOnly}
                      label={intl.formatMessage({
                        id: "bookingDialog.Form.Date",
                        description: "Date label",
                        defaultMessage: "Date",
                      })}
                      open={showDatePicker}
                      inputFormat="dd/MM/yyyy"
                      renderInput={(dateProps) => (
                        <TextField
                          {...dateProps}
                          onClick={(event: React.MouseEvent) => setShowDatePicker(!showDatePicker)}
                        />
                      )}
                      value={value}
                      onChange={(date) => {
                        if (date) {
                          onChange(date)
                          setShowDatePicker(false)
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={3} xs={5}>
                {props.settings ? (
                  <ReactHookTimeSelect
                    disabled={!canUserEdit && !adminEditOnly}
                    control={control}
                    label={intl.formatMessage({
                      id: "bookingDialog.Form.StartTime",
                      description: "StartTime label",
                      defaultMessage: "Start Time",
                    })}
                    name="bookingStartTime"
                    required
                    defaultValue={bookingContext.booking.startDateTime.toMinutes()}
                    startMinute={availabilityStart}
                    endMinute={availabilityEnd}
                    granularity={props.settings.time_granularity_minutes as IntervalLength}
                  />
                ) : null}
              </Grid>
              <Grid item sm={1} xs={2}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography> - </Typography>
                </Box>
              </Grid>
              <Grid item sm={3} xs={5}>
                {props.settings ? (
                  <ReactHookTimeSelect
                    control={control}
                    label={intl.formatMessage({
                      id: "bookingDialog.Form.EndTime",
                      description: "EndTime label",
                      defaultMessage: "End Time",
                    })}
                    name="bookingEndTime"
                    disabled={!canUserEdit && !adminEditOnly}
                    required
                    defaultValue={bookingContext.booking.endDateTime.toMinutes()}
                    startMinute={
                      (bookingStartTime ? bookingStartTime : bookingContext.booking.startDateTime.toMinutes()) +
                      (props.settings.booking.min_duration_minutes > 0
                        ? props.settings.booking.min_duration_minutes
                        : 0)
                    }
                    endMinute={
                      props.user.role === UserRoles.Admin
                        ? availabilityEnd
                        : bookingStartTime
                        ? Math.min(
                            availabilityEnd,
                            bookingStartTime +
                              (props.settings.booking.max_duration_minutes > 0
                                ? props.settings.booking.max_duration_minutes
                                : availabilityEnd),
                          )
                        : Math.min(
                            bookingContext.booking.startDateTime.toMinutes() +
                              (props.settings.booking.max_duration_minutes > 0
                                ? props.settings.booking.max_duration_minutes
                                : availabilityEnd),
                            availabilityEnd,
                          )
                    }
                    granularity={props.settings.time_granularity_minutes as IntervalLength}
                  />
                ) : null}
              </Grid>
              <Grid item sm={2} xs={12}>
                <ReactHookFormSelect
                  control={control}
                  disabled={!canUserEdit && !adminEditOnly}
                  label={intl.formatMessage({
                    id: "bookingDialog.Form.Court",
                    description: "Court label",
                    defaultMessage: "Court",
                  })}
                  name="ressource_id"
                  defaultValue={bookingContext.booking.ressource_id}
                  required
                >
                  {props.courts.map((court, idx) => {
                    return (
                      <MenuItem key={"ressource_id" + idx} value={court.id}>
                        {court.name}
                      </MenuItem>
                    )
                  })}
                </ReactHookFormSelect>
              </Grid>
            </Grid>
            <RenderOnRole roles={[UserRoles.Admin]}>
              <DialogContentText>
                <FormattedMessage
                  id="bookingDialog.Form.Title.BookingType"
                  description="Form Booking Type Title"
                  defaultMessage="Booking Type"
                />
              </DialogContentText>
              <Controller
                name="booking_type"
                control={control}
                defaultValue={bookingContext.booking.booking_type}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <RadioButtonGroup setBookingType={(e) => onChange(e)} bookingType={value} />
                )}
              />

              {[BookingType.Owner, BookingType.Blocked].indexOf(
                booking_type ? booking_type : bookingContext.booking.booking_type,
              ) > -1 ? (
                <React.Fragment>
                  <Controller
                    name="recurrency"
                    control={control}
                    defaultValue={
                      bookingContext.booking?.rrule
                        ? recurrencyFromString(bookingContext.booking.rrule)
                        : {
                            recurrency: "None",
                            endDate: bookingContext.booking.startDateTime.plus({
                              days: 1,
                            }),
                            repetition: "date",
                            interval: 7,
                            skipWeekends: false,
                          }
                    }
                    render={({ field: { onChange, value } }) => (
                      <RecurrencyPicker
                        disabled={!canUserEdit && !adminEditOnly}
                        recurrency={value}
                        setRecurrency={(type: Recurrency) => onChange(type)}
                        minDate={
                          bookingContext.booking
                            ? bookingContext.booking.startDateTime.plus({ days: 1 })
                            : DateTime.local()
                        }
                        label={intl.formatMessage({
                          id: "bookingDialog.RecurrencyPicker",
                          description: "Recurring event question",
                          defaultMessage: "Recurring Event?",
                        })}
                        recurrencyOptions={["None", "Daily", "Weekly", "Monthly", "Yearly"]}
                      />
                    )}
                  />
                  <UserTags
                    defaultValues={tags?.filter((tag) =>
                      bookingContext.booking?.tags && bookingContext.booking?.tags.length > 0
                        ? bookingContext.booking?.tags[0].id === tag.id
                        : false,
                    )}
                    single={true}
                    control={control}
                    tagOptions={tags}
                  />
                </React.Fragment>
              ) : null}
            </RenderOnRole>
            <DialogContentText>
              <FormattedMessage
                id="bookingDialog.Form.Title.Players"
                description="Form Players Title"
                defaultMessage="Players"
              />
            </DialogContentText>
            <Controller
              name="players"
              control={control}
              defaultValue={
                bookingContext.booking.participants_ids
                  ? bookingContext.booking.participants_ids.map((id) => {
                      return sortedUsers.find((user) => user.id === id)
                    }) ?? [props.user]
                  : [props.user]
              }
              rules={{
                required: true,
                validate: (value) => {
                  if (props.user.role !== UserRoles.Admin) {
                    return value.length >= 2
                  }
                  return value.length >= 1
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  disabled={!canUserEdit && !adminEditOnly}
                  options={sortedUsers}
                  multiple
                  value={value ? value : []}
                  filterOptions={filterOptions}
                  onChange={(event, data) => {
                    onChange(data)
                  }}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option: User) => option.lastname.toUpperCase().concat(" ", option.firstname)}
                  renderTags={(tagValue: any, getTagProps: any) =>
                    tagValue.map((option: User, index: any) => {
                      return (
                        <UserTag
                          label={option.completeName}
                          {...getTagProps({ index })}
                          disabled={fixedParticipants.some((part) => part && part.id === option.id)}
                        />
                      )
                    })
                  }
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label={intl.formatMessage({
                        id: "bookingDialog.Form.Participants",
                        description: "Participants label",
                        defaultMessage: "Participants",
                      })}
                      required
                      helperText={intl.formatMessage({
                        id: "bookingDialog.Form.Participants.Helper",
                        description: "Participants helper label",
                        defaultMessage: "At least 2 participants",
                      })}
                      error={errors?.players}
                    />
                  )}
                />
              )}
            />
            <TextField
              multiline
              disabled={!canUserEdit && !adminEditOnly}
              rows={3}
              defaultValue={bookingContext.booking.notes}
              {...restNotes}
              inputRef={refNotes}
              label={intl.formatMessage({
                id: "bookingDialog.Form.Notes",
                description: "Notes label",
                defaultMessage: "Notes",
              })}
              placeholder={intl.formatMessage({
                id: "bookingDialog.Form.Notes.Placeholder",
                description: "Notes Placeholder label",
                defaultMessage: "Any further information",
              })}
              margin="normal"
              fullWidth
            />
          </Container>
        </DialogContent>
      </Dialog>
      <ModifyRecurringDialog
        title={intl.formatMessage({
          id: "bookingDialog.DeleteRecurringDialog.Title",
          description: "Delete entire recurring event?",
          defaultMessage: "Delete entire recurring event?",
        })}
        message={intl.formatMessage({
          id: "bookingDialog.DeleteRecurringDialog.Message",
          description: "Delete entire recurring event?",
          defaultMessage:
            "You are trying to delete a recurring event. \n Do you want to delete only the selected occurance, or the whole recurring series?",
        })}
        open={showDeleteRecurringDialog}
        cancelCallback={() => setShowDeleteRecurringDialog(false)}
        modifySeriesCallback={() => onDeleteRecurring(true)}
        modifySingleCallback={() => onDeleteRecurring(false)}
      />
      <ModifyRecurringDialog
        title={intl.formatMessage({
          id: "bookingDialog.UpdateRecurringDialog.Title",
          description: "Update entire recurring event?",
          defaultMessage: "Update entire recurring event?",
        })}
        message={intl.formatMessage({
          id: "bookingDialog.UpdateRecurringDialog.Message",
          description: "Update entire recurring event?",
          defaultMessage:
            "You are trying to update a recurring event. \n Do you want to update only the selected occurance, or the whole recurring series?",
        })}
        open={showUpdateRecurringDialog}
        cancelCallback={() => setShowUpdateRecurringDialog(false)}
        modifySeriesCallback={() => onUpdateRecurring(true)}
        modifySingleCallback={() => onUpdateRecurring(false)}
      />
    </div>
  )
}
