import { Tooltip } from "@material-ui/core"
import React from "react"

export type ConditionalToolTipProps = {
  children: JSX.Element[]
  renderToolTip: boolean
  toolTip: string
}
export const ConditionalToolTip = (props: ConditionalToolTipProps) => {
  return props.renderToolTip ? (
    <Tooltip title={props.toolTip}>
      <div>{props.children}</div>
    </Tooltip>
  ) : (
    <React.Fragment>{props.children}</React.Fragment>
  )
}
