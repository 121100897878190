import { Box, Tab, Tabs } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { RessourceRead } from "api/scheduleAPI"
import ResourceFrom from "features/settingsList/ResourceForm"
import React from "react"
import ResourceAddDialog from "./ResourceAddDialog"
const useStyles = makeStyles((theme) => ({
  resourcesWrapper: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: "12rem",
  },
}))

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pl={2} pt={1.5}>
          {children}
        </Box>
      )}
    </div>
  )
}

export type ResourcesListProps = {
  resources: RessourceRead[]
}

const ResourcesList: React.FC<ResourcesListProps> = ({ resources }) => {
  const classes = useStyles()
  const [spaceTab, setSpaceTab] = React.useState(0)
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSpaceTab(newValue)
  }

  return (
    <>
      <div className={classes.resourcesWrapper}>
        <Tabs
          variant="scrollable"
          value={spaceTab}
          textColor="primary"
          orientation="vertical"
          onChange={handleTabChange}
          indicatorColor="primary"
          className={classes.tabs}
        >
          {resources.map((resource, index) => (
            <Tab label={resource.name} key={index} />
          ))}
        </Tabs>
        {resources.map((resource, index) => (
          <TabPanel value={spaceTab} index={index} key={index}>
            <Box width={3 / 4} minWidth={"20rem"}>
              <ResourceFrom resource={resource} />
            </Box>
          </TabPanel>
        ))}
      </div>
      <Box mt={1}>
        <ResourceAddDialog />
      </Box>
    </>
  )
}
export default ResourcesList
