import { makeStyles, Paper, Theme, useMediaQuery } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import { Alert } from "@material-ui/lab"
import { Ressource } from "api/scheduleAPI"
import { mobileBreakPoint } from "components/AppDrawer/AppMenuBar"
import { BookingViewProps } from "features/booking/views/BookingsPage"
import { DateTime, Interval } from "luxon"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { BookingColumn, BookingRow, BookingSlotTable } from "../utils/BookingSlotTable"
import { IntervalLength } from "../utils/BookingTypes"
import { drawerWidth } from "./slotviews/BookingStyles"
const createIntervalsFromTimespan = (
  start: DateTime,
  end: DateTime,
  stepSize: IntervalLength,
  nullMillis: boolean = false,
) => {
  if (nullMillis) {
    start = start.set({ millisecond: 0 })
    end = end.set({ millisecond: 0 })
  }
  const dailyInterval = Interval.fromDateTimes(start, end)
  const intervals = dailyInterval.splitBy({ minutes: stepSize })
  return intervals
}
type StyleProps = {
  scrollbarWidth: number
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    borderBottom: "0px solid #dee2e6",
    borderRight: "1px solid #dee2e6",
    position: "fixed",
    width: `92%`,
    [theme.breakpoints.up(mobileBreakPoint)]: {
      width: `calc(100% - ${drawerWidth}px - 30px)`,
    },
  },
  schedule: {
    maxHeight: "100vh",
    overflow: "auto",
    marginRight: (props: StyleProps) => `-${props.scrollbarWidth}px)`,
  },
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: theme.spacing(2, 2, 2),
  },
  ressource: {
    borderBottom: "1px solid #dee2e6",
  },
  toolbar: theme.mixins.toolbar,
}))
const isResourceClosed = (resource: Ressource, weekday: number, time: number) => {
  let open = false
  if (resource.availability[weekday].length === 0) {
    return false
  }
  resource.availability[weekday].forEach((duration) => {
    if (duration.start <= time && time <= duration.end) {
      open = true
    }
  })
  return !open
}
export const BookingsDayView = (props: BookingViewProps) => {
  const publishedResources = props.ressources.filter((res) => res.published)
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))
  const isMediumDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))
  const [displayedResources, setDisplayedResources] = useState({
    first: 0,
    count: Math.min(publishedResources.length, isSmallDevice ? 2 : isMediumDevice ? 4 : publishedResources.length),
  })
  const intl = useIntl()
  const classes = useStyles({
    scrollbarWidth: 0,
  })
  useEffect(() => {
    const displayedResourcesCount = Math.min(
      isSmallDevice ? 2 : isMediumDevice ? 4 : publishedResources.length,
      publishedResources.length,
    )
    setDisplayedResources({
      first: displayedResources.first,
      count: displayedResourcesCount,
    })
  }, [isSmallDevice, isMediumDevice])
  const columns = () => {
    const bookingColumns: BookingColumn[] = []
    if (displayedResources.count === publishedResources.length) {
      bookingColumns.push({ id: -1, label: "" })
    } else {
      bookingColumns.push({ id: -1, label: "", buttons: true })
    }
    for (let index = 0; index < displayedResources.count; index++) {
      let resourceID = index + displayedResources.first
      if (resourceID > publishedResources.length - 1) {
        resourceID -= publishedResources.length
      }
      const resource = publishedResources[resourceID]
      bookingColumns.push({
        id: resource.id,
        label: resource.name,
        tooltip: resource.description !== "" ? resource.description : "",
      })
    }
    return bookingColumns
  }
  const rows = () => {
    const bookingRows: BookingRow[] = []
    for (
      let time = props.settings.hours_of_availability.displayHours.start - props.settings.time_granularity_minutes;
      time < props.settings.hours_of_availability.displayHours.end;
      time += props.settings.time_granularity_minutes
    ) {
      const closed: boolean[] = []
      const resourceIDs: number[] = []
      for (let index = 0; index < displayedResources.count; index++) {
        let resourceID = index + displayedResources.first
        if (resourceID > publishedResources.length - 1) {
          resourceID -= publishedResources.length
        }
        const resource = publishedResources[resourceID]
        let isClosed = isResourceClosed(resource, props.date.weekday, time)
        isClosed =
          isClosed ||
          time < props.settings.hours_of_availability.displayHours.start ||
          time >= props.settings.hours_of_availability.displayHours.end
        closed.push(isClosed)
        resourceIDs.push(resource.id)
      }
      bookingRows.push({ time, closed, resourceIDs })
    }
    return bookingRows
  }
  const changeResourceGroupClickedHandler = (advance: boolean) => {
    if (advance) {
      if (displayedResources.first < publishedResources.length - 1) {
        setDisplayedResources({ ...displayedResources, first: displayedResources.first + 1 })
      } else {
        setDisplayedResources({ ...displayedResources, first: 0 })
      }
    } else {
      if (displayedResources.first > 0) {
        setDisplayedResources({ ...displayedResources, first: displayedResources.first - 1 })
      } else {
        setDisplayedResources({ ...displayedResources, first: publishedResources.length - 1 })
      }
    }
  }
  return (
    <Grid container className={classes.root}>
      <Grid className={classes.schedule} container alignItems="stretch" direction="row" justify="center">
        {publishedResources.length > 0 ? (
          BookingSlotTable({
            showDialogForExisting: props.openDialogForExisting,
            columns: columns(),
            rows: rows(),
            granularity: props.settings.time_granularity_minutes,
            slotCount: Math.ceil(
              (props.settings.hours_of_availability.displayHours.end -
                props.settings.hours_of_availability.displayHours.start) /
                props.settings.time_granularity_minutes,
            ),
            date: props.date,
            changeResourceHandler: changeResourceGroupClickedHandler,
          })
        ) : (
          <Paper className={classes.paper}>
            <Alert severity="warning">
              {intl.formatMessage({
                id: "dayView.EmptyCourts",
                description: "No Courts published label",
                defaultMessage: "No courts are published yet.\nCome back later to start booking!",
              })}
            </Alert>
          </Paper>
        )}
      </Grid>
    </Grid>
  )
}
