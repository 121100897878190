import { Paper } from "@material-ui/core"
import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import InputAdornment from "@material-ui/core/InputAdornment"
import Link from "@material-ui/core/Link"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined"
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined"
import Alert from "@material-ui/lab/Alert"
import { bookings, passwordreset } from "app/routes"
import { useAuth } from "features/auth/AuthContext"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { Link as RouterLink, useHistory } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(12),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(6, 6, 6),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export const LoginPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const { loginUser, isLoggedIn } = useAuth()
  const intl = useIntl()
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    loginUser.mutate({ username: username, password: password })
  }

  useEffect(() => {
    if (isLoggedIn()) {
      history.push(bookings)
    }
  }, [loginUser.isSuccess, isLoggedIn, history])

  return (
    <Container component="main" maxWidth="sm">
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {intl.formatMessage({
            id: "login.SignIn",
            description: "SignIn label",
            defaultMessage: "Sign In",
          })}
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            required
            onChange={(e) => setUsername(e.target.value)}
            value={username}
            id="username"
            label={intl.formatMessage({
              id: "login.Username",
              description: "Username label",
              defaultMessage: "Username",
            })}
            name="username"
            autoComplete="username"
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutlineOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            required
            name="password"
            label={intl.formatMessage({
              id: "login.Password",
              description: "Password label",
              defaultMessage: "Password",
            })}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            id="password"
            autoComplete="current-password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKeyOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          {loginUser.isError && (
            <Box pt={1}>
              <Alert severity="error">
                {intl.formatMessage({
                  id: "login.InvalidPassword",
                  description: "Invalid password message",
                  defaultMessage: "You have entered an invalid email or password",
                })}{" "}
              </Alert>
            </Box>
          )}
          <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
            {intl.formatMessage({
              id: "login.LoginButton",
              description: "Login Button",
              defaultMessage: "Log In",
            })}
          </Button>

          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} to={passwordreset.concat("/", username)} variant="body2">
                {intl.formatMessage({
                  id: "login.ForgotPassword",
                  description: "Forgot Password message",
                  defaultMessage: "Forgot your password?",
                })}
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {intl.formatMessage({
                  id: "login.NoAccount",
                  description: "No account message",
                  defaultMessage: "Don't have an account? Sign Up",
                })}
              </Link>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  )
}
