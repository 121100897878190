import { Box, IconButton, Typography } from "@material-ui/core"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp"
import { TagRead, User } from "api/scheduleAPI"
import { CustomTag, SystemTag, UserTag } from "features/user"
import React, { useState } from "react"

type CollapsableTagDisplayProps = {
  tags: TagRead[] | undefined
  users: User[] | undefined
  displayLimit: number
}

export const CollapsableTagDisplay = (props: CollapsableTagDisplayProps) => {
  const tagCount = props.tags ? props.tags.length : 0
  const userCount = props.users ? props.users.length : 0
  const totalCount = tagCount + userCount
  const [collapsed, setCollapsed] = useState(totalCount > props.displayLimit)
  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap" style={{ alignItems: "center" }}>
      {props.tags
        ?.slice(0, collapsed ? props.displayLimit : tagCount)
        .map((tag) => (tag?.type === 1 ? <CustomTag label={tag?.name} /> : <SystemTag label={tag?.name} />))}
      {props.users?.slice(0, collapsed ? props.displayLimit - tagCount : userCount).map((user) => (
        <UserTag label={user?.completeName} />
      ))}
      {totalCount > props.displayLimit && (
        <React.Fragment>
          {collapsed && <Typography>{"+".concat((totalCount - props.displayLimit).toString())}</Typography>}
          <IconButton
            onClick={() => {
              setCollapsed(!collapsed)
            }}
            color="primary"
          >
            {collapsed ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
          </IconButton>
        </React.Fragment>
      )}
    </Box>
  )
}
