import { Badge, Box, IconButton, LinearProgress, makeStyles, Theme, useMediaQuery } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import TextField from "@material-ui/core/TextField"
import { AddBox } from "@material-ui/icons"
import EventAvailableIcon from "@material-ui/icons/EventAvailable"
import { DatePicker } from "@material-ui/pickers"
import {
  Booking,
  BookingType,
  Ressource,
  Settings,
  useBookingRequests,
  useBookingsForDay,
  useMe,
  User,
  useRessources,
  UserRoles,
  useSettings,
  useUsers,
} from "api/scheduleAPI"
import { primaryColor } from "app/withRoot"
import AppMenuBar, { mobileBreakPoint } from "components/AppDrawer/AppMenuBar"
import { useBookingContext } from "context/bookingContext"
import RenderOnRole from "features/auth/RenderOnRole"
import { BookingsDayView } from "features/booking/views/BookingsDayView"
import { BookingsGridView } from "features/booking/views/BookingsGridView"
import { BookingsListView } from "features/booking/views/BookingsListView"
import { DateTime } from "luxon"
import React, { useCallback, useState } from "react"
import { FormattedMessage } from "react-intl"
import { Link, useLocation } from "react-router-dom"
import "../../DateTimeExtensions"
import BookingRequestsDialog from "./dialog/BookingRequestsDialog"
// import BookingSlotHandler from '../utils/BookingSlotHandler';
import { BookingsDialog } from "./dialog/BookingsDialog"
import { PreviousNextButtonPair } from "./PreviousNextButtonPair"

const useStyles = makeStyles((theme) => ({
  notifIcon: {
    color: "#fff",
    backgroundColor: "#2463ab",
    transition: "box-shadow .3s",
    "&:hover": {
      backgroundColor: "#2463ab",
      boxShadow: "0px 0px 8px 2px rgba(0, 0, 0, .3)",
      //border: "1px solid white"
    },
  },
  datepicker: {
    margin: "4px",
    border: "1px solid #e2e2e1",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "#fcfcfb",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}))

export type BookingViewProps = {
  date: DateTime
  user: User
  settings: Settings
  ressources: Ressource[]
  openDialogForExisting: Function
}

export type BookingDialogProps = {
  open: boolean
  close: Function
  onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined
  settings: Settings
  users: User[]
  user: User
  courts: Ressource[]
}
export const BookingsPage = () => {
  const classes = useStyles()
  const [openBookingRequests, setOpenBookingRequests] = useState(false)
  const { booking, createBooking, creatingBooking, cancelBooking, loadBooking } = useBookingContext()
  const [selectedDate, setSelectedDate] = useState<DateTime>(DateTime.local().set({ hour: 0, minute: 0, second: 0 }))
  const [showBookingDialog, setShowBookingDialog] = useState(false)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const searchParams = new URLSearchParams(useLocation().search)
  const { data: settings, isLoading: isLoadingSettings } = useSettings()
  const { data: users, isLoading: isLoadingUsers } = useUsers()
  const { data: user, isLoading: isLoadingUser } = useMe()
  const { data: ressources, isLoading: isLoadingRessources } = useRessources()
  const { isLoading: isLoadingBookings } = useBookingsForDay(selectedDate)
  const { data: bookingRequests } = useBookingRequests()
  const isLoading = isLoadingBookings || isLoadingRessources || isLoadingSettings || isLoadingUser || isLoadingUsers
  const view = searchParams.get("view")
  const isLargeDevice = useMediaQuery((theme: Theme) => theme.breakpoints.up(mobileBreakPoint))

  const bookableTags = user?.role === UserRoles.Admin ? undefined : user?.tags
  const bookableResources = ressources?.map((resource) => {
    if (resource.tags === undefined || bookableTags === undefined || resource.tags.length === 0) {
      return resource.id
    } else if (resource.tags.some((r) => bookableTags.some((b) => b.id === r.id))) {
      return resource.id
    } else {
      return -1
    }
  })
  const createBookingButtons = () => {
    if (creatingBooking) {
      return (
        <ButtonGroup
          style={{ marginLeft: "2rem" }}
          variant="contained"
          color="inherit"
          aria-label="contained primary button group"
        >
          <Button color={"primary"} onClick={() => setShowBookingDialog(true)}>
            {bookableResources?.some((r) => r === booking?.ressource_id) ? (
              <FormattedMessage id="createButton" defaultMessage="Create" description="Create button" />
            ) : (
              <FormattedMessage
                id="requestBookingButton"
                defaultMessage="Request"
                description="Request Booking button"
              />
            )}
          </Button>
          <Button
            style={{ color: primaryColor }}
            color={"default"}
            onClick={() => {
              cancelBooking()
            }}
          >
            <FormattedMessage id="cancelButton" defaultMessage="Cancel" description="Cancel button" />
          </Button>
        </ButtonGroup>
      )
    } else {
      return isLargeDevice ? (
        <React.Fragment>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "2rem" }}
            onClick={() => {
              if (!creatingBooking && ressources && settings) {
                createBooking({
                  resourceID: ressources[0].id,
                  time:
                    selectedDate >= DateTime.now()
                      ? selectedDate
                      : DateTime.now().setToGranularityStart(settings.time_granularity_minutes),
                  bookingType: BookingType.User,
                })
              }
              setShowBookingDialog(true)
            }}
            startIcon={<AddBox />}
          >
            <FormattedMessage id="newBookingButton" defaultMessage="New booking" description="New booking button" />
          </Button>
          <RenderOnRole roles={[UserRoles.Admin]}>
            <ButtonGroup
              style={{ marginLeft: "12px" }}
              variant="contained"
              color="primary"
              aria-label="contained primary button group"
            >
              <Button color={view === "day" ? "default" : "primary"} component={Link} to="/bookings?view=day">
                <FormattedMessage id="dayLayoutButton" defaultMessage="Calendar" description="Day Layout" />
              </Button>
              {/* <Button
            color={view === 'grid' ? 'default' : 'primary'}
            component={Link}
            to="/bookings?view=grid"
          >
            <FormattedMessage
              id="gridLayoutButton"
              defaultMessage="Grid"
              description="Grid Layout"
            />
          </Button> */}
              <Button color={view === "list" ? "default" : "primary"} component={Link} to="/bookings?view=list">
                <FormattedMessage id="lisLayoutButton" defaultMessage="Externals" description="List layout" />
              </Button>
            </ButtonGroup>
          </RenderOnRole>
        </React.Fragment>
      ) : (
        <AddBox
          color="primary"
          fontSize="large"
          style={{ marginLeft: "1rem" }}
          onClick={() => {
            if (!creatingBooking && ressources) {
              createBooking({
                resourceID: ressources[0].id,
                time: selectedDate,
                bookingType: BookingType.User,
              })
            }
            setShowBookingDialog(true)
          }}
        />
      )
    }
  }
  const onBookingDialogClose = (event: any, reason: string) => {
    closeBookingDialog()
  }
  const closeBookingDialog = () => {
    setShowBookingDialog(false)
  }
  const openBookingDialogForExistingBooking = useCallback(
    (existingBooking: Booking) => {
      if (!showBookingDialog) {
        loadBooking(existingBooking)
        setShowBookingDialog(true)
      }
    },
    [setShowBookingDialog, showBookingDialog],
  )
  if (isLoading) {
    return (
      <Box p={2}>
        <LinearProgress />
      </Box>
    )
  }
  return (
    <div>
      <RenderOnRole roles={[UserRoles.Admin]}>
        <BookingRequestsDialog
          users={users}
          bookings={bookingRequests}
          resources={ressources}
          open={openBookingRequests}
          setOpen={setOpenBookingRequests}
        />
      </RenderOnRole>
      <AppMenuBar useGradient={creatingBooking} userID={user?.id}>
        {(isLargeDevice || !creatingBooking) && (
          <React.Fragment>
            <PreviousNextButtonPair
              onClickPreviousHandler={() => setSelectedDate(selectedDate.minus({ days: 1 }))}
              onClickNextHandler={() => setSelectedDate(selectedDate.plus({ days: 1 }))}
            />
            <DatePicker
              className={classes.datepicker}
              open={showDatePicker}
              inputFormat="dd/MM/yyyy"
              renderInput={(props) => (
                <TextField
                  fullWidth={false}
                  margin="dense"
                  {...props}
                  className={classes.datepicker}
                  onClick={(event: React.MouseEvent) => setShowDatePicker(!showDatePicker)}
                />
              )}
              onAccept={(date) => {
                if (date && date !== selectedDate) {
                  setSelectedDate(date)
                }
                setShowDatePicker(false)
              }}
              onClose={() => {
                setShowDatePicker(false)
              }}
              value={selectedDate}
              onChange={(date) => {
                if (date && date !== selectedDate) {
                  setSelectedDate(date)
                }
                setShowDatePicker(false)
              }}
            />
          </React.Fragment>
        )}
        {createBookingButtons()}
        <RenderOnRole roles={[UserRoles.Admin]}>
          <Box flexGrow={1}></Box>
          <Box>
            <Badge
              overlap="circle"
              showZero={false}
              badgeContent={bookingRequests ? bookingRequests.length : 0}
              color="error"
            >
              <IconButton className={classes.notifIcon} onClick={() => setOpenBookingRequests(true)}>
                <EventAvailableIcon htmlColor="#fff" />
              </IconButton>
            </Badge>
          </Box>
        </RenderOnRole>
      </AppMenuBar>
      <div>
        {settings && ressources && user
          ? (() => {
              switch (view) {
                case "grid":
                  return <BookingsGridView />
                case "list":
                  return <BookingsListView />
                case "day":
                default:
                  return (
                    <BookingsDayView
                      date={selectedDate}
                      user={user}
                      settings={settings}
                      ressources={ressources}
                      openDialogForExisting={openBookingDialogForExistingBooking}
                    />
                  )
              }
            })()
          : null}
        {settings && users && user && ressources ? (
          <BookingsDialog
            open={showBookingDialog}
            close={closeBookingDialog}
            onClose={onBookingDialogClose}
            settings={settings}
            users={users}
            user={user}
            courts={ressources}
          />
        ) : null}
      </div>
    </div>
  )
}

// BookingsPage.whyDidYouRender = true;
