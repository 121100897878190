import {
  Box,
  Button,
  Divider,
  FormControl,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import AttachFileIcon from "@material-ui/icons/AttachFile"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import DeleteForeverIcon from "@material-ui/icons/DeleteForever"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import { Autocomplete } from "@material-ui/lab"
import { SendMailCreate, uploadApi, UserRoles, useTags, useUsers } from "api/scheduleAPI"
import { RichTextEditor } from "components/Form/RichTextEditor"
import PaperTitle from "components/paperTitle"
import "draft-js/dist/Draft.css"
import { useSnackbar } from "notistack"
import React, { ChangeEvent } from "react"
import { Controller, useForm } from "react-hook-form"
import { useIntl } from "react-intl"
import { useMutation, useQueryClient } from "react-query"

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(6),
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: theme.spacing(6, 6, 6),
  },
  deleteButton: {
    "&:hover": {
      color: "red",
    },
  },
}))

type MailFormProps = {
  mail: SendMailCreate
  title?: string
  subtitle?: string
  onFormSubmit: (values: SendMailCreate) => void
} & Partial<DefaultProps>

type DefaultProps = {
  title: "Mail"
  subtitle: "Convenient way to send a mail to multiple members or groups"
}

const icon = <CheckBoxOutlineBlankIcon />
const checkedIcon = <CheckBoxIcon />

type To = {
  id: number
  type: "user" | "group"
  label: string
}

type FromSubmitValues = {
  message: string
  subject: string
  to: To[]
}

function isUserType(to: To) {
  return to.type === "user"
}
function isGroupType(to: To) {
  return to.type === "group"
}

type Attachment = {
  file: File
  progress: number
  completed: boolean
}
export const MailForm = (props: MailFormProps) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const intl = useIntl()

  const { data: users, isSuccess: isUsersLoaded } = useUsers()
  const { data: tags, isSuccess: isTagsLoaded } = useTags()
  const [currentFiles, setCurrentFiles] = React.useState<Attachment[]>([])
  const [uploadedFiles, setUploadedFiles] = React.useState<string[]>([])
  const { register, control, handleSubmit } = useForm<SendMailCreate>()
  const { ref: refSubject, ...restSubject } = register('subject',{ required: true })
  const onUploadProgress = (progressEvent: ProgressEvent, file: File) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    setCurrentFiles((state) => {
      const attachment = state.find((files) => files.file === file)
      if (attachment) {
        return [
          ...state.filter((files) => files !== attachment),
          { ...attachment, progress: percentCompleted, completed: percentCompleted === 100 },
        ]
      } else {
        return [...state, { file: file, progress: percentCompleted, completed: percentCompleted === 100 }]
      }
    })
  }
  const fileUpload = (file: File) => {
    const uploadProgressConfig = {
      onUploadProgress: (progressEvent: ProgressEvent) => onUploadProgress(progressEvent, file),
    }
    // setCurrentFiles([...currentFiles, {file:file, progress: 0, completed: false }])
    return uploadApi.uploadFile(file, uploadProgressConfig)
  }
  const muUpload = useMutation((file: File) => fileUpload(file), {
    onSuccess: (response) => {
      enqueueSnackbar("File added.", { variant: "success" })
      setUploadedFiles([...uploadedFiles, response.data.id])
    },
    onError: () => {
      enqueueSnackbar("Failed to upload file.", { variant: "error" })
    },
    onSettled: () => {
      queryClient.invalidateQueries("ressources")
    },
  })

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist()
    const files = event.target.files
    if (files) {
      Array.from(files).forEach((file) => {
        if (file.size > 0) {
          muUpload.mutateAsync(file)
        }
      })
    }
  }

  const handleDeleteFile = (deletedFile: Attachment) => {
    setCurrentFiles(currentFiles.filter((file) => file !== deletedFile))
  }

  const onSubmit = (values: FromSubmitValues) => {
    const payload = {
      message: values.message,
      subject: values.subject,
      to_users: values.to.filter(isUserType).map((to) => to.id),
      to_tags: values.to.filter(isGroupType).map((to) => to.id),
      attachments: uploadedFiles,
    }
    props.onFormSubmit(payload)
  }

  if (!isUsersLoaded || !isTagsLoaded || tags === undefined || users === undefined) {
    return (
      <Box p={10}>
        <LinearProgress />
      </Box>
    )
  }

  const toOptions: To[] = []

  users
    .filter((user) => user.role !== UserRoles.External)
    .forEach((user) => toOptions.push({ id: user.id, type: "user", label: user.completeName }))
  tags.forEach((tag) => toOptions.push({ id: tag.id, type: "group", label: tag.name }))

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Paper className={classes.paper}>
        <PaperTitle
          title={intl.formatMessage({
            id: "mailForm.Title",
            description: "Titel label",
            defaultMessage: "Title",
          })}
          subtitle={intl.formatMessage({
            id: "mailForm.Subtitle",
            description: "Subtitel label",
            defaultMessage: "Convenient way to send a mail to multiple members or groups",
          })}
        />
        <Divider />
        <FormControl>
          <Controller
            name={"to"}
            defaultValue={toOptions.filter(
              (option) => option.type === "user" && props.mail.to_users.includes(option.id),
            )}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                disableClearable={true}
                disableCloseOnSelect
                options={toOptions}
                multiple
                limitTags={15}
                value={value ? value : []}
                onChange={(event, data) => {
                  onChange(data)
                }}
                getOptionSelected={(option, value) => {
                  console.log(option)
                  console.log(value)
                  return option.id === value.id && option.type === value.type
                }}
                getOptionLabel={(option) => option.label}
                renderOption={(option, { selected }) => (
                  <div>
                    {/* <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} /> */}
                    {option.label}
                  </div>
                )}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({
                      id: "mailForm.To.Label",
                      description: "To label",
                      defaultMessage: "To",
                    })}
                    id="to"
                    placeholder={intl.formatMessage({
                      id: "mailForm.To.Placeholder",
                      description: "To Placeholder",
                      defaultMessage: "To",
                    })}
                  />
                )}
              />
            )}
          />
        </FormControl>
        <TextField
          required
          placeholder={intl.formatMessage({
            id: "mailForm.Subject.Placeholder",
            description: "Subject placeholder",
            defaultMessage: "Add a subject",
          })}
          {...restSubject}
          inputRef={refSubject}
          id="subject"
          label={intl.formatMessage({
            id: "mailForm.Subject.Label",
            description: "Subject label",
            defaultMessage: "Subject",
          })}
          type="text"
        />
        {/* <Controller
          id="editor"
          name="editor"
          control={control}
          render={({ value, onChange }) => <Editor editorState={value} onChange={onChange} />}
        /> */}
        <Controller
          name={"message"}
          control={control}
          render={({ field: { onChange } }) => (
            <RichTextEditor
              onChange={(data: string) => {
                onChange(data)
              }}
            />
          )}
        />
        {/* <TextField
          required
          multiline
          rows={12}
          inputRef={register({ required: true })}
          id="message"
          label={intl.formatMessage({
            id: "mailForm.Message.Label",
            description: "Message Label",
            defaultMessage: "Message",
          })}
          name="message"
          type="text"
          placeholder={intl.formatMessage({
            id: "mailForm.Message.Placeholder",
            description: "Message placeholder",
            defaultMessage: "Your message...",
          })}
        /> */}
        <Box display="flex">
          <Box flexGrow={1}>
            <Button variant="contained" component="label" color="secondary" startIcon={<AttachFileIcon />}>
              {intl.formatMessage({
                id: "mailForm.Upload",
                description: "Upload button",
                defaultMessage: "Upload attachment(s)",
              })}
              <input type="file" hidden multiple onChange={handleFileSelect} />
            </Button>
          </Box>
          <Button type="submit" variant="contained" color="primary">
            {intl.formatMessage({
              id: "mailForm.Send",
              description: "Send button",
              defaultMessage: "Send",
            })}
          </Button>
        </Box>
        {currentFiles && (
          <Box pt={2}>
            <Typography variant="body1">Attached files: </Typography>
            <List>
              {currentFiles &&
                Array.from(currentFiles)
                  .sort()
                  .map((file) => {
                    return (
                      <React.Fragment>
                        <ListItem>
                          <ListItemIcon>
                            <FileCopyIcon />
                          </ListItemIcon>
                          <ListItemText primary={file.file.name} />
                          <ListItemIcon className={classes.deleteButton} onClick={() => handleDeleteFile(file)}>
                            <DeleteForeverIcon />
                          </ListItemIcon>
                        </ListItem>
                        {!file.completed && (
                          <Box display="flex" alignItems="center" marginLeft={2} marginRight={5}>
                            <Box width="100%" mr={1}>
                              <LinearProgress variant="determinate" value={file.progress} />
                            </Box>
                            <Box minWidth={35}>
                              <Typography variant="body2" color="textSecondary">{`${Math.round(
                                file.progress,
                              )}%`}</Typography>
                            </Box>
                          </Box>
                        )}
                      </React.Fragment>
                    )
                  })}
            </List>
          </Box>
        )}
      </Paper>
    </form>
  )
}
