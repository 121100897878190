import { Button, ButtonGroup, Divider, makeStyles, Paper } from "@material-ui/core"
import FormatBoldIcon from "@material-ui/icons/FormatBold"
import FormatColorTextIcon from "@material-ui/icons/FormatColorText"
import FormatItalicIcon from "@material-ui/icons/FormatItalic"
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted"
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered"
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined"
import { convertToHTML } from "draft-convert"
import { DraftEditorCommand, DraftInlineStyleType, DraftStyleMap, Editor, EditorState, RichUtils } from "draft-js"
import React, { useRef, useState } from "react"
import { GithubPicker } from "react-color"
import { useIntl } from "react-intl"
import { usePopper } from "react-popper"
import sanitizeHtml from "sanitize-html"

type RichTextEditorProps = {
  onChange?: Function
}

const useStyles = makeStyles((theme) => ({
  editorBasePaper: {
    padding: "2rem",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  selectedButton: {
    backgroundColor: "#9E9E9E",
    color: "#fff",
    "&:hover": {
      background: "#6E6E6E",
    },
  },
}))
const pickerColors = [
  "#000000",
  "#e2e2e2",
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#ffeb3b",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#607d8b",
]
const htmlConversionOpts = {
  styleToHTML: (style: DraftInlineStyleType) => {
    if (style.includes("COLOR-")) {
      return <span style={{ color: style.replace("COLOR-", "") }} />
    } else if (style === "BOLD") {
      return <b />
    } else if (style === "ITALIC") {
      return <i />
    } else if (style === "UNDERLINE") {
      return <u />
    } else return <p />
  },
}
export const RichTextEditor = (props: RichTextEditorProps) => {
  const classes = useStyles()
  const intl = useIntl()
  const referenceElement = useRef<HTMLDivElement>(null)
  const popperElement = useRef<HTMLDivElement>(null)
  const { styles, attributes } = usePopper(referenceElement.current, popperElement.current)
  const [showColorPicker, setShowColorPicker] = useState(false)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const styleMap = new Object() as DraftStyleMap
  pickerColors.forEach((color) => {
    const identifier = "COLOR-".concat(color)
    styleMap[identifier] = {
      color: color,
    }
  })
  const onChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState)
    if (props.onChange) {
      const html = convertToHTML(htmlConversionOpts)(editorState.getCurrentContent())
      const clean = sanitizeHtml(html, {
        allowedTags: ["b", "i", "u", "span", "p", "h1", "h2", "h3", "ul", "ol", "li"],
        allowedAttributes: {
          span: ["style"],
        },
      })
      props.onChange(clean)
    }
  }
  const handleKeyCommand = (command: DraftEditorCommand) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      onChange(newState)
      return "handled"
    }
    return "not-handled"
  }
  const handleUnderline = (event: React.MouseEvent) => {
    event.preventDefault()
    onChange(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"))
  }

  const handleBlockTpye = (event: React.MouseEvent, type: string) => {
    event.preventDefault()
    onChange(RichUtils.toggleBlockType(editorState, type))
  }
  const handleBold = (event: React.MouseEvent) => {
    event.preventDefault()
    onChange(RichUtils.toggleInlineStyle(editorState, "BOLD"))
  }

  const handleItalic = (event: React.MouseEvent) => {
    event.preventDefault()
    onChange(RichUtils.toggleInlineStyle(editorState, "ITALIC"))
  }
  const handleColor = (event: React.MouseEvent) => {
    event.preventDefault()
    setShowColorPicker(!showColorPicker)
  }
  const getStyledClass = (style: string) => {
    return editorState.getCurrentInlineStyle().has(style) ? classes.selectedButton : ""
  }
  const getBlockClass = (style: string) => {
    const blockType = RichUtils.getCurrentBlockType(editorState)
    return blockType === style ? classes.selectedButton : ""
  }
  const getStyledColor = () => {
    let appliedColor = "#000000"
    pickerColors.forEach((color) => {
      if (editorState.getCurrentInlineStyle().has("COLOR-".concat(color))) {
        appliedColor = color
      }
    })
    return appliedColor
  }
  return (
    <Paper className={classes.editorBasePaper} elevation={0} variant="outlined">
      <ButtonGroup size="small">
        <Button className={getBlockClass("header-one")} onMouseDown={(event) => handleBlockTpye(event, "header-one")}>
          H1
        </Button>
        <Button className={getBlockClass("header-two")} onMouseDown={(event) => handleBlockTpye(event, "header-two")}>
          H2
        </Button>
        <Button
          className={getBlockClass("header-three")}
          onMouseDown={(event) => handleBlockTpye(event, "header-three")}
        >
          H3
        </Button>
        <Button className={getStyledClass("BOLD")} startIcon={<FormatBoldIcon />} onMouseDown={handleBold}>
          {intl.formatMessage({
            id: "richTextEditor.Formatting.Bold",
            description: "Bold",
            defaultMessage: "Bold",
          })}
        </Button>
        <Button className={getStyledClass("ITALIC")} startIcon={<FormatItalicIcon />} onMouseDown={handleItalic}>
          {intl.formatMessage({
            id: "richTextEditor.Formatting.Italic",
            description: "Italic",
            defaultMessage: "Italic",
          })}
        </Button>
        <Button
          className={getStyledClass("UNDERLINE")}
          startIcon={<FormatUnderlinedIcon />}
          onMouseDown={handleUnderline}
        >
          {intl.formatMessage({
            id: "richTextEditor.Formatting.Underline",
            description: "Underline",
            defaultMessage: "Underline",
          })}
        </Button>
        <Button
          className={getBlockClass("unordered-list-item")}
          startIcon={<FormatListBulletedIcon />}
          onMouseDown={(event) => handleBlockTpye(event, "unordered-list-item")}
        />
        <Button
          className={getBlockClass("ordered-list-item")}
          startIcon={<FormatListNumberedIcon />}
          onMouseDown={(event) => handleBlockTpye(event, "ordered-list-item")}
        />
        <Button style={{ color: getStyledColor() }} startIcon={<FormatColorTextIcon />} onMouseDown={handleColor}>
          <div ref={referenceElement}>TextColor</div>
        </Button>
      </ButtonGroup>
      <div ref={popperElement} style={{ ...styles.popper, zIndex: 10 }} {...attributes.popper}>
        {showColorPicker && (
          <GithubPicker
            color={getStyledColor()}
            colors={pickerColors}
            onChangeComplete={(color) => {
              console.log(color)
              onChange(RichUtils.toggleInlineStyle(editorState, "COLOR-".concat(getStyledColor())))
              onChange(RichUtils.toggleInlineStyle(editorState, "COLOR-".concat(color.hex)))
              setShowColorPicker(false)
            }}
            triangle="top-left"
          />
        )}
      </div>

      <Divider style={{ marginTop: "1rem", marginBottom: "1rem" }} />
      <Editor
        customStyleMap={styleMap}
        onChange={onChange}
        editorState={editorState}
        handleKeyCommand={handleKeyCommand}
      />
    </Paper>
  )
}
