import { Typography, useMediaQuery } from "@material-ui/core"
import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles, Theme } from "@material-ui/core/styles"
import CalendarIcon from "@material-ui/icons/CalendarTodayTwoTone"
import ExitIcon from "@material-ui/icons/ExitToAppTwoTone"
import MailIcon from "@material-ui/icons/MailTwoTone"
import PeopleIcon from "@material-ui/icons/PeopleTwoTone"
import PersonIcon from "@material-ui/icons/PersonOutlineTwoTone"
import SettingsIcon from "@material-ui/icons/SettingsTwoTone"
import { UserRoles } from "api/scheduleAPI"
import { bookings, login, mails, profile, settings, users } from "app/routes"
import { useMobileMenu } from "context/MobileMenuContext"
import { IAuthContext, useAuth } from "features/auth/AuthContext"
import RenderOnRole from "features/auth/RenderOnRole"
import React from "react"
import { IntlShape, useIntl } from "react-intl"
import { Link, useHistory } from "react-router-dom"
import clubLogo from "../../app/logo.png"
import { mobileBreakPoint } from "./AppMenuBar"
const drawerWidth = 200

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    justifyContent: "space-between",
    backgroundColor: theme.palette.grey[200],
  },
  userAvatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginBottom: theme.spacing(1),
  },
}))

const drawerContent = (classes: any, auth: IAuthContext, intl: IntlShape, handleLogout: any) => {
  return (
    <React.Fragment>
      <Box>
        <Box display="flex" flexDirection="column" alignItems="center" mt={2} mb={2}>
          <Avatar alt="Avatar" className={classes.userAvatar}>
            {auth.user.firstname.charAt(0)}
            {auth.user.lastname.charAt(0)}
          </Avatar>
          <Typography>
            {auth.user.firstname} {auth.user.lastname}
          </Typography>
        </Box>
        <Divider />
        <List>
          <ListItem button key="bookings" component={Link} to={`${bookings}?view=day`}>
            <ListItemIcon>
              <CalendarIcon />
            </ListItemIcon>
            <ListItemText primary="Bookings" />
          </ListItem>
          <RenderOnRole roles={[UserRoles.Admin]}>
            <ListItem button key="users" component={Link} to={users}>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText
                primary={intl.formatMessage({
                  id: "appDrawer.Users",
                  description: "Users entry",
                  defaultMessage: "Users",
                })}
              />
            </ListItem>
          </RenderOnRole>
          <RenderOnRole roles={[UserRoles.Admin]}>
            <ListItem button key="settings" component={Link} to={settings}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                primary={intl.formatMessage({
                  id: "appDrawer.Settings",
                  description: "Settings Entry",
                  defaultMessage: "Settings",
                })}
              />
            </ListItem>
          </RenderOnRole>
          <RenderOnRole roles={[UserRoles.Admin]}>
            <ListItem button key="mail" component={Link} to={mails}>
              <ListItemIcon>
                <MailIcon />
              </ListItemIcon>
              <ListItemText
                primary={intl.formatMessage({
                  id: "appdrawer.Mail",
                  description: "Mail Entry",
                  defaultMessage: "Mail",
                })}
              />
            </ListItem>
          </RenderOnRole>
          <ListItem button key="profile" component={Link} to={profile}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText
              primary={intl.formatMessage({
                id: "appdrawer.Profile",
                description: "Profile Entry",
                defaultMessage: "Profile",
              })}
            />
          </ListItem>
        </List>
      </Box>
      <Box>
        <List>
          <ListItem>
            <Box width="100%" display="flex" flexDirection="column" alignItems="center" mt={2} mb={2}>
              <img width={drawerWidth - 60} src={clubLogo} alt="clublogo" />
            </Box>
          </ListItem>
          <ListItem button key="logout" onClick={handleLogout}>
            <ListItemIcon>
              <ExitIcon />
            </ListItemIcon>
            <ListItemText
              primary={intl.formatMessage({
                id: "appDrawer.Logout",
                description: "Logout Entry",
                defaultMessage: "Logout",
              })}
            />
          </ListItem>
        </List>
      </Box>
    </React.Fragment>
  )
}

const AppDrawer = () => {
  const isLargeDevice = useMediaQuery((theme: Theme) => theme.breakpoints.up(mobileBreakPoint))
  const classes = useStyles()
  const auth = useAuth()
  const history = useHistory()
  const { getMobileMenuOpen, setMobileMenuOpen } = useMobileMenu()

  const intl = useIntl()
  const handleLogout = async () => {
    await auth.logoutUser()
    history.push(login)
  }

  if (!auth.user) {
    return null
  }

  return (
    <React.Fragment>
      {!isLargeDevice ? (
        <Drawer
          className={classes.drawer}
          variant="temporary"
          anchor={"left"}
          open={getMobileMenuOpen()}
          onClose={() => setMobileMenuOpen(false)}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawerContent(classes, auth, intl, handleLogout)}
        </Drawer>
      ) : (
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
          open
        >
          {drawerContent(classes, auth, intl, handleLogout)}
        </Drawer>
      )}
    </React.Fragment>
  )
}

export default AppDrawer
