import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core"
import { AddBox } from "@material-ui/icons"
import { RessourceCreate, ressourcesApi } from "api/scheduleAPI"
import { useSnackbar } from "notistack"
import React, { useCallback, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQueryClient } from "react-query"

const initialValues: RessourceCreate = {
  name: "",
  description: "",
  published: true,
  tags: [],
}

const ResourceAddDialog: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const [isOpen, open] = useState(false)
  const toggle = useCallback(() => open(!isOpen), [open, isOpen])
  const { register, getValues } = useForm<RessourceCreate>()
  const { ref: refName, ...restName } = register('name',{ required: true })
  
  const muCreate = useMutation((values: RessourceCreate) => ressourcesApi.createRessource(values), {
    onError: () => {
      enqueueSnackbar("Error creating space.", { variant: "error" })
    },
    onSuccess: () => {
      enqueueSnackbar("Space successfully added.", { variant: "success" })
      toggle()
    },
    onSettled: () => {
      queryClient.invalidateQueries("ressources")
    },
  })

  const onSubmit = () => {
    muCreate.mutate({ ...initialValues, ...getValues() })
  }

  return (
    <div>
      <Button variant="contained" color="primary" onClick={toggle} startIcon={<AddBox />}>
        Add a space
      </Button>
      <Dialog open={isOpen} onClose={toggle} fullWidth>
        <DialogTitle id="form-dialog-title">New Space</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Space Name"
            {...restName}
            inputRef={refName}
            required
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggle} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onSubmit()} color="primary" variant="contained" startIcon={<AddBox />}>
            Add space
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default ResourceAddDialog
