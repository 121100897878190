import { Box, Button, Divider, Grid, MenuItem, Paper, TextField, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import SaveIcon from "@material-ui/icons/Save"
import { AppSettings, Ressource } from "api/scheduleAPI"
import ReactHookFormSelect from "components/Form/ReactHookFormSelect"
import ReactHookTimeSelect from "components/Form/ReactHookTimeSelect"
import PaperTitle from "components/paperTitle"
import ResourcesList from "features/settingsList/ResourcesList"
import { Duration } from "luxon"
import React from "react"
import { useForm } from "react-hook-form"
import AvailabilityRules from "./AvailabilityRules"

const timezones = ["Europe/Luxembourg"]

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    padding: theme.spacing(4, 4, 4),
  },
}))

export type SettingsFormProps = {
  settings: AppSettings
  resources: Ressource[]
  onSubmit: (settings: AppSettings) => void
}

const SettingsForm: React.FC<SettingsFormProps> = ({ settings, resources, onSubmit }) => {
  const classes = useStyles()
  const { register, control, handleSubmit, watch, formState:{errors} } = useForm<AppSettings>({
    defaultValues: settings,
  })
  const { ref: refName, ...restName } = register('name',{ required: true })
  
  const time_granularity_minutes: number = watch("time_granularity_minutes")

  function range(start: number, count: number) {
    return Array.apply(0, Array(count)).map((_, index) => index + start)
  }

  const durationBookingItems = () => {
    let items: JSX.Element[] = [
      <MenuItem key="duration_nolimit" value="-1">
        No Limit
      </MenuItem>,
    ]
    const maxItems = ~~(1440 / time_granularity_minutes)

    range(1, maxItems).forEach((number) => {
      const minutes = number * time_granularity_minutes
      const duration = Duration.fromObject({
        hours: 0,
        minutes: minutes,
      })
        .normalize()
        .toObject()

      let humanizeDuration = ""
      if (duration.hours && duration.hours > 0) {
        humanizeDuration += duration.hours + "h"
      }

      if (duration.minutes && duration.minutes > 0) {
        humanizeDuration += " " + duration.minutes + "min"
      }

      items.push(
        <MenuItem key={"duration_" + number} value={minutes + ""}>
          {humanizeDuration}
        </MenuItem>,
      )
    })
    return items
  }

  const concurrentBookingItems = () => {
    let items: JSX.Element[] = [
      <MenuItem key="concurrent_nolimit" value="-1">
        No Limit
      </MenuItem>,
    ]
    if (resources && resources.length > 0) {
      range(1, resources.length).forEach((number) =>
        items.push(
          <MenuItem key={"concurrent_" + number} value={number + ""}>
            {number}
          </MenuItem>,
        ),
      )
    }
    return items
  }

  const SaveButton = () => {
    return (
      <Box display="flex" justifyContent="flex-end">
        <Button type="submit" variant="contained" color="primary" startIcon={<SaveIcon />}>
          Save all changes
        </Button>
      </Box>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Paper className={classes.paper}>
        <PaperTitle title="Basics" />
        <Divider />
        <Box width={1 / 2} minWidth={"20rem"}>
          <TextField
            label="Venue Name"
            fullWidth
            margin="normal"
            {...restName}
            inputRef={refName}
            required
          />

          <ReactHookFormSelect control={control} label="Timezone" name="timezone" fullWidth margin="normal" required>
            {timezones.map((value, idx) => (
              <MenuItem key={"time_zone" + idx} value={value}>
                {value}
              </MenuItem>
            ))}
          </ReactHookFormSelect>

          <ReactHookFormSelect
            control={control}
            label="Time Granularity"
            name="time_granularity_minutes"
            fullWidth
            margin="normal"
            required
          >
            {[15, 20, 30, 60].map((value, idx) => (
              <MenuItem key={"time_granularity" + idx} value={value}>
                {value}
              </MenuItem>
            ))}
          </ReactHookFormSelect>
        </Box>
        <SaveButton />
      </Paper>
      <Paper className={classes.paper}>
        <PaperTitle title="Spaces" subtitle="Your bookable courts, rooms..." />
        <Divider />
        <Box pt={2}>
          <ResourcesList resources={resources} />
        </Box>
      </Paper>

      <Paper className={classes.paper}>
        <PaperTitle title="Hours of availability" subtitle="Your broad opening hours" />
        <Divider />
        <Box mt={1}>
          <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
            <Grid item>
              <Typography>Show the hours from</Typography>
            </Grid>
            <Grid item>
              <ReactHookTimeSelect
                control={control}
                label="Start time"
                name="hours_of_availability.displayHours.start"
                fullWidth
                margin="dense"
                required
                startMinute={0}
                endMinute={1440}
                granularity={60}
              />
            </Grid>
            <Grid item>
              <Typography>to</Typography>
            </Grid>
            <Grid item>
              <ReactHookTimeSelect
                control={control}
                label="End time"
                name="hours_of_availability.displayHours.end"
                fullWidth
                margin="dense"
                required
                startMinute={(watch("hours_of_availability.displayHours.start") as number) + 60}
                endMinute={1440}
                granularity={60}
              />
            </Grid>
            <Grid item>
              <Typography>on the scheduler time axis.</Typography>
            </Grid>
          </Grid>
        </Box>
        <Box mt={1} mb={1}>
          <AvailabilityRules resources={resources} control={control} name="hours_of_availability.rules" />
        </Box>
        <SaveButton />
      </Paper>
      <Paper className={classes.paper}>
        <PaperTitle title="Booking Rules" />
        <Divider />
        <Box width={1 / 2} minWidth={"20rem"}>
          <ReactHookFormSelect
            control={control}
            label="Minimal booking duration"
            name="booking.min_duration_minutes"
            fullWidth
            margin="normal"
            required
          >
            {durationBookingItems()}
          </ReactHookFormSelect>

          <ReactHookFormSelect
            control={control}
            label="Maximal booking duration"
            name="booking.max_duration_minutes"
            fullWidth
            margin="normal"
            required
          >
            {durationBookingItems()}
          </ReactHookFormSelect>

          <ReactHookFormSelect
            control={control}
            label="Maximal concurrent bookings"
            name="booking.concurrent"
            fullWidth
            margin="normal"
            required
          >
            {concurrentBookingItems()}
          </ReactHookFormSelect>
        </Box>
        <SaveButton />
      </Paper>
    </form>
  )
}

export default SettingsForm
