import { Paper } from "@material-ui/core"
import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import InputAdornment from "@material-ui/core/InputAdornment"
import Link from "@material-ui/core/Link"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined"
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined"
import Alert from "@material-ui/lab/Alert"
import { recoverPassword } from "api/scheduleAPI"
import { login } from "app/routes"
import { useSnackbar } from "notistack"
import React, { useState } from "react"
import { useIntl } from "react-intl"
import { useMutation } from "react-query"
import { Link as RouterLink, RouteComponentProps } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(12),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(6, 6, 6),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

type ResetPasswordProps = {
  renderProps?: RouteComponentProps
}
type ResetPasswordRenderProps = {
  username?: string
}
export const ResetPassword = (props: ResetPasswordProps) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const passedProps = props.renderProps?.match.params as ResetPasswordRenderProps
  const [username, setUsername] = useState(passedProps?.username ? passedProps.username : "")

  const mutation = useMutation((userName: string) => recoverPassword(userName), {
    onSuccess: () => {
      enqueueSnackbar("We have e-mailed you a password reset link!", {
        variant: "success",
      })
    },
    onError: () => {
      enqueueSnackbar("Error, failed to send you a password reset link!", {
        variant: "error",
      })
    },
  })
  const intl = useIntl()
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    mutation.mutate(username)
  }

  return (
    <Container component="main" maxWidth="sm">
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOpenOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {intl.formatMessage({
            id: "resetPassword.Reset",
            description: "Reset label",
            defaultMessage: "Reset your login",
          })}
        </Typography>
        <Box paddingTop={1}>
          <Typography variant="body1">
            {intl.formatMessage({
              id: "resetPassword.EnterUsername",
              description: "Enter username label",
              defaultMessage:
                "Enter the username associated with your account and we'll send you a link to reset your password.",
            })}
          </Typography>
        </Box>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            required
            onChange={(e) => setUsername(e.currentTarget.value)}
            value={username}
            id="username"
            label={intl.formatMessage({
              id: "login.Username",
              description: "Username label",
              defaultMessage: "Username",
            })}
            name="username"
            autoComplete="username"
            autoFocus
            disabled={mutation.isSuccess}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutlineOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          {mutation.isSuccess && (
            <Box pt={1}>
              <Alert severity="success">
                {intl.formatMessage({
                  id: "resetPassword.LinkSend",
                  description: "Password send label",
                  defaultMessage: "We have e-mailed your password reset link!",
                })}
              </Alert>
            </Box>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={mutation.isSuccess}
          >
            {intl.formatMessage({
              id: "resetPassword.RequestReset",
              description: "Password request button label",
              defaultMessage: "Send reset link",
            })}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} to={login} variant="body2">
                {intl.formatMessage({
                  id: "newPassword.ToLogin",
                  description: "To Login label",
                  defaultMessage: "Back to login",
                })}
              </Link>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  )
}
