import { Paper } from "@material-ui/core"
import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import InputAdornment from "@material-ui/core/InputAdornment"
import Link from "@material-ui/core/Link"
import { makeStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined"
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined"
import WarningIcon from "@material-ui/icons/Warning"
import { Alert } from "@material-ui/lab"
import { ExceptionModel } from "api/generated/schedule"
import { authApi } from "api/scheduleAPI"
import { login } from "app/routes"
import { AxiosError } from "axios"
import jwt_decode, { JwtPayload } from "jwt-decode"
import { useSnackbar } from "notistack"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useMutation } from "react-query"
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(12),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(6, 6, 6),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

type OurJwtPayload = JwtPayload & { id: string }

const parseJwt = (token: string) => {
  try {
    return jwt_decode<OurJwtPayload>(token)
  } catch (e) {
    return null
  }
}

export const NewPasswordPage = () => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [password, setPassword] = useState("")
  const [passwordConfirm, setPasswordConfirm] = useState("")
  const [token, setToken] = useState("")
  const [decodedToken, setDecodedToken] = useState<OurJwtPayload | null>()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    setToken(location.pathname.split("/").pop() || "")
    setDecodedToken(parseJwt(token))
  }, [token])

  const mutation = useMutation(() => authApi.resetPassword({ token: token, new_password: password }), {
    onSuccess: () => {
      enqueueSnackbar("Your account has a new password!", {
        variant: "success",
      })
      history.push(login)
    },
    onError: (error: AxiosError<ExceptionModel>) => {
      enqueueSnackbar(`Error: ${error.response?.data.detail}`, {
        variant: "error",
      })
    },
  })
  const intl = useIntl()
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    mutation.mutate()
  }

  const PasswordMatchHeperText = () => {
    if (!password && !passwordConfirm) {
      return null
    }
    if (password === passwordConfirm) {
      return (
        <Grid container direction="row" alignItems="center">
          <CheckCircleIcon fontSize="small" color="primary" />
          {intl.formatMessage({
            id: "newPassword.Match.Positive",
            description: "Passwords match label",
            defaultMessage: "Passwords match",
          })}
        </Grid>
      )
    }
    return (
      <Grid container direction="row" alignItems="center">
        <WarningIcon fontSize="small" />
        {intl.formatMessage({
          id: "newPassword.Match.Negative",
          description: "Passwords match label",
          defaultMessage: "Passwords do not match",
        })}
      </Grid>
    )
  }

  return (
    <Container component="main" maxWidth="sm">
      <Paper className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOpenOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {intl.formatMessage({
            id: "newPassword.NewPassword",
            description: "New password title label",
            defaultMessage: "Reset your password",
          })}
        </Typography>
        <Box paddingTop={1}>
          <Typography variant="body1">
            {intl.formatMessage({
              id: "newPassword.Instructions",
              description: "New password instructions label",
              defaultMessage: "Please enter and confirm your new password bellow to access your account.",
            })}
          </Typography>
        </Box>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          {decodedToken === null ? <Alert severity="error">Token not valid! Request a new one.</Alert> : null}
          <TextField label="Username" name="username" value={decodedToken?.sub} disabled id="username"></TextField>
          <TextField
            required
            name="password"
            label={intl.formatMessage({
              id: "login.Password",
              description: "Password label",
              defaultMessage: "Password",
            })}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            id="password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKeyOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            required
            name="passwordConfirm"
            label={intl.formatMessage({
              id: "newPassword.ConfirmPassword",
              description: "Confirm Password label",
              defaultMessage: "Confirm Password",
            })}
            type="password"
            onChange={(e) => setPasswordConfirm(e.target.value)}
            value={passwordConfirm}
            id="passwordConfirm"
            error={password !== passwordConfirm}
            helperText={<PasswordMatchHeperText />}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VpnKeyOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={decodedToken === null || password !== passwordConfirm || password === ""}
          >
            {intl.formatMessage({
              id: "newPassword.SetPassword",
              description: "Set password button label",
              defaultMessage: "Set password and log in",
            })}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component={RouterLink} to={login} variant="body2">
                {intl.formatMessage({
                  id: "newPassword.ToLogin",
                  description: "To Login label",
                  defaultMessage: "Back to login",
                })}
              </Link>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  )
}
