import { makeStyles } from "@material-ui/core/styles"
import AppDrawer from "components/AppDrawer/AppDrawer"
import React from "react"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}))

export const MainLayout: React.FC = ({ children }: any) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <AppDrawer />
      <main className={classes.content}>{children}</main>
    </div>
  )
}
