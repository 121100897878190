import { Box, Checkbox, FormControl, LinearProgress, TextField } from "@material-ui/core"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import { Autocomplete } from "@material-ui/lab"
import { TagNested, TagRead } from "api/generated/schedule"
import React from "react"
import { Controller } from "react-hook-form"

type UserTagsProps = {
  control: any
  single?: boolean
  defaultValues?: TagNested[]
  tagOptions?: TagRead[]
}
const icon = <CheckBoxOutlineBlankIcon />
const checkedIcon = <CheckBoxIcon />

export const UserTags = (props: UserTagsProps) => {
  if (props.tagOptions === undefined) {
    return (
      <Box pt={4} pb={4}>
        <LinearProgress />
      </Box>
    )
  }
  return (
    // contains a bug, if value is read, there is only the id. If value is set, we send the Tag object in the response and not just the id.u

    <FormControl style={{ width: "100%" }}>
      <Controller
        name="tags"
        defaultValue={props.defaultValues ? props.defaultValues : []}
        control={props.control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            disableClearable={props.single ? false : true}
            disableCloseOnSelect
            options={
              props.tagOptions
                ? props.tagOptions
                    .filter((tag) => tag.type === 1)
                    .map((tag) => {
                      return { id: tag.id } as TagNested
                    })
                : []
            }
            multiple
            value={
              value
                ? value.filter((tag: TagNested) => props.tagOptions?.find((option) => tag.id === option.id)?.type === 1)
                : []
            }
            onChange={(event, data) => {
              if (props.single) {
                onChange(data.filter((tag) => !value.includes(tag)))
              } else {
                onChange(data)
              }
            }}
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option: TagNested) => props.tagOptions?.find((tag) => tag.id === option.id)?.name || ""}
            renderOption={(option, { selected }) => (
              <>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {props.tagOptions?.find((tag) => tag.id === option.id)?.name}
              </>
            )}
            renderInput={(params: any) => <TextField {...params} label="Tags" id="tag" placeholder="Tags" />}
          />
        )}
      />
    </FormControl>
  )
}
