export const bookings = "/bookings"
export const profile = "/profile"
export const settings = "/settings"
export const users = "/users"
export const usersCreate = users + "/create"
export const usersEdit = users + "/:id"
export const login = "/account/login"
export const passwordreset = "/account/passwordreset"
export const passwordresetWithUsername = "/account/passwordreset/:username"
export const newpassword = "/account/newpassword"
export const mails = "/mails"
export const mailsCreate = mails + "/create"
export const mailsCreateWithTarget = mails + "/create/:target"
