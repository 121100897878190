import { AppBar, IconButton, makeStyles, Toolbar } from "@material-ui/core"
import { Menu } from "@material-ui/icons"
import { useMobileMenu } from "context/MobileMenuContext"
import { drawerWidth } from "features/booking/views/slotviews/BookingStyles"
import React from "react"
import { ColorGenerator } from "../../features/booking/utils/GradientGenerator"
export const mobileBreakPoint = "md"
const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up(mobileBreakPoint)]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  appBarGradient: {
    background: "#4B71AF", //(props: AppMenuBarProps) => ColorGenerator(props.userID ? props.userID : 1, "1"),
    border: 0,
    borderRadius: 3,
    boxShadow: (props: AppMenuBarProps) => "0 3px 5px 2px" + ColorGenerator(props.userID ? props.userID : 1, ".3"),
    color: "white",
    [theme.breakpoints.up(mobileBreakPoint)]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(mobileBreakPoint)]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
}))
export type AppMenuBarProps = {
  children: any[] | any
  userID: number | undefined
  useGradient: boolean
}

const defaultProps = {
  userID: undefined,
  useGradient: false,
}
const AppMenuBar = (props: AppMenuBarProps) => {
  const classes = useStyles(props)
  const { getMobileMenuOpen, setMobileMenuOpen } = useMobileMenu()

  return (
    <React.Fragment>
      <AppBar position="fixed" className={props.useGradient ? classes.appBarGradient : classes.appBar} color="inherit">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => setMobileMenuOpen(!getMobileMenuOpen())}
            className={classes.menuButton}
          >
            <Menu />
          </IconButton>
          {props.children}
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} />
    </React.Fragment>
  )
}

AppMenuBar.defaultProps = defaultProps
export default AppMenuBar
