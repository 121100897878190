import { DateTime } from "luxon"

declare module "luxon/src/datetime" {
  interface DateTime {
    toMinutes(): number
    toDays(): number
    setMinutes(minuteOfDay: number): DateTime
    setToGranularityStart(granularity: number): DateTime
  }
}
DateTime.prototype.toMinutes = function (): number {
  return this.hour * 60 + this.minute
}
DateTime.prototype.toDays = function (): number {
  var days = Math.floor(this.startOf("day").diff(this.startOf("year").startOf("day")).as("days"))
  return days
}
DateTime.prototype.setMinutes = function (minuteOfDay: number): DateTime {
  var hours = Math.floor(minuteOfDay / 60)
  var minutes = minuteOfDay % 60
  return this.set({
    hour: hours,
    minute: minutes,
  })
}
DateTime.prototype.setToGranularityStart = function (granularity: number): DateTime {
  var granularity_step = Math.floor(this.minute / granularity)
  return this.set({
    minute: granularity_step * granularity,
  })
}
