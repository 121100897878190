import { Typography } from "@material-ui/core"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import React from "react"
export enum SlotType {
  Base = "base",
  Bookable = "bookable",
  Blocked = "blocked",
  Booked = "booked",
}
export type BookingSlotViewProps = {
  slotTimeDisplay?: string
  resourceID: number
  slotType: SlotType
  slotTime: number
  classes: any
  class?: string
}
export const BookingSlotView = React.memo((props: BookingSlotViewProps) => {
  if (props.slotTimeDisplay === undefined) {
    let hours = Math.floor(props.slotTime / 60)
    let minutes = props.slotTime % 60
    props.slotTimeDisplay = hours + ":" + minutes
  }
  // const hoverClass = props.slotTime >= DateTime.local().toMinutes() ? props.class : "";
  return (
    <div
      data-slottype={props.slotType}
      data-time={props.slotTime}
      data-resourceid={props.resourceID}
      id={"slot_" + props.resourceID + "_" + props.slotTime}
      key={"slot_" + props.resourceID + "_" + props.slotTime}
      className={`${props.classes.schedulerItem} ${props.class}`}
    >
      <AddCircleOutlineIcon style={{ pointerEvents: "none" }} />
      <Typography style={{ pointerEvents: "none" }}>{props.slotTimeDisplay}</Typography>
    </div>
  )
})
