import { Box, Typography } from "@material-ui/core"
import LinearProgress from "@material-ui/core/LinearProgress"
import { useMe, UserRoles, usersApi, UserUpdate, useUser } from "api/scheduleAPI"
import { users as usersPath } from "app/routes"
import AppMenuBar from "components/AppDrawer/AppMenuBar"
import { UserForm } from "features/userEdit/UserForm"
import { useSnackbar } from "notistack"
import React from "react"
import { useMutation, useQueryClient } from "react-query"
import { useParams } from "react-router-dom"

interface Params {
  id: string
}

export const UserEditPage = () => {
  const { id } = useParams<Params>()
  const userId = Number(id)

  const { data: user } = useUser(userId)
  const { data: currentUser } = useMe()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const mutation = useMutation((values: UserUpdate) => usersApi.updateUser(userId, values), {
    onSuccess: () => {
      enqueueSnackbar("Successfully saved.", { variant: "success" })
    },
    onError: () => {
      enqueueSnackbar("Error saving user.", { variant: "error" })
    },
    onSettled: () => {
      queryClient.invalidateQueries("me")
      queryClient.invalidateQueries("users")
      queryClient.invalidateQueries(["user", userId])
    },
  })

  if (!user || !currentUser) {
    return (
      <Box p={10}>
        <LinearProgress />
      </Box>
    )
  }

  return (
    <>
      <AppMenuBar>
        <Typography variant="h5">Edit User</Typography>
      </AppMenuBar>
      <UserForm
        user={user}
        title={`${user.firstname} ${user.lastname}`}
        subtitle="Manage user profile"
        backButtonUrl={usersPath}
        editorIsAdmin={currentUser.role === UserRoles.Admin}
        onFormSubmit={mutation.mutate}
      />
    </>
  )
}
