import { TableCell, TableRow } from "@material-ui/core"
import { ConditionalToolTip } from "containers/ConditionalTooltip"
import { BookingRow } from "features/booking/utils/BookingSlotTable"
import { DateTime } from "luxon"
import React from "react"
import { BookingSlotView, SlotType } from "./BookingSlotView"

const zeroPad = (num: number, places: number) => String(num).padStart(places, "0")
const createTableRowCells = (
  rowData: BookingRow,
  classes: any,
  additionalClass: string,
  height: number,
  date: DateTime,
  granularity: number,
  bookableResources: number[] | undefined,
  tooltip: string,
) => {
  const cells: JSX.Element[] = []
  const hours = Math.floor(rowData.time / 60)
  const minutes = rowData.time % 60
  const displayTime = zeroPad(hours, 2) + ":" + zeroPad(minutes, 2)
  const currentTimeOffset = DateTime.local().setZone("Europe/Berlin").offset
  const blocked =
    +date.set({ hour: hours, minute: minutes + granularity }) < +DateTime.local().plus({ minutes: currentTimeOffset })
  additionalClass = blocked ? classes.schedulerItemBlocked : additionalClass
  cells.push(
    <TableCell
      key={"timestep_" + rowData.time}
      align={"left"}
      height={height}
      padding="none"
      onMouseDown={(event: React.MouseEvent) => {
        event.preventDefault()
      }}
    >
      {rowData.closed.findIndex((closed) => !closed) >= 0 && (
        <p className={classes.timeStep}>{minutes === 0 ? displayTime : ""}</p>
      )}
    </TableCell>,
  )
  for (let index = 0; index < rowData.closed.length; index++) {
    const bookable = bookableResources?.some((id) => id === rowData.resourceIDs[index])
    const isBlocked = blocked || rowData.closed[index]
    const cellAdditionalClass = isBlocked
      ? classes.schedulerItemBlocked
      : bookable
      ? additionalClass
      : classes.schedulerItemNoPermission
    cells.push(
      <TableCell
        key={"cell_" + rowData.resourceIDs[index] + "_" + rowData.time}
        align="center"
        padding="none"
        style={{
          width: `calc(100% / ${rowData.closed.length})`,
        }}
      >
        <ConditionalToolTip toolTip={tooltip} renderToolTip={!bookable}>
          {[
            <BookingSlotView
              key={"bsv_" + rowData.time}
              slotType={SlotType.Base}
              slotTime={rowData.time}
              class={cellAdditionalClass}
              classes={classes}
              resourceID={rowData.resourceIDs[index]}
              slotTimeDisplay={displayTime}
            />,
          ]}
        </ConditionalToolTip>
      </TableCell>,
    )
  }
  return cells
}
export type BookingTableRowsProps = {
  rows: BookingRow[]
  height: number
  classes: any
  granularity: number
  date: DateTime
  hoverable: boolean
  bookableResources: number[] | undefined
  tooltipNoPermission: string
}
export const BookingTableRows = (props: BookingTableRowsProps) => {
  const bookingTableRows = props.rows.map((row) => {
    return (
      <TableRow key={row.time}>
        {createTableRowCells(
          row,
          props.classes,
          props.hoverable && props.classes.schedulerItemHover,
          props.height,
          props.date,
          props.granularity,
          props.bookableResources,
          props.tooltipNoPermission,
        )}
      </TableRow>
    )
  })
  return bookingTableRows
}
