import { IconButton, makeStyles } from "@material-ui/core"
import { ChevronLeft, ChevronRight } from "@material-ui/icons"
import React from "react"

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "#fff",
    margin: "2px",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    margin: 0,
  },
}))
export type PreviousNextButtonPairProps = {
  onClickNextHandler: Function
  onClickPreviousHandler: Function
  additionalClass?: any
}
export const PreviousNextButtonPair = (props: PreviousNextButtonPairProps) => {
  const classes = useStyles()
  return (
    <div className={classes.flexContainer}>
      <IconButton
        className={`${classes.button} ${props.additionalClass}`}
        onClick={() => props.onClickPreviousHandler()}
      >
        <ChevronLeft />
      </IconButton>
      <IconButton className={`${classes.button} ${props.additionalClass}`} onClick={() => props.onClickNextHandler()}>
        <ChevronRight />
      </IconButton>
    </div>
  )
}
