import { Card, Grid, makeStyles, Typography } from "@material-ui/core"
import { ExpandLess, ExpandMore } from "@material-ui/icons"
import { Booking, BookingType, useMe, useSettings } from "api/scheduleAPI"
import { useBookingContext } from "context/bookingContext"
import { DateTime } from "luxon"
import React, { useCallback, useEffect, useState } from "react"
import { pickTextColorBasedOnBgColor, useBookingColor } from "../utils/BookingColorManager"
import { useEventListener } from "../utils/BookingSlotHandler"

type CreateBookingCardViewProps = {
  date: DateTime
  granularity: number
  height: number
  // anchorEl: null | HTMLElement;
}
const useStyles = makeStyles((theme) => ({
  timePopper: {
    backgroundColor: "transparent",
    color: "#fff",
    width: "100%",
    marginTop: "6px",
    height: (props: CreateBookingCardViewProps) => props.height + "rem",
  },
  timePopperTypography: {
    marginRight: "6px",
    textAlign: "end",
    fontSize: "0.6rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "0.65rem",
    },
  },
  expandBox: {
    position: "relative",
  },
  expandLess: {
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    top: "-0.25rem",
  },
  expandMore: {
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    bottom: "-0.25rem",
  },
  schedulerItemSelected: {
    color: "#fff",
    backgroundColor: "#E0E0E0",
    height: "100%",
    position: "absolute",
  },
}))
export const CreateBookingCardView = (props: CreateBookingCardViewProps) => {
  const { booking, updateBooking, createBooking, validateBooking } = useBookingContext()
  const { data: settings } = useSettings()
  const { data: user } = useMe()
  const classes = useStyles(props)
  const [coords, setCoords] = useState({ x: 0, y: 0 })
  const [dragging, setDragging] = useState(-1)
  const [downOnEnd, setDownOnEnd] = useState(false)
  const [newBooking, setNewBooking] = useState(booking)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = newBooking !== undefined && newBooking.startDateTime.toDays() === props.date.toDays()
  const parentTable = document.getElementById("schedulerTable")
  const parentRect = parentTable?.getBoundingClientRect()
  const targetRect = anchorEl && anchorEl.getBoundingClientRect()
  let cardLeft = targetRect && parentRect ? targetRect.left - parentRect.left : 0
  let cardTop = targetRect && parentRect && parentTable ? targetRect.top - parentRect.top + parentTable.scrollTop : 0
  const bookingDuration = newBooking?.endDateTime.diff(newBooking.startDateTime).as("minutes")
  const correctedHeight = props.height + 0.065
  const cardHeightValue = bookingDuration
    ? (correctedHeight * bookingDuration) / props.granularity // TO FIX
    : 0
  const cardMargin = 4
  const cardHeight = cardHeightValue - (2 * cardMargin) / 16 + "rem"
  const cardWidth = anchorEl && anchorEl.offsetWidth - 2 * cardMargin
  const contentHeight = cardHeightValue - 2 * correctedHeight
  const bookingMinEqualsMax =
    settings &&
    settings.booking.max_duration_minutes === settings.booking.min_duration_minutes &&
    settings.booking.max_duration_minutes > 0
  const bookingColor = useBookingColor(
    booking?.booking_type,
    user?.id,
    booking ? [booking.owner_id] : undefined,
    undefined,
    1,
  )
  const bookingTextColor = pickTextColorBasedOnBgColor(bookingColor, "#ffffff", "#1E1E1E")
  const validateAndSetBooking = (tmpBooking: Booking) => {
    const startDateTime = tmpBooking.startDateTime
    const endDateTime = tmpBooking.endDateTime
    const validBooking = validateBooking(tmpBooking)
    if (validBooking && +validBooking.startDateTime === +startDateTime && +validBooking.endDateTime === +endDateTime) {
      setNewBooking(tmpBooking)
    }
  }
  const mouseMoveHandler = useCallback(
    ({ clientX, clientY }) => {
      // Update coordinates
      if (open && (dragging || mouseWithinCard(clientY))) {
        setCoords({ x: clientX, y: Math.floor(clientY) })
      }
    },
    [open, dragging],
  )
  const mouseDownHandler = useCallback(
    (event: React.MouseEvent) => {
      // Update coordinates
      if (event.target instanceof Element) {
        if (event.target.id.includes("createCard")) {
          event.preventDefault()
          setDownOnEnd(event.target.id.includes("Corner"))
          if (targetRect) {
            if (coords.y >= targetRect.top && coords.y <= targetRect.top + cardHeightValue * 16) {
              const boxStart =
                targetRect.top + correctedHeight * 16 * Math.floor((coords.y - targetRect.top) / (correctedHeight * 16))
              setDragging(Math.floor(boxStart))
            }
          }
        } else if (event.target.id.includes("slot_")) {
          event.preventDefault()
          const slotDetails = event.target.id.split("_")
          const time = props.date.setMinutes(parseInt(slotDetails[2], 10))
          if (time.toMillis() >= DateTime.local().toMillis() - props.granularity * 60 * 1000) {
            createBooking({
              resourceID: parseInt(slotDetails[1], 10),
              time: time,
              bookingType: BookingType.User,
            })
          }
          // } else {
          //   enqueueSnackbar('Slot is in past.', { variant: 'error' });
          // }
        }
      }
    },
    [targetRect, props.date],
  )
  const mouseUpHandler = useCallback(
    (event: React.MouseEvent) => {
      // Update coordinates
      if (dragging > 0) {
        event.preventDefault()
        setDragging(-1)
        updateBooking({ ...newBooking })
      }
    },
    [dragging, newBooking],
  )
  const mouseWithinCard = (yPos: number) => {
    if (newBooking && targetRect) {
      if (yPos > targetRect.top + cardMargin && yPos < targetRect.top + cardHeightValue * 16 - cardMargin) {
        return true
      }
    }
    return false
  }
  const handleDraggingOnEnd = () => {
    if (newBooking && targetRect && settings) {
      if (coords.y < dragging) {
        setDragging(dragging - correctedHeight * 16)
        if (mouseWithinCard(coords.y)) {
          let bookingUpdate = {
            ...newBooking,
            endDateTime: newBooking.endDateTime.minus({
              minutes: props.granularity,
            }),
          }
          if (bookingMinEqualsMax) {
            bookingUpdate = {
              ...bookingUpdate,
              startDateTime: bookingUpdate.endDateTime.minus({
                minutes: settings.booking.max_duration_minutes,
              }),
            }
            validateAndSetBooking(bookingUpdate)
          } else if (
            bookingUpdate.endDateTime.toMinutes() - bookingUpdate.startDateTime.toMinutes() >=
            settings.booking.min_duration_minutes
          ) {
            validateAndSetBooking(bookingUpdate)
          }
        } else if (coords.y < targetRect.top + cardMargin) {
          let bookingUpdate = {
            ...newBooking,
            startDateTime: newBooking.startDateTime.minus({
              minutes: props.granularity,
            }),
          }
          if (bookingMinEqualsMax) {
            bookingUpdate = {
              ...bookingUpdate,
              endDateTime: bookingUpdate.startDateTime.plus({
                minutes: settings.booking.max_duration_minutes,
              }),
            }
            validateAndSetBooking(bookingUpdate)
          } else if (
            settings.booking.max_duration_minutes < 0 ||
            bookingUpdate.endDateTime.toMinutes() - bookingUpdate.startDateTime.toMinutes() <=
              settings.booking.max_duration_minutes
          ) {
            validateAndSetBooking(bookingUpdate)
          }
        }
      } else if (coords.y > dragging + correctedHeight * 16) {
        setDragging(dragging + correctedHeight * 16)
        if (mouseWithinCard(coords.y)) {
          let bookingUpdate = {
            ...newBooking,
            startDateTime: newBooking.startDateTime.plus({
              minutes: props.granularity,
            }),
          }
          if (bookingMinEqualsMax) {
            bookingUpdate = {
              ...bookingUpdate,
              endDateTime: bookingUpdate.startDateTime.plus({
                minutes: settings.booking.max_duration_minutes,
              }),
            }
            validateAndSetBooking(bookingUpdate)
          } else if (
            bookingUpdate.endDateTime.toMinutes() - bookingUpdate.startDateTime.toMinutes() >=
            settings.booking.min_duration_minutes
          ) {
            validateAndSetBooking(bookingUpdate)
          }
        } else if (coords.y > targetRect.top + cardHeightValue * 16 - cardMargin) {
          let bookingUpdate = {
            ...newBooking,
            endDateTime: newBooking.endDateTime.plus({
              minutes: props.granularity,
            }),
          }
          if (bookingMinEqualsMax) {
            bookingUpdate = {
              ...bookingUpdate,
              startDateTime: bookingUpdate.endDateTime.minus({
                minutes: settings.booking.max_duration_minutes,
              }),
            }
            validateAndSetBooking(bookingUpdate)
          } else if (
            settings.booking.max_duration_minutes < 0 ||
            bookingUpdate.endDateTime.toMinutes() - bookingUpdate.startDateTime.toMinutes() <=
              settings.booking.max_duration_minutes
          ) {
            validateAndSetBooking(bookingUpdate)
          }
        }
      }
    }
  }
  const handleDraggingOnMiddle = () => {
    if (targetRect && newBooking) {
      if (coords.y < dragging) {
        setDragging(dragging - correctedHeight * 16)
        validateAndSetBooking({
          ...newBooking,
          startDateTime: newBooking.startDateTime.minus({
            minutes: props.granularity,
          }),
          endDateTime: newBooking.endDateTime.minus({
            minutes: props.granularity,
          }),
        })
      } else if (coords.y > dragging + correctedHeight * 16) {
        setDragging(dragging + correctedHeight * 16)
        validateAndSetBooking({
          ...newBooking,
          startDateTime: newBooking.startDateTime.plus({
            minutes: props.granularity,
          }),
          endDateTime: newBooking.endDateTime.plus({
            minutes: props.granularity,
          }),
        })
      }
    }
  }
  // Add event listener using our hook
  useEventListener("mouseup", mouseUpHandler)
  useEventListener("mousedown", mouseDownHandler)
  useEventListener("mousemove", mouseMoveHandler)
  useEffect(() => {
    if (dragging > 0) {
      if (downOnEnd) {
        handleDraggingOnEnd()
      } else {
        handleDraggingOnMiddle()
      }
    }
  }, [coords, dragging])
  useEffect(() => {
    if (newBooking !== booking) {
      setNewBooking(booking)
    }
  }, [booking])

  useEffect(() => {
    if (newBooking) {
      let element = document.getElementById(
        "slot_" + newBooking.ressource_id + "_" + newBooking.startDateTime.toMinutes(),
      )
      if (element != null) {
        setAnchorEl(element)
      }
    } else {
      setAnchorEl(null)
    }
  }, [newBooking])
  if (anchorEl === null || !open || cardWidth === null) {
    return null
  }
  const bookingIsMinDuration = bookingDuration && settings && bookingDuration === settings?.booking.min_duration_minutes
  const bookingIsMaxDuration = bookingDuration && settings && bookingDuration === settings?.booking.max_duration_minutes
  return (
    <div
      id="container"
      style={{
        position: "relative",
        width: "0px",
        height: "0px",
        top: "0px",
        background: "yellow",
      }}
    >
      <Card
        id="createBookingCardView"
        className={classes.schedulerItemSelected}
        style={{
          zIndex: 1,
          margin: cardMargin,
          height: cardHeight,
          width: cardWidth,
          top: cardTop,
          left: cardLeft,
          background: bookingColor,
        }}
      >
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            container
            style={{
              cursor: bookingMinEqualsMax
                ? dragging > 0
                  ? "grabbing"
                  : "grab"
                : bookingIsMinDuration
                ? "n-resize"
                : bookingIsMaxDuration
                ? "s-resize"
                : "ns-resize",
              height: correctedHeight * 16 + cardMargin,
            }}
            id="createCardTopCorner"
          >
            <Grid item xs={1} style={{ pointerEvents: "none" }}>
              <ExpandLess className={classes.expandLess} />
            </Grid>
            <Grid item xs={11} style={{ pointerEvents: "none" }}>
              <div className={classes.timePopper}>
                <Typography style={{ color: bookingTextColor }} className={classes.timePopperTypography}>
                  {newBooking?.startDateTime.toLocaleString(DateTime.TIME_24_SIMPLE) +
                    " - " +
                    newBooking?.endDateTime.toLocaleString(DateTime.TIME_24_SIMPLE)}
                </Typography>
              </div>
            </Grid>
          </Grid>
          {contentHeight > 0 && (
            <Grid
              item
              xs={12}
              style={{
                height: contentHeight + "rem",
                cursor: dragging > 0 ? "grabbing" : "grab",
              }}
              id="createCardCenter"
            ></Grid>
          )}
          <Grid
            item
            xs={12}
            style={{
              cursor: bookingMinEqualsMax
                ? dragging > 0
                  ? "grabbing"
                  : "grab"
                : bookingIsMinDuration
                ? "s-resize"
                : bookingIsMaxDuration
                ? "n-resize"
                : "ns-resize",
              height: correctedHeight * 16 + cardMargin,
            }}
            id="createCardBottomCorner"
          >
            <Grid item xs={1} style={{ pointerEvents: "none" }}>
              <ExpandMore className={classes.expandMore} />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </div>
  )
}
