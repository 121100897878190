import { Container, Fab, Tooltip, Typography } from "@material-ui/core"
import ArrowBackTwoToneIcon from "@material-ui/icons/ArrowBackTwoTone"
import { mailsApi, SendMailCreate } from "api/scheduleAPI"
import { mails } from "app/routes"
import AppMenuBar from "components/AppDrawer/AppMenuBar"
import { MailForm } from "features/mail/MailForm"
import { useSnackbar } from "notistack"
import React from "react"
import { useIntl } from "react-intl"
import { useMutation } from "react-query"
import { RouteComponentProps, useHistory } from "react-router-dom"

const defaultValues: SendMailCreate = {
  to_tags: [],
  to_users: [],
  subject: "",
  message: "",
  attachments: [],
}
export type SendMailPageProps = {
  renderProps?: RouteComponentProps
}
export const SendMailPage = (props: SendMailPageProps) => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const intl = useIntl()
  const passedProps = props.renderProps?.location.state as SendMailPageProps
  const initialValues = { ...defaultValues, ...passedProps }

  const BackButton = () => {
    return (
      <Tooltip title="Back" aria-label="back" arrow placement="right">
        <Fab color="primary" size="small" onClick={() => history.goBack()}>
          <ArrowBackTwoToneIcon />
        </Fab>
      </Tooltip>
    )
  }

  const mutation = useMutation((values: SendMailCreate) => mailsApi.sendMail(values), {
    onSuccess: () => {
      enqueueSnackbar("Successfully saved.", { variant: "success" })
      history.push(mails)
    },
    onError: () => {
      enqueueSnackbar("Error saving user.", { variant: "error" })
    },
  })

  return (
    <Container component="main" maxWidth="md">
      <AppMenuBar>
        <Typography variant="h5">
          {intl.formatMessage({
            id: "mailList.SendPage.Title",
            description: "Send Page Title",
            defaultMessage: "Send Mail",
          })}{" "}
        </Typography>
      </AppMenuBar>
      <BackButton />
      <MailForm mail={initialValues} onFormSubmit={mutation.mutate} />
    </Container>
  )
}
