import { UserRoles } from "api/scheduleAPI"
import { useAuth } from "features/auth/AuthContext"
import React from "react"

export type RenderOnRoleProps = {
  roles: UserRoles[]
  children: React.ReactNode
}

const RenderOnRole: React.FC<RenderOnRoleProps> = ({ roles, children }) => {
  const auth = useAuth()
  return roles.includes(auth.user.role) ? <>{children}</> : null
}
export default RenderOnRole
