import { Button } from "@material-ui/core"
import { ButtonProps } from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import { green } from "@material-ui/core/colors"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import React from "react"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}))
type LoaderButtonProps = ButtonProps & {
  loading?: boolean
  success?: boolean
}
export const LoaderButton = (props: LoaderButtonProps) => {
  const classes = useStyles()

  const buttonClassname = clsx({
    [classes.buttonSuccess]: props.success ? true : false,
  })

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Button {...props} className={`${buttonClassname} ${props.className}`} disabled={props.loading} />
        {props.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>
  )
}
