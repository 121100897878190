import { AuthApi, configuration, Login, useMe, User, UserLoginResponse } from "api/scheduleAPI"
import React, { useCallback, useContext, useMemo } from "react"
import { useMutation, UseMutationResult, useQueryClient } from "react-query"

export interface IAuthContext {
  user: User
  loginUser: UseMutationResult<UserLoginResponse, unknown, Login, unknown>
  logoutUser: Function
  isLoggedIn: Function
  getToken: Function
}

const AuthContext = React.createContext<IAuthContext | undefined>(undefined)
AuthContext.displayName = "AuthContext"

const getExpirationDate = (jwtToken?: string): number | undefined => {
  if (!jwtToken) {
    return undefined
  }
  const jwt = JSON.parse(atob(jwtToken.split(".")[1]))
  // multiply by 1000 to convert seconds into milliseconds
  return (jwt && jwt.exp && jwt.exp * 1000) || undefined
}

const isExpired = (exp?: number) => {
  if (!exp) {
    return false
  }
  return Date.now() > exp
}

const isTokeExpired = (jwtToken?: string): boolean => {
  if (!jwtToken) {
    return true
  }
  const expDate = getExpirationDate(jwtToken)
  return isExpired(expDate)
}

export function AuthProvider(props: any) {
  const authApi = new AuthApi(configuration)
  const { data: currentUser } = useMe()
  const queryClient = useQueryClient()

  const logoutUser = useCallback(() => {
    console.log("[Auth] Remove token to local storage")
    localStorage.removeItem("token")
    queryClient.clear()
  }, [])

  const isLoggedIn = useCallback(() => {
    const token = localStorage.getItem("token")
    if (token) {
      const expired = isTokeExpired(token)
      if (expired) {
        logoutUser()
      }
      return true
    }
    return false
  }, [])

  const loginUser = useMutation((login: Login) => authApi.loginUser(login.username, login.password), {
    onSuccess: (data) => {
      if (data.data.access_token) {
        console.log("[Auth] Save token to local storage: " + data.data.access_token)
        localStorage.setItem("token", data.data.access_token)
      }
      queryClient.invalidateQueries("me")
    },
  })

  const user = currentUser
  const value = useMemo(() => ({ user, loginUser, logoutUser, isLoggedIn }), [user, loginUser, logoutUser, isLoggedIn])

  return <AuthContext.Provider value={value} {...props} />
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error("To use `useAuth`, component must be within a Auth provider")
  }
  return context
}
