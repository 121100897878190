import { login } from "app/routes"
import { useAuth } from "features/auth/AuthContext"
import React from "react"
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom"

export const PrivateRoute = ({ children, ...otherProps }: RouteProps) => {
  const location = useLocation()
  const auth = useAuth()

  if (auth.isLoggedIn() === true) {
    return <Route {...otherProps}>{children}</Route>
  }

  return <Redirect to={{ pathname: login, state: { from: location } }} />
}
